<script>
    export let mainClass;
</script>

<svg class={mainClass} viewBox="0 0 54 58" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1671_9036)">
        <path d="M6.19725 58C6.02971 57.8755 5.83774 57.7756 5.63704 57.7826C5.11174 57.8019 4.66148 57.5283 4.16934 57.4249C3.70162 57.3267 3.44159 56.8672 3.15887 56.5113C2.21821 55.3294 1.42764 54.0423 0.914551 52.6237C0.370051 51.1244 -0.0348338 49.576 0.00181529 47.9522C0.0157768 47.3683 -0.00516549 46.7844 0.00705086 46.2022C0.026248 45.1799 0.275811 44.1944 0.476508 43.1984C0.640556 42.3847 0.90059 41.6027 1.16935 40.8276C1.53409 39.7772 2.01228 38.7654 2.59517 37.8203C3.45904 36.4192 4.39621 35.0637 5.26706 33.6662C5.64751 33.0559 6.18154 32.9227 6.82377 32.9419C7.69463 32.9665 8.30195 33.5381 8.97385 33.973C9.918 34.5833 10.8534 35.2058 11.7923 35.8265C12.1885 36.0878 12.5777 36.3613 12.9756 36.6209C13.1728 36.7506 13.1501 36.8716 13.0314 37.0522C12.0053 38.6252 11.0227 40.2296 9.95814 41.7763C9.30195 42.7284 9.02097 43.7157 9.24261 44.8502C9.32638 45.2798 9.55151 45.6446 9.82027 45.9567C10.4904 46.7353 11.3142 47.2859 12.3735 47.3367C13.6789 47.3999 14.623 46.7949 15.3141 45.7007C16.2391 44.2365 17.2024 42.7968 18.1448 41.3449C18.2635 41.1625 18.363 40.9679 18.4625 40.775C18.637 40.4331 18.8691 40.4085 19.1658 40.6224C20.4153 41.5273 21.7539 42.2953 23.0419 43.1423C23.4485 43.4106 23.883 43.6368 24.2548 43.9682C25.2024 44.8169 25.2443 45.7042 24.6195 46.6739C23.7958 47.9487 22.9947 49.2376 22.1396 50.4914C21.0384 52.1064 19.6649 53.4671 18.1274 54.6718C17.0297 55.531 15.8551 56.2658 14.5916 56.8322C13.6492 57.253 12.6859 57.6563 11.6423 57.7843C11.4398 57.8089 11.2426 57.8597 11.0838 58.0035H6.20249L6.19725 58Z" fill="currentColor"/>
        <path d="M40.9737 58C39.5496 56.7831 38.6142 55.1593 37.4676 53.7126C36.6945 52.7377 35.9406 51.7469 35.1832 50.7597C34.3717 49.7023 33.5776 48.6326 32.7556 47.5858C32.2827 46.9825 31.7992 46.3846 31.4816 45.6814C31.1657 44.987 30.8411 44.2926 30.7678 43.514C30.7399 43.2107 30.6614 42.9126 30.6143 42.6092C30.4467 41.5168 30.7033 40.4734 30.965 39.4265C31.054 39.0723 31.1902 38.7427 31.3298 38.4095C31.4432 38.1394 31.5392 38.178 31.6823 38.3516C32.0488 38.7988 32.4013 39.2582 32.7748 39.6983C33.4519 40.4945 34.3385 40.8452 35.3577 40.7662C36.2547 40.6961 36.9685 40.2156 37.6072 39.5809C38.7032 38.4919 38.8114 36.6752 37.8079 35.4688C37.52 35.1234 37.253 34.7604 36.986 34.4009C36.7678 34.1081 36.8551 33.8941 37.2146 33.8748C38.4624 33.8065 39.7207 33.7714 40.9179 34.1835C42.5287 34.7376 43.9475 35.6056 45.0016 36.9944C46.2267 38.6094 47.4519 40.2244 48.6875 41.8306C50.054 43.6105 51.4274 45.3833 52.8061 47.1526C53.4798 48.0189 53.4972 48.9342 52.8271 49.5883C52.0435 50.3528 50.9876 50.1512 50.3978 49.3831C48.9335 47.4788 47.4955 45.5517 46.0435 43.6368C45.4589 42.8652 44.9545 42.02 44.239 41.3607C43.876 41.0258 43.4275 40.8171 42.8917 41.0117C42.2372 41.2502 41.7399 41.6167 41.6823 42.376C41.6474 42.8354 41.7189 43.251 42.0226 43.6438C43.1221 45.0624 44.1936 46.5038 45.2721 47.9382C46.1517 49.1078 47.0103 50.2915 47.9074 51.4471C48.3856 52.0608 48.2948 53.3496 47.3908 53.8529C46.6072 54.2878 45.7433 53.9844 45.0871 53.1041C43.9283 51.5488 42.7573 50.0021 41.5915 48.452C40.8865 47.5156 40.1762 46.5827 39.4781 45.6393C38.9406 44.9116 37.9877 44.7959 37.3053 45.215C36.5566 45.6762 36.349 46.7669 36.8603 47.4735C37.6055 48.5064 38.3873 49.5111 39.1517 50.53C39.9947 51.654 40.8358 52.7797 41.6788 53.902C42.0784 54.4333 42.5025 54.9471 42.8742 55.4977C43.3995 56.2763 43.1657 57.2548 42.3716 57.7686C42.2756 57.8299 42.1954 57.9176 42.1081 57.993H40.9755L40.9737 58Z" fill="currentColor"/>
        <path d="M35.0278 39.8001C34.2337 39.8352 33.677 39.4161 33.1883 38.7673C32.3192 37.6099 31.3855 36.5017 30.4903 35.3654C29.5932 34.2274 28.7136 33.077 27.8184 31.939C26.5391 30.3117 25.2494 28.6897 23.9685 27.0642C22.6247 25.358 21.3018 23.6325 19.9266 21.9491C18.9475 20.7497 18.0278 19.4994 17.075 18.2789C16.0592 16.9778 15.0435 15.6767 14.0156 14.3843C13.2949 13.4795 13.2826 13.483 14.1989 12.7448C15.0662 12.0451 15.9423 11.3595 16.8044 10.6545C17.04 10.4634 17.1552 10.6335 17.2652 10.7738C18.0453 11.7628 18.8114 12.7623 19.5898 13.7513C21.1116 15.6872 22.6404 17.6143 24.1604 19.552C25.7259 21.5458 27.2791 23.5501 28.8445 25.5438C30.0522 27.0799 31.2756 28.6038 32.485 30.1399C33.7834 31.79 35.0749 33.4488 36.3681 35.1041C36.5914 35.39 36.8044 35.6846 37.0382 35.9599C37.9422 37.0278 37.649 38.7568 36.3349 39.4599C35.7921 39.751 35.6962 39.8089 35.0278 39.8019V39.8001Z" fill="currentColor"/>
        <path d="M10.0557 43.8595C9.95276 43.4877 10.2145 43.0739 10.4606 42.6916C11.7817 40.6365 13.1325 38.6006 14.4641 36.5525C15.4239 35.0743 16.3646 33.5837 17.3227 32.1038C18.6037 30.1275 19.8934 28.1583 21.1761 26.1838C21.2389 26.0874 21.2546 25.9559 21.3279 25.8717C21.4728 25.7051 21.6141 25.5385 21.8375 25.8278C22.6996 26.9378 23.574 28.0356 24.4536 29.1315C24.6595 29.3875 24.6595 29.598 24.478 29.8768C23.3942 31.5339 22.3279 33.2015 21.2546 34.8638C20.2285 36.4525 19.211 38.0483 18.1709 39.6282C17.1307 41.2099 16.1028 42.7986 15.0784 44.3908C14.7154 44.9537 14.3995 45.5657 13.8323 45.9883C13.1447 46.5003 12.2233 46.5915 11.4746 46.1549C10.9301 45.8375 10.4624 45.4587 10.1971 44.8502C10.0714 44.5627 10.0941 44.2838 10.0557 43.8595Z" fill="currentColor"/>
        <path d="M43.3732 6.48989C43.457 7.01595 43.0451 7.35263 42.827 7.76471C42.6176 8.1575 42.3331 8.50997 42.1132 8.89925C41.7275 9.58664 41.0329 9.87948 40.4099 10.2495C39.5513 10.7598 38.5774 10.993 37.6455 11.3297C37.5914 11.3489 37.5303 11.37 37.4762 11.3647C36.6839 11.2946 36.2581 11.7715 35.93 12.4011C35.8968 12.466 35.8619 12.5291 35.8253 12.5922C35.5652 13.0429 35.4937 13.0622 35.0748 12.7956C33.8846 12.0346 32.6944 11.2735 31.5059 10.5125C31.2878 10.3722 31.2948 10.1776 31.4134 9.98294C31.5792 9.71114 31.7345 9.42707 31.9422 9.19209C32.2214 8.8747 32.2319 8.52224 32.1202 8.16803C31.7625 7.02998 31.8253 5.86212 31.8637 4.70128C31.8846 4.07176 31.7991 3.41067 32.1673 2.82675C32.5984 2.14462 32.9771 1.42391 33.4553 0.776859C33.8532 0.240276 34.4675 0.0438796 35.1237 0.189423C35.8584 0.352502 36.553 0.647097 37.19 1.05918C38.1446 1.67642 39.1045 2.28841 40.0417 2.93196C41.0469 3.62285 42.1691 4.17522 42.9055 5.20279C43.1743 5.5763 43.4238 5.97435 43.3715 6.49164L43.3732 6.48989ZM33.8986 5.29047C33.8043 6.27596 34.1708 7.16851 34.1918 8.10314C34.1988 8.41177 34.4536 8.61342 34.6979 8.78001C34.9352 8.94134 35.2022 9.06233 35.4256 9.24119C35.6612 9.43057 35.8986 9.47967 36.1918 9.43583C37.2389 9.27802 38.2197 8.90451 39.1813 8.49068C39.8218 8.21362 40.3907 7.81732 40.7781 7.20358C40.9806 6.88268 40.909 6.67401 40.546 6.6179C39.6647 6.47761 38.8794 6.08131 38.1342 5.6289C36.9125 4.88891 35.7223 4.09631 34.8881 2.88111C34.7729 2.71277 34.5792 2.64262 34.4518 2.91442C34.0923 3.68072 33.7747 4.45754 33.8986 5.29222V5.29047Z" fill="currentColor"/>
        <path d="M15.7295 6.23908C15.7033 6.83703 15.6806 7.43324 15.6492 8.03119C15.637 8.25915 15.6736 8.44678 15.8412 8.62915C16.075 8.88341 16.2792 9.16924 16.4764 9.45507C16.6021 9.63568 16.6283 9.81279 16.4066 9.98113C15.5811 10.6142 14.7661 11.263 13.9476 11.9065C13.6405 12.1467 13.3264 12.3782 13.0349 12.6377C12.8045 12.8429 12.6353 12.8131 12.4555 12.5851C12.2234 12.2888 11.9721 12.0082 11.7504 11.7049C11.6004 11.4997 11.4241 11.391 11.1675 11.3892C10.3176 11.3875 9.52707 11.0999 8.71555 10.9C7.89357 10.6966 7.16408 10.3073 6.42062 9.93904C6.25483 9.85663 6.07333 9.75492 5.96338 9.61289C5.46077 8.95882 4.98433 8.2837 4.49219 7.61911C3.99481 6.94751 4.27753 6.31974 4.61435 5.71477C5.02447 4.97828 5.65448 4.45047 6.3194 3.9472C7.54104 3.02309 8.72253 2.04637 9.94068 1.11699C10.5463 0.655809 11.178 0.243727 11.9564 0.0701266C12.7347 -0.105227 13.3455 0.0368093 13.8359 0.675098C14.2496 1.21343 14.7138 1.72021 15.082 2.28835C15.4817 2.9056 15.5864 3.64384 15.6614 4.35928C15.726 4.98004 15.6754 5.61131 15.6754 6.23908C15.6928 6.23908 15.7103 6.23908 15.7277 6.23908H15.7295ZM13.6981 6.09529C13.562 4.95899 13.6405 3.7736 12.8848 2.78285C12.6754 2.5093 12.5358 2.50404 12.3246 2.81441C11 4.75909 9.23562 6.13211 7.02272 6.9177C6.76792 7.00888 6.64924 7.17021 6.83074 7.40869C7.42934 8.18901 8.28274 8.56602 9.18501 8.83256C9.74871 8.99915 10.3595 9.04649 10.904 9.23237C11.712 9.50767 12.3037 9.33758 12.8517 8.75365C12.9494 8.65019 13.0733 8.57128 13.1832 8.47835C13.3316 8.35384 13.4468 8.21356 13.4747 8.01015C13.5602 7.37537 13.719 6.74936 13.6946 6.09704L13.6981 6.09529Z" fill="currentColor"/>
        <path d="M31.0661 11.1437C31.108 11.1648 31.1901 11.1946 31.2599 11.2437C32.3489 11.9995 33.4588 12.7202 34.5845 13.4216C34.8166 13.5671 34.9562 13.7127 34.7223 14.0599C33.4187 16.001 32.1517 17.9685 30.8846 19.936C30.1726 21.0389 29.4867 22.1577 28.7834 23.2659C28.6316 23.5044 28.4588 23.5833 28.2442 23.3028C27.3803 22.177 26.5129 21.0547 25.6316 19.9412C25.4449 19.7045 25.4466 19.5274 25.6107 19.2819C26.293 18.2613 26.9597 17.2285 27.6281 16.1992C28.4379 14.9524 29.2477 13.7039 30.0539 12.4554C30.2895 12.0924 30.5216 11.7277 30.7468 11.3559C30.8166 11.2419 30.8567 11.1122 31.0679 11.1437H31.0661Z" fill="currentColor"/>
    </g>
    <defs>
        <clipPath id="clip0_1671_9036">
            <rect width="53.3226" height="58.0001" fill="white"/>
        </clipPath>
    </defs>
</svg>
