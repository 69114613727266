<script>
    import { onDestroy, onMount } from "svelte"

    import Swiper, { Pagination, Navigation, Scrollbar } from 'swiper';
    Swiper.use([Pagination, Navigation, Scrollbar]);

    import PricingSliderItem from "./PricingSliderItem.svelte";
    import Checkmark from "@/icons/Checkmark.svelte";
    import Tooltip from "@/components/form/Tooltip.svelte";
    import Info from "@/icons/Info.svelte";

    import InvoiceCheckoutPopup from "./InvoiceCheckoutPopup.svelte";
    import { navigate } from "svelte-routing";
    import { user } from "@/services/user";
    import { getQueryParameterByName, removeQueryParams } from "@/lib/utils";
    import { api } from "@/services/checkout"
    import { failure } from "@/lib/toast";

    let slider = undefined;
    let init = false;
    let swiper = undefined;
    let resizeEventListener = undefined;

    let checkoutInvoicePopupOpened = false;
    let checkoutInvoicePopupData = JSON.parse(localStorage.getItem('checkoutInvoice'));

    function setSlideOpacity(swiper) {
        swiper.slides.forEach((slide, index) => {
            const slideOffset = Math.abs(slide.progress);
            const opacity = 1 - (slideOffset + 0) + 0.3;
            const scale = 1 - (slideOffset + 0) + 0.95;

            slide.style.opacity = opacity;
            slide.style.scale = Math.min(1, Math.max(0, scale))

            if(index === swiper.activeIndex) {
                slide.style.transform = `rotate(0deg)`;
                slide.style.opacity = 1;
            }
        });
    }

    const swiperMode = () => {
        let mobile = window.matchMedia('(min-width: 0px) and (max-width: 768px)');
        let tablet = window.matchMedia('(min-width: 769px) and (max-width: 1024px)');
        let desktop = window.matchMedia('(min-width: 1025px)');

        // Enable (for mobile)
        if(mobile.matches) {
            if (!init) {
                init = true;
                swiper = new Swiper(slider, {
                    slidesPerView: 1,
                    watchSlidesProgress: true,
                    pagination: {
                        el: '.pricing-swiper-pagination',
                        clickable: true,
                    },
                    grabCursor: true,
                    spaceBetween: 0,
                    centeredSlides: true,
                    rewind: true,
                    // simulateTouch: false,
                    draggable: true,
                    on: {
                        slideChangeTransitionStart: function () {
                            setSlideOpacity(this);
                        },
                        setTranslate: function () {
                            setSlideOpacity(this);
                        },
                    },
                });
            }
        }

        // Disable (for tablet)
        else if(tablet.matches) {
            swiper?.destroy();
            init = false;
        }

        // Disable (for desktop)
        else if(desktop.matches) {
            swiper?.destroy();
            init = false;
        }
    }

    const checkoutInvoice = (event) => {
        // if no user is given redirect the user to register page and save the invoice data in the session storage to use them later
        if($user) {
            checkoutInvoicePopupOpened = true;
            checkoutInvoicePopupData = event.detail;
        } else {
            localStorage.setItem('checkoutInvoice', JSON.stringify(event.detail));
            navigate('/register');
        }
    }

    const checkoutStripe = (event) => {
        api.checkout({
            quantity: event.detail.amount.id,
            intended: getQueryParameterByName('intended') ?? `${window.location.origin}/dashboard?checkoutSuccesfull=true`,
            type: event.detail.type,
            on_cancel: `${window.location.origin}/arbeitgeber?scrollto=unsere-pakete`
        }).then((result) => {
            window.location.href = result.data.redirect;
        }).catch((error) => {
            if(error.response.data.message === "Unauthenticated.") {
                localStorage.setItem('checkoutStripe', JSON.stringify(event.detail));
                navigate("/register?intended=/?verified=1");
            }
            else if(error.response.data.message === "Your email address is not verified.") {
                localStorage.setItem('checkoutStripe', JSON.stringify(event.detail));
                navigate("/email/verify");
                failure("Bitte bestätigen Sie zuerst Ihre E-Mail-Adresse um den Kauf abschliessen zu können.");
            }
            else {
                failure("Es ist ein Fehler beim aufrufen der Zahlungsseite aufgetreten")
                error.response.data.message && failure(error.response.data.message);
            }
        })
    }

    onMount(() => {
        swiperMode();
        
        // if the user comes back to the page and has payment data in the session storage then open the popup
        if(getQueryParameterByName("processCheckout")) {
            if(localStorage.getItem('checkoutInvoice')) {
                let data = JSON.parse(localStorage.getItem('checkoutInvoice'));
                localStorage.removeItem('checkoutInvoice');
                checkoutInvoicePopupOpened = true;
                checkoutInvoicePopupData = data;
                document.getElementById("unsere-pakete").scrollIntoView()
            }

            if(localStorage.getItem('checkoutStripe')) {
                let data = JSON.parse(localStorage.getItem('checkoutStripe'));
                localStorage.removeItem('checkoutStripe');
                checkoutStripe({detail: data});
                document.getElementById("unsere-pakete").scrollIntoView()
            }

            removeQueryParams(["processCheckout"]);
        }


        resizeEventListener = window?.addEventListener('resize', () => swiperMode());
    })

    onDestroy(() => {
        window?.removeEventListener('resize', resizeEventListener);
    });
</script>

<main>
    <div class="mt-12 max-w-5xl mx-auto">
        <div class="pricing-slider overflow-visible mx-auto relative">
            <div bind:this={slider}>
                <div class="swiper-wrapper">
                    <PricingSliderItem on:checkoutInvoice={(e) => checkoutInvoice(e)} on:checkoutStripe={(e) => checkoutStripe(e)} type="smart" badge={true}>
                        <div slot="price" let:amount let:prices>
                            <strong class="text-2xl text-poisongreen" style="text-shadow: rgba(0,0,0,0.3) 2px 2px 15px;">
                                Smart
                            </strong>
                            <p class="text-6xl text-white font-bold mt-1" style="text-shadow: rgba(0,0,0,0.3) 2px 2px 15px;">
                                {prices["smart"][amount.id].price} €
                            </p>
                            <p class="text-white font-semibold mt-1" style="text-shadow: rgba(0,0,0,0.3) 2px 2px 15px;">
                                Laufzeit: 60 Tage
                            </p>
                        </div>
                        <div slot="information">
                            <div class="relative">
                                <div class="flex items-top gap-2 my-2 font-medium relative">
                                    <Checkmark mainClass="w-6 h-6 text-poisongreen shrink-0"/>
                                    Laufzeit + 30-Tage Zufriedenheitsgarantie
                                    <div class="relative">
                                        <Tooltip title="Sollten Sie innerhalb der Laufzeit von 60 Tagen <br/> Ihre offene(n) Stelle(n) nicht mit genau den passenden <br/> Bewerbern besetzen, suchen wir 30 Tage <br/>kostenfrei für Sie weiter.">
                                            <Info mainClass="w-6 h-6 text-moss shrink-0 rounded-full bg-white border border-moss"/>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                            <div class="flex items-top gap-2 my-2 font-medium">
                                <Checkmark mainClass="w-6 h-6 text-poisongreen shrink-0"/>
                                <span>
                                    Direkter Zugang zu {window.meta.settings.employee_count}+ potenziellen Bewerbern
                                </span>
                            </div>
                            <div class="flex items-top gap-2 my-2 font-medium">
                                <Checkmark mainClass="w-6 h-6 text-poisongreen shrink-0"/>
                                <span>
                                    Visuelle Darstellung Ihres Unternehmens mit Ihren Bildern und Videos
                                </span>
                            </div>
                            <div class="flex items-top gap-2 my-2 font-medium">
                                <Checkmark mainClass="w-6 h-6 text-poisongreen shrink-0"/>
                                <span>
                                    Nur passende Bewerbungen durch unseren KI-Filterungsprozess
                                </span>
                            </div>
                            <div class="flex items-top gap-2 my-2 font-medium">
                                <Checkmark mainClass="w-6 h-6 text-poisongreen shrink-0"/>
                                <span>
                                    Bewerbungen via Mail und WhatsApp
                                </span>
                            </div>
                            <div class="flex items-top gap-2 my-2 font-medium">
                                <Checkmark mainClass="w-6 h-6 text-poisongreen shrink-0"/>
                                <span>
                                    Einbindung Ihrer Social-Media Kanäle
                                </span>
                            </div>
                        </div>
                    </PricingSliderItem>
                    <PricingSliderItem on:checkoutInvoice={(e) => checkoutInvoice(e)} on:checkoutStripe={(e) => checkoutStripe(e)} type="premium">
                        <div slot="price" let:amount let:prices>
                            <strong class="text-2xl text-poisongreen" style="text-shadow: rgba(0,0,0,0.3) 2px 2px 15px;">
                                Premium
                            </strong>
                            <p class="text-6xl text-white font-bold mt-1" style="text-shadow: rgba(0,0,0,0.3) 2px 2px 15px;">
                                {prices["premium"][amount.id].price} €
                            </p>
                            <p class="text-white font-semibold mt-1" style="text-shadow: rgba(0,0,0,0.3) 2px 2px 15px;">
                                Laufzeit: 60 Tage
                            </p>
                        </div>
                        <div slot="information">
                            <p class="bg-gradient-to-r from-poisongreen to-limegreen inline-block text-transparent bg-clip-text font-medium text-xl mb-4">
                                Alle Features von Smart sowie:
                            </p>
                            <div class="flex items-top gap-2 my-2 font-medium">
                                <Checkmark mainClass="w-6 h-6 text-poisongreen shrink-0"/>
                                <span>
                                    Premium-Platzierung: Ganz oben in der Ergebnisliste
                                </span>
                            </div>
                            <div class="flex items-top gap-2 my-2 font-medium">
                                <Checkmark mainClass="w-6 h-6 text-poisongreen shrink-0"/>
                                <span>
                                    3 x höhere Sichtbarkeit Ihrer Anzeige beim Bewerber
                                </span>
                            </div>
                            <div class="flex items-top gap-2 my-2 font-medium">
                                <Checkmark mainClass="w-6 h-6 text-poisongreen shrink-0"/>
                                <span>
                                    Premium-Darstellung: Optisch auffallende Anzeige
                                </span>
                            </div>
                            <div class="flex items-top gap-2 my-2 font-medium">
                                <Checkmark mainClass="w-6 h-6 text-poisongreen shrink-0"/>
                                <span>
                                    Einbindung Ihrer Karriereseite
                                </span>
                            </div>
                        </div>
                    </PricingSliderItem>
                </div>

                <div class="pricing-swiper-pagination flex items-center !w-auto px-2 py-3 absolute !-bottom-12 !left-1/2 !right-unset p-2 !-translate-x-1/2 z-10 md:hidden"></div>
            </div>
        </div>
    </div>
</main>

<InvoiceCheckoutPopup bind:open={checkoutInvoicePopupOpened} data={checkoutInvoicePopupData}/>