<script>
    export let mode;
    export let formID;

    import Stroke from "@/icons/Stroke.svelte";
    import Call from "@/icons/Call.svelte";
    import Mail from "@/icons/Mail.svelte";
    import Pen from "@/icons/Pen.svelte";
    import Popup from "@/components/Popup.svelte";
    import Upload from "@/components/form/Upload.svelte";
    import Facebook from "@/icons/Facebook.svelte";
    import Linkedin from "@/icons/Linkedin.svelte";
    import Xing from "@/icons/Xing.svelte";
    import Instagram from "@/icons/Instagram.svelte";
    import Tiktok from "@/icons/Tiktok.svelte";
    import Website from "@/icons/Website.svelte";

    import Input from "@/components/form/Input.svelte";
    import TelInput from "@/components/form/TelInput.svelte";

    import { jobAdvertisement as tempJobAdvertisement } from "@/stores/temporary";
    import { formattedPhoneNumber, getAvailableFileSize } from "@/lib/utils";
    import { success } from "@/lib/toast";

    let uploadPopupOpen = false;
</script>

<section class="py-12">
    <h2 class="text-moss text-left lg:text-center">
        Hast du noch Fragen?
    </h2>

    <div class="mx-auto max-w-lg mt-8">
        {#if mode === "view"}
            <div class="flex flex-row gap-10">
                <div class="w-1/3">
                    {#if $tempJobAdvertisement?.data?.contact_images?.data?.length > 0}
                        <img class="aspect-square w-full border-4 bg-moss border-white object-cover rounded-full" src="{getAvailableFileSize($tempJobAdvertisement?.data?.contact_images?.data[0], "sm")}" alt="Banner"/>
                    {:else if $tempJobAdvertisement?.data?.contact_images[0]}
                        <img class="aspect-square w-full border-4 bg-moss border-white object-cover rounded-full" src="{`/uploads/${$tempJobAdvertisement?.data?.contact_images[0].path}`}" alt="Banner"/>
                    {:else}
                        <img class="aspect-square w-full border-4 bg-moss border-white object-cover rounded-full" src="/images/person-placeholder.png" alt="Banner"/>
                    {/if}
                </div>
                <div class="w-2/3">
                    <p class="font-medium text-xl text-moss inline-flex flex-col gap-1 items-start">
                        Dein Ansprechpartner
                        <Stroke mainClass="inline-block text-limegreen h-1 w-auto"/>
                    </p>
                    {#if $tempJobAdvertisement.data.contact_name}
                        <p class="font-medium text-limegreen block mt-2">
                            {$tempJobAdvertisement.data.contact_name}
                        </p>
                    {/if}
                    {#if $tempJobAdvertisement.data.contact_position}
                        <p>
                            {$tempJobAdvertisement.data.contact_position}
                        </p>
                    {/if}
                    <div class="inline-flex flex-col gap-2 mt-4">
                        {#if $tempJobAdvertisement.data.contact_phone}
                            <a href="tel:{$tempJobAdvertisement.data.contact_phone}" class="button !py-2 moss !no-underline !text-left inline-flex items-center gap-2 lg:hidden">
                                <Call mainClass="w-4 h-4"/>
                                Jetzt anrufen
                            </a>
                            <p class="button !py-2 moss !no-underline !text-left items-center gap-2 hidden lg:inline-flex">
                                <Call mainClass="w-4 h-4"/>
                                {formattedPhoneNumber($tempJobAdvertisement.data.contact_phone)}
                            </p>
                        {/if}
                        {#if $tempJobAdvertisement.data.contact_email}
                            <a href="mailto:{$tempJobAdvertisement.data.contact_email}" class="button !py-2 limegreen !no-underline !text-left !inline-flex items-center gap-2 outlined">
                                <Mail mainClass="w-4 h-4"/>
                                E-Mail senden
                            </a>
                        {/if}
                        {#if $tempJobAdvertisement.data.website}
                            <a href="{$tempJobAdvertisement.data.website}" class="button !py-2 limegreen !no-underline !text-left !inline-flex items-center gap-2 outlined">
                                <Website mainClass="w-4 h-4"/>
                                Website
                            </a>
                        {/if}
                    </div>

                    <div class="flex flex-row gap-2 text-earth mt-4">
                        {#if $tempJobAdvertisement.data.social_facebook}
                            <a target="_blank" href="{$tempJobAdvertisement.data.social_facebook}" class="cursor-pointer w-10 h-10 bg-earth text-white rounded-full p-1 flex items-center justify-center">
                                <Facebook mainClass="w-5 h-5"/>
                            </a>
                        {/if}
                        {#if $tempJobAdvertisement.data.social_xing}
                            <a target="_blank" href="{$tempJobAdvertisement.data.social_xing}" class="cursor-pointer w-10 h-10 bg-earth text-white rounded-full p-1 flex items-center justify-center">
                                <Xing mainClass="w-5 h-5"/>
                            </a>
                        {/if}
                        {#if $tempJobAdvertisement.data.social_instagram}
                            <a target="_blank" href="{$tempJobAdvertisement.data.social_instagram}" class="cursor-pointer w-10 h-10 bg-earth text-white rounded-full p-1 flex items-center justify-center">
                                <Instagram mainClass="w-5 h-5"/>
                            </a>
                        {/if}
                        {#if $tempJobAdvertisement.data.social_linkedin}
                            <a target="_blank" href="{$tempJobAdvertisement.data.social_linkedin}" class="cursor-pointer w-10 h-10 bg-earth text-white rounded-full p-1 flex items-center justify-center">
                                <Linkedin mainClass="w-5 h-5"/>
                            </a>
                        {/if}
                        {#if $tempJobAdvertisement.data.social_tiktok}
                            <a target="_blank" href="{$tempJobAdvertisement.data.social_tiktok}" class="cursor-pointer w-10 h-10 bg-earth text-white rounded-full p-1 flex items-center justify-center">
                                <Tiktok mainClass="w-5 h-5"/>
                            </a>
                        {/if}
                    </div>
                </div>
            </div>
        {:else if mode === "edit"}
            <div class="flex flex-row gap-10">
                <div class="w-1/3 relative">
                    {#if $tempJobAdvertisement?.data?.contact_images?.data?.length > 0}
                        <img class="aspect-square w-full border-4 bg-moss border-white object-cover rounded-full" src="{getAvailableFileSize($tempJobAdvertisement?.data?.contact_images?.data[0], "lg")}" alt="Banner"/>
                    {:else if $tempJobAdvertisement?.data?.contact_images[0]}
                        <img class="aspect-square w-full border-4 bg-moss border-white object-cover rounded-full" src="{`/uploads/${$tempJobAdvertisement?.data?.contact_images[0].path}`}" alt="Banner"/>
                    {:else}
                        <img class="aspect-square w-full border-4 bg-moss border-white object-cover rounded-full" src="/images/person-placeholder.png" alt="Banner"/>
                    {/if}

                    <button on:click={() => uploadPopupOpen = true} class="absolute top-1 right-1 w-8 h-8 bg-limegreen text-white flex items-center justify-center rounded-full">
                        <Pen mainClass="w-5 h-5"/>
                    </button>
                </div>
                <div class="w-2/3">
                    <p class="font-medium text-xl text-moss inline-flex flex-col gap-1 items-start">
                        Dein Ansprechpartner
                        <Stroke mainClass="inline-block text-limegreen h-1 w-auto"/>
                    </p>
                    <p class="text-limegreen block mt-2">
                        <Input
                            bind:value={$tempJobAdvertisement.data.contact_name}
                            name="contact_name"
                            placeholder="Vor- und Nachname"
                            type="text"
                            {formID}
                            containerClass="w-full my-1"
                            inputClass="!bg-poisongreen/20 !border-limegreen !text-moss placeholder:!text-limegreen focus:!bg-white"
                        />
                    </p>
                    <p class="text-limegreen block mt-4">
                        <Input
                            bind:value={$tempJobAdvertisement.data.contact_position}
                            name="contact_position"
                            placeholder="Position"
                            type="text"
                            {formID}
                            containerClass="w-full my-1"
                            inputClass="!bg-poisongreen/20 !border-limegreen !text-moss placeholder:!text-limegreen focus:!bg-white"
                        />
                    </p>
                    <div class="flex flex-col gap-2 mt-4">
                        <TelInput
                            bind:value={$tempJobAdvertisement.data.contact_phone}
                            name="contact_phone"
                            placeholder="Telefon"
                            type="text"
                            {formID}
                            containerClass="w-full my-1 !bg-poisongreen/20 !border-limegreen !text-moss placeholder:!text-limegreen focus:!bg-white"
                            telInputClass="!bg-transparent !text-moss placeholder:!text-limegreen"
                            inputClass="!bg-transparent"
                            selectableCountries={[
                                {id: "AT", iso2: "AT", dialCode: "43", name: "Österreich", label: "Österreich"},
                                {id: "CH", iso2: "CH", dialCode: "41", name: "Schweiz", label: "Schweiz"},
                                {id: "DE", iso2: "DE", dialCode: "49", name: "Deutschland", label: "Deutschland"},
                                {id: "FR", iso2: "FR", dialCode: "33", name: "Frankreich", label: "Frankreich"},
                                {id: "IT", iso2: "IT", dialCode: "39", name: "Italien", label: "Italien"},
                                {id: "NL", iso2: "NL", dialCode: "31", name: "Niederlande", label: "Niederlande"},
                            ]}
                        />
                        <TelInput
                            bind:value={$tempJobAdvertisement.data.contact_whatsapp}
                            name="contact_whatsapp"
                            placeholder="WhatsApp"
                            type="text"
                            {formID}
                            tooltip="WhatsApp als Kontaktmöglichkeit erhöht<br/> die Bewerbungsrate um 52% (optional)."
                            containerClass="w-full my-1 !bg-poisongreen/20 !border-limegreen !text-moss placeholder:!text-limegreen focus:!bg-white"
                            telInputClass="!bg-transparent !text-moss placeholder:!text-limegreen"
                            inputClass="!bg-transparent"
                            selectableCountries={[
                                {id: "AT", iso2: "AT", dialCode: "43", name: "Österreich", label: "Österreich"},
                                {id: "CH", iso2: "CH", dialCode: "41", name: "Schweiz", label: "Schweiz"},
                                {id: "DE", iso2: "DE", dialCode: "49", name: "Deutschland", label: "Deutschland"},
                                {id: "FR", iso2: "FR", dialCode: "33", name: "Frankreich", label: "Frankreich"},
                                {id: "IT", iso2: "IT", dialCode: "39", name: "Italien", label: "Italien"},
                                {id: "NL", iso2: "NL", dialCode: "31", name: "Niederlande", label: "Niederlande"},
                            ]}
                        />
                        <Input
                            bind:value={$tempJobAdvertisement.data.contact_email}
                            name="contact_email"
                            placeholder="E-Mail"
                            type="text"
                            {formID}
                            containerClass="w-full my-1"
                            inputClass="!bg-poisongreen/20 !border-limegreen !text-moss placeholder:!text-limegreen focus:!bg-white"
                        />
                    </div>
                </div>
            </div>

            <Popup open={uploadPopupOpen} on:close={() => uploadPopupOpen = null}>
                <strong class="text-2xl text-snow">
                    Kontaktbild hochladen
                </strong>

                <div class="bg-sandlight rounded-md mt-4">
                    <Upload 
                        bind:files={$tempJobAdvertisement.data.contact_images}
                        type="horizontal"
                        title="Kontaktbild der Anzeigen hochladen"
                        message="Laden Sie hier das Kontaktbild Ihrer Stellenanzeigen hoch."
                        acceptedFileTypes={["image/jpg","image/jpeg","image/png","image/webp"]}
                        formID={formID}
                        name="contact_images"
                        maxFileSize="2MB"
                        on:uploaded={() => {
                            uploadPopupOpen = false
                            success("Kontaktbild erfolgreich hochgeladen")
                        }}
                    />
                </div>
            </Popup>
        {/if}
    </div>
</section>