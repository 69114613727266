<script>
    import { onMount, onDestroy, createEventDispatcher, tick } from "svelte";
    import { newlinesToArray } from "@/lib/utils";
    import InputError from "@/components/form/InputError.svelte";
    import { clickOutside } from "@/lib/utils";

    const dispatch = createEventDispatcher();

    export let newItemLabel = "Eintrag hinzufügen";
    export let value = undefined;
    export let formID = undefined;
    export let name = undefined;

    let iterable = newlinesToArray(value);

    let list = undefined;
    let hasErrors = false;

    const handleChange = async () => {
        await tick();
        await new Promise(resolve => setTimeout(resolve, 0));

        let elements = list.getElementsByTagName('li');
        let value = "";

        for (let iterator = 0; iterator < elements.length; iterator++) {
            let span = elements[iterator].querySelector('span');

            if(span.innerText !== newItemLabel && span.innerText !== "") {
                value += `${span.innerText}\n`;
            }
        }

        dispatch("change", value);
    }

    onMount(() => {
        var listItems = list.querySelectorAll("li");
        var inputs = list.querySelectorAll("input");

        for (var i = 0; i < listItems.length; i++) {
            setEventListener(listItems[i], inputs[i]);
        }

        function editItem(eventInput, object) {
            if (!object) object = this;
            object.className = "edit";
            var inputField = object.querySelector("input");
            inputField.focus();
            // do not focus the text inside inputField
            // inputField.setSelectionRange(0, inputField.value.length);
        }

        function blurInput(event) {
            this.parentNode.className = "";

            if (this.value == "") {
                if (this.parentNode.getAttribute("data-new")) addChild();
                list.removeChild(this.parentNode);
            } else {
                this.previousElementSibling.innerHTML = this.value;

                if (this.parentNode.getAttribute("data-new")) {
                    this.parentNode.removeAttribute("data-new");
                    addChild();
                }
            }
        }

        function keyInput(event) {
            if (event.which == 13 || event.which == 9) {
                event.preventDefault();
                this.blur();

                if (!this.parentNode.getAttribute("data-new")) {
                    editItem(null, this.parentNode.nextElementSibling);
                }
            }
        }

        function setEventListener(listItem, input) {
            listItem.addEventListener("click", editItem);
            input.addEventListener("blur", blurInput);
            input.addEventListener("keydown", keyInput);
        }

        function addChild() {
            var entry = document.createElement('li');
            entry.innerHTML = `<span>${newItemLabel}</span><input type='text'>`;
            entry.setAttribute("data-new", true);
            list.appendChild(entry);
            setEventListener(entry, entry.lastChild);
        }

        list.addEventListener("change", handleChange);
    })

    onDestroy(() => {
        list.removeEventListener("change", handleChange);
    })
</script>

<main class="contenteditable bg-poisongreen/20 px-4 py-2 rounded-md border border-limegreen">
    <ul bind:this={list} id="list" use:clickOutside on:clickOutside={handleChange}>
        {#each iterable as item}
            <li>
                <span>{item}</span>
                <input type="text" value="{item}">
            </li>
        {/each}
        <li data-new="true">
            <span>{newItemLabel}</span>
            <input type="text">
        </li>
    </ul>

    <InputError bind:hasErrors {name} {formID} {value}/>
</main>