<script>
    import Facebook from "@/icons/Facebook.svelte";
    import Xing from "@/icons/Xing.svelte";
    import Instagram from "@/icons/Instagram.svelte";
    import Linkedin from "@/icons/Linkedin.svelte";
    import Tiktok from "@/icons/Tiktok.svelte";

    import { createEventDispatcher } from "svelte";

    const icons = {
        facebook: Facebook,
        xing: Xing,
        instagram: Instagram,
        linkedin: Linkedin,
        tiktok: Tiktok,
    }

    const dispatch = createEventDispatcher();

    export let activeSocialMedia;
    export let value;
    export let icon;
    export let formID;

    import { errors } from "@/stores/error";

    let inputError = false;
    $: hasErrors = inputError ? true : false;

    errors.subscribe((data) => {
        inputError = _.get(data, `${formID}.${`social_${icon}`?.replaceAll(".", "-")}`);
    });
</script>

<button on:click={() => dispatch("select", icon === activeSocialMedia ? null : icon) ? activeSocialMedia = undefined : activeSocialMedia = icon} class="{inputError ? 'border-red' : value ? '!bg-moss text-white border-moss' : ''} {activeSocialMedia === icon ? 'border-poisongreen' : ''} border-2 relative bg-sandlight text-moss group hover:bg-moss hover:text-sandlight transition-all ease-in-out w-14 h-14 rounded-full flex justify-center items-center shadow-lg">
    <svelte:component this={icons[icon]} mainClass="w-8 h-8"/>
</button>