<script>
    export let meta;

    import { createEventDispatcher } from "svelte";
    import { replaceQueryParams } from "@/lib/utils";

    const dispatch = createEventDispatcher();

    const setCurrentPage = (newPage) => {
        dispatch("paginate", newPage);
    };
</script>

<main>
    {#if meta && meta.total / meta.per_page > 1}
        <ul class="flex flex-row gap-2 mt-6">
            {#if meta?.current_page > 1}
                <li
                    class="rounded-md bg-transparent px-1 py-1 text-moss cursor-pointer border border-moss/50 w-8 h-8"
                >
                    <button
                        on:click|preventDefault={() => setCurrentPage(1)}
                        class="flex items-center justify-center w-full h-full"
                    >
                        <svg width="80%" height="80%" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.93006 0.0288683C4.68006 0.0788682 4.42646 0.20585 4.27376 0.43585L0.27377 6.43584C0.0506704 6.77084 0.0506704 7.22486 0.27377 7.55986L4.27376 13.5599C4.57906 14.0198 5.22226 14.1478 5.68006 13.8418C6.13786 13.5348 6.26656 12.8948 5.96136 12.4358L2.33637 6.99785L5.96136 1.55987C6.26656 1.10087 6.13786 0.460868 5.68006 0.153868C5.45116 0.000868357 5.18006 -0.0211316 4.93006 0.0288683ZM10.93 0.0288683C10.68 0.0788682 10.4265 0.20585 10.2738 0.43585L6.27376 6.43584C6.05066 6.77084 6.05066 7.22486 6.27376 7.55986L10.2738 13.5599C10.579 14.0198 11.2222 14.1478 11.68 13.8418C12.1378 13.5348 12.2665 12.8948 11.9613 12.4358L8.33635 6.99785L11.9613 1.55987C12.2665 1.10087 12.1378 0.460868 11.68 0.153868C11.4511 0.000868357 11.18 -0.0211316 10.93 0.0288683Z" fill="currentColor"/>
                        </svg>
                    </button>
                </li>
                <li
                    class="rounded-md bg-transparent px-1 py-1 text-moss cursor-pointer border border-moss/50 w-8 h-8"
                >
                    <button
                        on:click|preventDefault={() =>
                            setCurrentPage(meta?.current_page - 1)}
                        class="flex items-center justify-center w-full h-full"
                    >
                        <svg width="80%" height="80%" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.7784 0.0219409C5.5284 0.0719408 5.2774 0.197946 5.1254 0.427946L1.13942 6.42794C0.916418 6.76394 0.916418 7.21694 1.13942 7.55294L5.1254 13.5529C5.4304 14.0119 6.0684 14.1409 6.5264 13.8339C6.9834 13.5279 7.11139 12.8869 6.80639 12.4279L3.1944 6.98995L6.80639 1.55294C7.11139 1.09295 6.9834 0.45294 6.5264 0.146941C6.2974 -0.00705908 6.0284 -0.029059 5.7784 0.0219409Z" fill="currentColor"/>
                        </svg>
                    </button>
                </li>
            {/if}

            {#each [3, 2, 1] as index}
                {#if meta?.current_page - index > 0}
                    <li
                        class="rounded-md px-2 py-1 text-moss cursor-pointer border border-moss/50 w-8 h-8 {meta?.current_page ===
                        meta?.current_page - index
                            ? 'bg-moss text-white'
                            : 'bg-transparent cursor-pointer'}"
                    >
                        <button
                            on:click|preventDefault={() =>
                                setCurrentPage(meta?.current_page - index)}
                            class="flex items-center justify-center w-full h-full"
                        >
                            {meta?.current_page - index}
                        </button>
                    </li>
                {/if}
            {/each}

            <li
                class="rounded-md bg-moss p-0 text-white cursor-pointer border border-moss/50 w-8 h-8"
            >
                <div class="flex items-center justify-center w-full h-full">
                    {meta?.current_page}
                </div>
            </li>

            {#each Array(4) as _, index}
                {#if meta?.current_page + (index + 1) <= meta?.last_page}
                    <li
                        class="rounded-md bg-moss p-0 cursor-pointer border border-moss/50 w-8 h-8 {meta?.current_page ===
                        meta?.current_page + (index + 1)
                            ? 'bg-moss text-white'
                            : 'bg-transparent text-moss cursor-pointer'}"
                    >
                        <button
                            on:click|preventDefault={() =>
                                setCurrentPage(
                                    meta?.current_page + (index + 1),
                                )}
                            class="flex items-center justify-center w-full h-full"
                        >
                            {meta?.current_page + (index + 1)}
                        </button>
                    </li>
                {/if}
            {/each}

            {#if meta?.current_page < meta?.last_page}
                <li
                    class="rounded-md bg-transparent px-1 py-1 text-moss cursor-pointer border border-moss/50 w-8 h-8"
                >
                    <button
                        on:click|preventDefault={() =>
                            setCurrentPage(meta?.current_page + 1)}
                        class="flex items-center justify-center w-full h-full"
                    >
                        <svg width="80%" height="80%" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.16615 0.0219409C1.91615 -0.029059 1.64715 -0.00705907 1.41815 0.146941C0.961155 0.45294 0.833156 1.09294 1.13816 1.55294L4.75015 6.98994L1.13816 12.4279C0.833156 12.8869 0.961155 13.5279 1.41815 13.8339C1.87615 14.1399 2.51415 14.0119 2.81915 13.5529L6.80513 7.55293C7.02813 7.21693 7.02813 6.76393 6.80513 6.42793L2.81915 0.427946C2.66715 0.197946 2.41615 0.0719407 2.16615 0.0219409Z" fill="currentColor"/>
                        </svg>
                    </button>
                </li>
                <li
                    class="rounded-md bg-transparent px-1 py-1 text-moss cursor-pointer border border-moss/50 w-8 h-8"
                >
                    <button
                        on:click|preventDefault={() =>
                            setCurrentPage(meta?.last_page)}
                        class="flex items-center justify-center w-full h-full"
                    >
                        <svg width="80%" height="80%" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.30362 0.0288683C1.05363 -0.0211316 0.782526 0.000868357 0.553626 0.153868C0.0958272 0.460868 -0.032872 1.10087 0.272327 1.55987L3.89732 6.99785L0.272327 12.4358C-0.032872 12.8948 0.0958272 13.5348 0.553626 13.8418C1.01143 14.1478 1.65462 14.0198 1.95992 13.5599L5.95991 7.55986C6.18301 7.22486 6.18301 6.77084 5.95991 6.43584L1.95992 0.43585C1.80722 0.20585 1.55362 0.0788682 1.30362 0.0288683ZM7.30361 0.0288683C7.05361 -0.0211316 6.78251 0.000868357 6.55361 0.153868C6.09581 0.460868 5.96711 1.10087 6.27231 1.55987L9.8973 6.99785L6.27231 12.4358C5.96711 12.8948 6.09581 13.5348 6.55361 13.8418C7.01141 14.1478 7.65461 14.0198 7.95991 13.5599L11.9599 7.55986C12.183 7.22486 12.183 6.77084 11.9599 6.43584L7.95991 0.43585C7.80721 0.20585 7.55361 0.0788682 7.30361 0.0288683Z" fill="currentColor"/>
                        </svg>
                    </button>
                </li>
            {/if}
        </ul>
    {/if}
</main>
