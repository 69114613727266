<script>
    import { jobs, premiumjobs, service as jobService } from '@/services/job';
    import Listing from '@/components/listing/Listing.svelte';
    import LoadingDots from '@/components/LoadingDots.svelte';
    import Filter from '@/icons/Filter.svelte';
    import PremiumJobSlider from '@/components/PremiumJobSlider.svelte';

    import { openSearch, preparedSearch } from "@/stores/globalsearch";

    let loading = false;
    let lastPage = false;

    const loadMore = async () => {
        loading = true;

        if($jobs?.meta?.current_page >= $jobs?.meta?.last_page) {
            lastPage = true;
            loading = false;
            return;
        }

        await jobService.search({
            ...$preparedSearch,
        }, {
            params: {
                page: $jobs?.meta?.current_page ? $jobs?.meta?.current_page + 1 : 1,
                append: true
            }
        });

        loading = false;
    }
</script>

<main class="flex flex-col gap-2 my-4">
    {#if $premiumjobs?.data?.length > 0}
        <div class="py-8 mb-8">
            <h2 class="text-moss">
                Unsere Favoriten:
            </h2>
            {#if loading === false}
                <div class="mt-4">
                    <PremiumJobSlider jobs={$premiumjobs} clickable={true}/>
                </div>
            {/if}
        </div>
    {/if}

    {#if $jobs?.data?.length > 0}
        <h2 class="text-moss mb-2">
            Alle Stellen:
        </h2>

        <div class="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-2 gap-4">
            {#each $jobs.data as job}
                <Listing {job} containerClass="h-fit"/>
            {/each}
        </div>

        <div class="flex flex-col mt-2 text-center">
            {#if lastPage === true}
                <div class="mb-2">
                    <p>
                        Keine weiteren Ergebnisse
                    </p>
                </div>
                <button class="button moss" on:click={(e) => openSearch()}>
                    Filter anpassen
                </button>
            {:else if loading === true}
                <button class="button moss">
                    <LoadingDots />
                </button>
            {:else if $jobs?.meta?.current_page < $jobs?.meta?.last_page}
                <button on:click={() => loadMore()} class="button moss">
                    Weitere Jobs laden...
                </button>
            {/if}
        </div>
    {:else}
        <div class="mt-16 text-center">
            <p class="text-xl text-moss font-bold">
                Suche nach passenden Stellenanzeigen
            </p>
            <p class="text-limegreen">
                Deine aktuellen Filtereinstellungen liefert keine Ergebnisse. <br/>
                Passe den Filter an um Stellenanzeigen zu finden.
            </p>
            <button on:click={() => openSearch()} class="button sm limegreen flex items-center gap-2 !w-auto mx-auto !text-white !rounded-full mt-3">
                Suche
                <Filter mainClass="text-white"/>
            </button>
        </div>
    {/if}
</main>