<script>
    export let mainClass;
</script>

<svg class={mainClass} viewBox="0 0 97 97" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    <g transform="matrix(1,0,0,1,-18,-10)">
        <g>
            <path d="M66.5,10L68.835,13.941L71.57,10.266L73.48,14.429L76.584,11.06L78.049,15.4L81.487,12.374L82.49,16.844L86.227,14.193L86.757,18.743L90.75,16.498L90.802,21.078L95.008,19.263L94.58,23.824L98.953,22.458L98.051,26.949L102.543,26.047L101.176,30.42L105.737,29.992L103.921,34.198L108.502,34.25L106.257,38.243L110.807,38.773L108.156,42.51L112.626,43.513L109.6,46.952L113.94,48.416L110.571,51.52L114.734,53.43L111.059,56.165L115,58.5L111.059,60.835L114.734,63.57L110.571,65.48L113.94,68.584L109.6,70.049L112.626,73.487L108.156,74.49L110.807,78.227L106.257,78.757L108.502,82.75L103.921,82.802L105.737,87.008L101.176,86.58L102.543,90.953L98.051,90.051L98.953,94.543L94.58,93.176L95.008,97.737L90.802,95.921L90.75,100.502L86.757,98.257L86.227,102.807L82.49,100.156L81.487,104.626L78.049,101.6L76.584,105.94L73.48,102.571L71.57,106.734L68.835,103.059L66.5,107L64.165,103.059L61.43,106.734L59.52,102.571L56.416,105.94L54.952,101.6L51.513,104.626L50.51,100.156L46.773,102.807L46.243,98.257L42.25,100.502L42.198,95.921L37.992,97.737L38.42,93.176L34.047,94.543L34.949,90.051L30.458,90.953L31.824,86.58L27.263,87.008L29.078,82.802L24.498,82.75L26.743,78.757L22.193,78.227L24.844,74.49L20.374,73.487L23.4,70.049L19.06,68.584L22.429,65.48L18.266,63.57L21.941,60.835L18,58.5L21.941,56.165L18.266,53.43L22.429,51.52L19.06,48.416L23.4,46.952L20.374,43.513L24.844,42.51L22.193,38.773L26.743,38.243L24.498,34.25L29.078,34.198L27.263,29.992L31.824,30.42L30.458,26.047L34.949,26.949L34.047,22.458L38.42,23.824L37.992,19.263L42.198,21.078L42.25,16.498L46.243,18.743L46.773,14.193L50.51,16.844L51.513,12.374L54.952,15.4L56.416,11.06L59.52,14.429L61.43,10.266L64.165,13.941L66.5,10Z" style="fill-rule:nonzero;" fill="currentColor"/>
        </g>
    </g>
</svg>
