<script>
    import { Map } from 'mapbox-gl';
    import 'mapbox-gl/dist/mapbox-gl.css';
    import { onMount, onDestroy } from 'svelte';

    export let location;
    export let radius;

    let map;
    let mapContainer;

    let defaultLocation = {
        latitude: 51.1642292,
        longitude: 10.4541194
    };

    function getZoomLevelFromRadius(radiusInKm) {
        var minZoom = 5;
        var maxZoom = 17;
        var minRadius = 1;
        var maxRadius = 100;

        radiusInKm = Math.max(minRadius, Math.min(radiusInKm, maxRadius));

        var logMinRadius = Math.log(minRadius);
        var logMaxRadius = Math.log(maxRadius);
        var logRadius = Math.log(radiusInKm);

        var zoomLevel = maxZoom - ((logRadius - logMinRadius) / (logMaxRadius - logMinRadius)) * (maxZoom - minZoom);

        return zoomLevel;
    }

    var createGeoJSONCircle = function(center, radiusInKm, points) {
        if(!points) points = 64;

        var coords = {
            latitude: center[1],
            longitude: center[0]
        };

        var ret = [];
        var distanceX = radiusInKm/(111.320*Math.cos(coords.latitude*Math.PI/180));
        var distanceY = radiusInKm/110.574;

        var theta, x, y;

        for(var i=0; i<points; i++) {
            theta = (i/points)*(2*Math.PI);
            x = distanceX*Math.cos(theta);
            y = distanceY*Math.sin(theta);

            ret.push([coords.longitude+x, coords.latitude+y]);
        }
        
        ret.push(ret[0]);

        return {
            "type": "geojson",
            "data": {
                "type": "FeatureCollection",
                "features": [{
                    "type": "Feature",
                    "geometry": {
                        "type": "Polygon",
                        "coordinates": [ret]
                    }
                }]
            }
        };
    };

    $: if (location) {
        if(map) {
            let zoom = 10;

            if(location.latitude === "" && location.longitude === "") {
                location.latitude = defaultLocation.latitude;
                location.longitude = defaultLocation.longitude;
                zoom = 4;
            }
            else {
                zoom = getZoomLevelFromRadius(radius);
            }

            map.flyTo({
                center: [location.longitude, location.latitude],
                zoom: zoom
            });

            if(map.getSource('polygon')) {
                map.getSource('polygon').setData(
                    createGeoJSONCircle([location.longitude, location.latitude], radius).data
                );
            }
        }
    }

    onMount(() => {
        let zoom = 4;

        if(location.latitude === "" && location.longitude === "") {
            location.latitude = defaultLocation.latitude;
            location.longitude = defaultLocation.longitude;
            zoom = 4;
        }
        else {
            zoom = getZoomLevelFromRadius(radius);
        }

        map = new Map({
            container: mapContainer,
            accessToken:"pk.eyJ1IjoibWFwYm94Z3JlZW5jYXJlZXJzIiwiYSI6ImNtMTVhb3VnNjA2ZTIya3NqOHdiaW1xcHUifQ.APQFuZAl-Yf4hZI5qeERew",
            style: `mapbox://styles/mapbox/outdoors-v11`,
            center: [location.longitude, location.latitude],
            zoom: zoom
        });

        map.on('load', () => {
            map.addSource("polygon", createGeoJSONCircle([location.longitude, location.latitude], radius));

            map.addLayer({
                "id": "polygon",
                "type": "fill",
                "source": "polygon",
                "layout": {},
                "paint": {
                    "fill-color": "rgb('0,210,97')",
                    "fill-opacity": 0.6,
                    "stroke-color": "rgb('0,0,0')",
                    "stroke-width": 20,
                    "stroke-opacity": 1
                }
            });
        });
    });

    onDestroy(() => {
        map.remove();
    });
</script>

<div class="map-wrap relative w-full aspect-video">
  <div class="map" bind:this="{mapContainer}" />
</div>

<style>
    .map {
        position: absolute;
        width: 100%;
        height: 100%;
        @apply rounded-lg; 
    }
</style>