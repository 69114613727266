<script>
    document.title = "Green Careers - Checkout";

    import { fly } from "svelte/transition";
    import { link } from "svelte-routing";
</script>

<main in:fly out:fly>
    <div
        class="flex items-center justify-center min-h-screen py-24 bg-earth"
    >
        <div class="w-screen px-6 mt-4 space-y-8 md:mt-0 md:px-2 max-w-lg">
            <div
                class="p-8 space-y-4 bg-gray-700 text-white backdrop-blur-xl shadow-2xl rounded-md relative bg-sandlight"
            >
                <h2 class="text-center text-moss">
                    Bestellung fehlgeschlagen
                </h2>
                <p class="text-center text-limegreen">
                    Es gab einen Fehler bei der Verarbeitung Ihrer Zahlung. Bitte melden Sie sich bei unserem Support-Team.
                </p>
                <a href="/" use:link class="button moss block text-center">
                    Zurück zur Startseite
                </a>
            </div>
        </div>
    </div>
</main>