import Api from "../api.js"
import { writable } from "svelte/store";
import axios from "@/lib/axios.js"

export const benefits = writable();
export const benefit = writable();

class BenefitService extends Api {
    constructor() {
        super('/api/benefits', benefits, benefit, 'benefit');
    }

    async search(data, config) {
        return await axios
            .post(`/api/benefits/search`, data, config)
            .then(async (response) => {
                return response;
            })
            .catch((error) => {
                console.log(error);
                return Promise.reject(error);
            });
    }
}

export const service = new BenefitService('/api/benefits', benefits, benefit);