<script>
    export let formID;

    import Map from "@/components/Map.svelte"
    import Geocoder from "@/components/filter/Geocoder.svelte"
    import Information from "@/icons/Information.svelte"
    import Range from "@/components/form/Range.svelte"
    import InputError from "@/components/form/InputError.svelte"

    import { search } from "@/stores/globalsearch"
    import { merge, omit } from "lodash"

    export let hightlightClass = "text-poisongreen"

    let radius = $search?.location?.radius ? $search?.location?.radius : 30;

    const setLocation = (e) => {
        return search.update((store) => {
            if(e.detail.latitude === "" || e.detail.longitude === "") {
                store = omit(store, 'location');
                return store;
            }
            else {
                store.location = {...e.detail, radius: radius}
            }

            return store;
        })
    }

    const setRadius = (e) => {
        return search.update((store) => {
            radius = e.detail.value

            store.location = merge(store.location, {
                radius: e.detail.value
            })

            return store;
        })
    }

    let hasErrors = false;
    let name = "latitude";
</script>

<section id="location">
    <slot></slot>

    <div class="mt-2">
        <Geocoder 
            value={$search?.location?.formatted_address ?? ""} 
            on:location={(location) => setLocation(location)} 
            placeholder="Ort, PLZ, Bundesland eingeben"
        />
        
        {#if $search.location}
            <p class="mt-2">
                Region / Umkreis:
            </p>

            <div class="flex flex-row gap-2 my-2 {hightlightClass}">
                <Information mainClass="w-5"/>
                <p>
                    Wähle deinen Radius
                </p>
            </div>

            <Map radius={radius} location={$search.location}/>

            <div>
                <Range hightlightClass={hightlightClass} initialValue="{radius}" min={30} on:change={(e) => setRadius(e)} />
                <div class="flex flex-row justify-between text-xs mt-1 {hightlightClass === "text-limegreen" ? "text-moss" : "text-white"}">
                    <span class="w-8 text-center">
                        30km
                    </span>
                    <span class="w-8 text-center">
                        65km
                    </span>
                    <span class="w-8 text-center">
                        100km
                    </span>
                </div>
            </div>
        {/if}
    </div>

    <InputError bind:hasErrors {name} {formID} value={$search.location}/>
</section>