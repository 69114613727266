<script>
    export let open = false;
    export let job = {};
    
    import Popup from "@/components/Popup.svelte";
    import Select from "@/components/form/Select.svelte";
    import StarBadge from "@/icons/StarBadge.svelte";
    import LoadingDots from "@/components/LoadingDots.svelte";
    import Checkmark from "@/icons/Checkmark.svelte";

    import { service as employerService, employer } from "@/services/employer";
    import { service as jobService } from "@/services/job";
    import { user } from "@/services/user";
    import { failure, success } from "@/lib/toast";
    import { link, navigate } from "svelte-routing";
    import { api as checkoutService } from "@/services/checkout";
    import { onMount } from "svelte"

    let listOpen = undefined;
    let loading = true;

    // TODO: hier default setzen wenn nur eins von beiden verfügbar ist sonst premium als default
    let amount = undefined;
    let items = undefined;
    let selectDisabled = false;
    let mode = undefined;
    let state = "upgrade";

    const showSelection = () => {
        listOpen = !listOpen;
    }

    const changeAmount = (e) => {
        amount = e.detail.detail;
    }

    const publish = async () => {
        let unlistenChanges = () => {
            window.Echo.private(`App.Models.User.${$user.data.id}`).stopListening(
                "PublishJobAdvertisementSuccess",
            );

            window.Echo.private(`App.Models.User.${$user.data.id}`).stopListening(
                "PublishJobAdvertisementFailed",
            );
        };

        let listenChanges = () => {
            unlistenChanges();

            window.Echo.private(`App.Models.User.${$user.data.id}`)
                .listen("PublishJobAdvertisementSuccess", (data) => {
                    state = "success";
                    loading = false;
                })
                .listen("PublishJobAdvertisementFailed", (data) => {
                    failure("Es gab einen Fehler beim freischalten Ihrer Anzeige.");
                    loading = false;
                    navigate("/dashboard?tab=inserieren");
                });
        };

        loading = true;

        listenChanges();

        await jobService.publish(job.id, {
            type: amount.id,
        })
        .catch(() => {
            failure("Es gab einen Fehler beim freischalten Ihrer Anzeige.");
            loading = false;
        });
    }

    const upgrade = async () => {
        let unlistenChanges = () => {
            window.Echo.private(`App.Models.User.${$user.data.id}`).stopListening(
                "UpgradeContingentSuccess",
            );

            window.Echo.private(`App.Models.User.${$user.data.id}`).stopListening(
                "UpgradeContingentFailed",
            );
        };

        let listenChanges = () => {
            unlistenChanges();

            window.Echo.private(`App.Models.User.${$user.data.id}`)
                .listen("UpgradeContingentSuccess", (data) => {
                    window.eventbus.emit("reload:employer");
                    window.eventbus.emit("reload:vacancies");
                    window.location.href = data.url;
                    loading = false;
                })
                .listen("UpgradeContingentFailed", (data) => {
                    window.eventbus.emit("reload:employer");
                    window.eventbus.emit("reload:vacancies");

                    failure("Es gab einen Fehler beim upgrade.");
                    loading = false;
                });
        };

        loading = true;

        listenChanges();

        await checkoutService.upgrade({
            intended: window.location.href,
            on_cancel: window.location.href
        });

        window.eventbus.emit("reload:employer");
        window.eventbus.emit("reload:vacancies");
    }

    onMount(async () => {
        loading = true;

        await employerService.show({
            id: $user.data.employers.data[0].id
        });

        if($employer?.data?.free_smart_contingents_count > 0 && $employer?.data?.free_premium_contingents_count > 0) {
            // beides verfügbar
            amount = {id: 'premium', name: 'Premium'};

            items = [
                {id: 'premium', name: 'Premium'},
                {id: 'smart', name: 'Smart'},
            ];

            selectDisabled = false;
            mode = "both";
        }
        else if($employer?.data?.free_smart_contingents_count > 0) {
            // nur smart verfügbar
            amount = {id: 'smart', name: 'Smart'};

            items = [
                {id: 'smart', name: 'Smart'},
            ];

            selectDisabled = true;
            mode = "smart";
        }
        else if($employer?.data?.free_premium_contingents_count > 0) {
            // nur premium verfügbar
            amount = {id: 'premium', name: 'Premium'};

            items = [
                {id: 'premium', name: 'Premium'},
            ];

            selectDisabled = true;
            mode = "premium";
        }

        loading = false;
    })
</script>

<Popup
    open={open}
    on:close={() => (open = false)}
>
    {#if loading}
        <div class="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">
            <LoadingDots/>
        </div>
    {/if}
    {#if state === "upgrade"}
        <div class="{loading ? "opacity-40" : "opacity-100"}">
            <strong class="text-xl lg:text-2xl text-snow">
                Inserat veröffentlichen
            </strong>

            <div class="hidden lg:block">
                {#if mode === "both"}
                    <p class="text-poisongreen mt-2">
                        Wählen Sie aus, welches Stellenkontingent Sie für diese Anzeige nutzen möchten.
                    </p>
                {:else if mode === "smart"}
                    <p class="text-poisongreen mt-2">
                        Schalten Sie Ihr verfügbares Smart-Kontingent frei oder upgraden Sie vergünstigt auf Premium.
                    </p>
                {:else if mode === "premium"}
                    <p class="text-poisongreen mt-2">
                        Schalten Sie Ihr verfügbares Premium-Kontingent frei.
                    </p>
                {/if}
            </div>

            {#if $employer?.data?.free_smart_contingents_count > 0 || $employer?.data?.free_premium_contingents_count > 0}
                <div class="mt-4 lg:mt-8">
                    <button on:click={showSelection} class="w-full border-2 bg-white border-poisongreen px-2 py-1 rounded-md flex items-center gap-1 justify-between">
                        <div class="text-earth font-medium shrink-0">
                            Kontingent:
                        </div>
                        <Select bind:listOpen={listOpen}
                            on:change={(e) => changeAmount(e)}
                            on:input={(e) => changeAmount(e)}
                            searchable={false} 
                            showChevron={true} 
                            itemId="id" 
                            label="name" 
                            disabled={selectDisabled}
                            value={amount} 
                            clearable={false}
                            items={items}
                            containerClass="w-full"
                        >
                            <div slot="item" let:item>
                                {item.name}
                            </div>
                        </Select>
                    </button>
                </div>
            {/if}

            {#if $employer?.data?.free_premium_contingents_count <= 0 && $employer?.data?.free_smart_contingents_count > 0}
                <div class="mt-10 bg-poisongreen/30 p-4 border border-poisongreen rounded-lg relative">
                    <div class="w-24 h-24 absolute -top-10 -right-6">
                        <StarBadge mainClass="text-orange"/>
                        <div class="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 text-center rotate-12">
                            <strong class="text-2xl">
                                30%
                            </strong>
                            <p class="text-brown font-bold text-sm">
                                AKTION
                            </p>
                        </div>
                    </div>
                    <strong class="text-2xl text-white">
                        Jetzt upgraden<br class="lg:hidden"/> auf Premium.
                    </strong>
                    <p class="mt-2">
                        <span class="text-poisongreen font-bold">3x höhere Sichtbarkeit</span> Ihrer Anzeige und viele weitere Vorteile sichern.
                    </p>
                    <button disabled={loading} on:click={upgrade} class="button poisongreen mt-4 !text-black">
                        Jetzt upgraden und profitieren
                    </button>
                </div>
            {/if}
            
            <div class="!text-sandlight mt-4 lg:mt-8">
                <strong class="text-xl">
                    Ihre verfügbaren Kontingente:
                </strong>
                <hr class="border-sandlight my-3"/>
                <div class="flex flex-col gap-2">
                    <div class="flex flex-row items-center gap-3">
                        <strong class="w-8 h-8 bg-poisongreen text-moss rounded-md flex items-center justify-center text-center text-xl">
                            {$employer?.data?.free_smart_contingents_count ?? 0}
                        </strong>
                        <p>
                            Smart-Stellenanzeigen
                        </p>
                    </div>
                    <div class="flex flex-row items-center gap-3">
                        <strong class="w-8 h-8 bg-poisongreen text-moss rounded-md flex items-center justify-center text-center text-xl">
                            {$employer?.data?.free_premium_contingents_count ?? 0}
                        </strong>
                        <p>
                            Premium-Stellenanzeigen
                        </p>
                    </div>
                </div>
            </div>

            {#if $employer?.data?.free_smart_contingents_count <= 0 && $employer?.data?.free_premium_contingents_count <= 0}
                <div class="p-2 text-limegreen border border-limegreen bg-limegreen/20 text-white rounded-md mb-4 mt-6">
                    <p>
                        Ihre Stelle wurde gespeichert. Kaufen Sie jetzt ein Kontingent, um Ihre Stelle zu veröffentlichen.
                    </p>
                </div>
                <div class="mt-2 lg:mt-8">
                    <a href="{`/arbeitgeber?scrollto=unsere-pakete&intended=${window.location.href}&publishPopupOpen=true`}" class="button gradient block text-center">
                        Jetzt Kontingente kaufen
                    </a>
                </div>
            {:else}
                <button disabled={loading} on:click={publish} class="button gradient mt-4 lg:mt-8">
                    Jetzt veröffentlichen
                </button>
            {/if}
        </div>
    {:else if state === "success"}
        <div class="px-0.5 w-full">
            <Checkmark mainClass="text-limegreen w-24 h-24 mx-auto"/>
        </div>

        <div class="text-center mt-4">
            <strong class="text-2xl text-snow !text-center">
                Stellenanzeige erfolgreich veröffentlicht
            </strong>
        </div>

        <div class="flex flex-col lg:flex-row gap-2 mt-6">
            <a href="/dashboard" use:link class="button text-center sandlight">
                Zurück zum Profil
            </a>
            <a href="/{job.slug}" use:link class="button text-center gradient">
                Stellenanzeige ansehen
            </a>
        </div>
    {/if}
</Popup>