import Api from "../api.js"
import { writable, get } from "svelte/store";
import axios from "@/lib/axios.js"
import { success, failure, warning } from "@/lib/toast.js";

export const premiumjobs = writable();
export const newest = writable();
export const jobs = writable();
export const job = writable();

class JobService extends Api {
    constructor() {
        super('/api/job-advertisements', jobs, job, 'job');
    }

    async search(data, config, store = jobs) {
        return await axios
            .post("/api/job-advertisements/search", data, config)
            .then(async (response) => {
                if(config?.params?.append === true) {
                    store.set({
                        ...response.data,
                        data: [...get(store).data, ...response.data.data]
                    });

                    window.eventbus.emit("append:vacancies");
                }
                else {
                    store.set(response.data);
                    
                    window.eventbus.emit("set:vacancies");
                }

                return response;
            })
            .catch((error) => {
                if(error?.response?.data?.message) warning("Bitte fülle die fehlenden Pflichtfilter aus.")
                console.log(error);
                return Promise.reject(error);
            });
    }

    async index(config = {}, store = jobs) {
        return await axios
            .get(`${this.baseUrl}`, config)
            .then(async (response) => {
                if(config.params?.append === true) {
                    store.set({
                        ...response.data,
                        data: [...get(store).data, ...response.data.data]
                    });

                    window.eventbus.emit("append:vacancies");
                }
                else {
                    store.set(response.data);

                    window.eventbus.emit("set:vacancies");
                }

                return response;
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    async newest(config = {}) {
        return await axios
            .get(`/api/job-advertisements/newest`, config)
            .then(async (response) => {
                if(config.params?.append === true) {
                    newest.set({
                        ...response.data,
                        data: [...get(newest).data, ...response.data.data]
                    });
                }
                else {
                    newest.set(response.data);
                }
                return response;
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    async publish(id, data, config = {}) {
        return await axios
            .put(`${this.baseUrl}/${id}/publish`, data, config)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    async terminate(id, data, config = {}) {
        return await axios
            .put(`${this.baseUrl}/${id}/terminate`, data, config)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }
}

export const service = new JobService('/api/job-advertisements', jobs, job);