<script>
    import { onMount } from "svelte";

    export let number;
    export let duration = 500;
    export let prefix;
    export let suffix;

    export let mainClass;
    export let formatter = (number) => Intl.NumberFormat().format(number);

    let counter = null

    class CountUp {
        constructor(trigger, counter) {
            let start = 0;
            const stop = parseInt(counter.dataset.stop);
            const totalDuration = parseInt(counter.dataset.duration);
            const totalIncrements = stop - start;
            const steps = 100;
            const stepSize = totalIncrements / steps;
            const intervalTime = totalDuration / steps;

            const countUp = () => {
                if (start + stepSize <= stop) {
                    start += stepSize;
                } else {
                    start = stop; // Ensure it stops exactly at the stop value
                }
                counter.textContent = formatter(Math.round(start));
            };

            const observer = new IntersectionObserver((el) => {
                if (el[0].isIntersecting) {
                    const interval = setInterval(() => {
                        (start < stop) ? countUp() : clearInterval(interval);
                    }, intervalTime);
                }
                else {
                    start = 0;
                }
            }, { threshold: [0] });

            observer.observe(trigger);
        }
    }

    onMount( () => {
        new CountUp(counter, counter)
    })
</script>

<main class="{mainClass}">
    <div class="flex flex-row gap-1 justify-center font-medium">
        {#if prefix}
            <p class="text-4xl">{prefix}</p>
        {/if}
        <span bind:this="{counter}" class="text-4xl" data-stop="{number}" data-duration={duration}>
            {formatter(number)}
        </span>
        {#if suffix}
            <p class="text-4xl">{suffix}</p>
        {/if}
    </div>
    <div class="text-center mt-2">
        <slot/>
    </div>
</main>