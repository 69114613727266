import Api from "../api.js"
import { writable } from "svelte/store";

export const transactions = writable();
export const transaction = writable();

class TransactionService extends Api {
    constructor() {
        super('/api/transactions', transactions, transaction, 'transaction');
    }
}

export const service = new TransactionService('/api/transactions', transactions, transaction);