import { SvelteToast, toast } from "@zerodevx/svelte-toast";

new SvelteToast({
    target: document.body,
    props: {
        options: {
            duration: 10000,
            intro: { y: 1000, x: 0 },
        },
    },
});

export const success = (message, props) =>
    toast.push(
        message,
        _.merge(props, {
            theme: {
                "--toastBackground": "white",
                "--toastColor": "rgb(var(--tw-moss) / var(--tw-text-opacity))",
                "--toastProgressBackground": "rgb(var(--tw-moss) / var(--tw-text-opacity))",
                "--toastBorderColor": "rgb(var(--tw-moss) / var(--tw-text-opacity))",
            },
        }),
    );

export const warning = (message, props) =>
    toast.push(
        message,
        _.merge(props, {
            theme: {
                "--toastBackground": "white",
                "--toastColor": "rgb(var(--tw-moss) / var(--tw-text-opacity))",
                "--toastProgressBackground": "rgb(var(--tw-orange) / var(--tw-text-opacity))",
                "--toastBorderColor": "rgb(var(--tw-orange) / var(--tw-text-opacity))",
            },
        }),
    );

export const failure = (message, props) =>
    toast.push(
        message,
        _.merge(props, {
            theme: {
                "--toastBackground": "white",
                "--toastColor": "rgb(var(--tw-moss) / var(--tw-text-opacity))",
                "--toastProgressBackground": "rgb(var(--tw-red) / var(--tw-text-opacity))",
                "--toastBorderColor": "rgb(var(--tw-red) / var(--tw-text-opacity))",
            },
        }),
    );
