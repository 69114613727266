<script>
    import { search, clear, translatedKeys, removeItemByKey } from "@/stores/globalsearch"
    
    import Filter from "@/icons/Filter.svelte"
</script>

<section>
    <div class="flex flex-row justify-between">
        <p>
            Deine Auswahl:
        </p>
        <button on:click={() => clear()} class="underline text-poisongreen inline-flex flex-row gap-1 text-sm">
            <Filter mainClass="w-6 text-poisongreen"/>
            Zurücksetzen
        </button>
    </div>
    <div class="flex flex-row gap-2 flex-wrap mt-2">
        {#if $search && Object.keys($search).length > 1}
            {#each Object.keys($search) as key}
                {#if key != "updated_at" && key != "location" && $search[key] !== null}
                    {#if typeof $search[key] === "object" && Object.keys($search[key]).length >= 1}
                        {#each $search[key] as item}
                            <div class="bg-white/20 py-1 px-3 rounded-full text-center border border-white/10 inline-flex gap-1 items-center">
                                <p>{item.name}</p>
                                <button class="w-4 h-4" on:click={() => removeItemByKey(key, item)}>
                                    <svg width="100%" height="100%" viewBox="-2 -2 50 50" focusable="false" aria-hidden="true" role="presentation" class="svelte-whdbu1"><path fill="currentColor" d="M34.923,37.251L24,26.328L13.077,37.251L9.436,33.61l10.923-10.923L9.436,11.765l3.641-3.641L24,19.047L34.923,8.124l3.641,3.641L27.641,22.688L38.564,33.61L34.923,37.251z"></path></svg>
                                </button>
                            </div>
                        {/each}
                    {:else if typeof $search[key] === "string"}
                        <div class="bg-white/20 py-1 px-3 rounded-full text-center border border-white/10 inline-flex gap-1 items-center">
                            <p>{translatedKeys[$search[key]]}</p>
                            <button class="w-4 h-4" on:click={() => removeItemByKey(key)}>
                                <svg width="100%" height="100%" viewBox="-2 -2 50 50" focusable="false" aria-hidden="true" role="presentation" class="svelte-whdbu1"><path fill="currentColor" d="M34.923,37.251L24,26.328L13.077,37.251L9.436,33.61l10.923-10.923L9.436,11.765l3.641-3.641L24,19.047L34.923,8.124l3.641,3.641L27.641,22.688L38.564,33.61L34.923,37.251z"></path></svg>
                            </button>
                        </div>
                    {/if}
                {/if}
            {/each}
        {:else}
            <div class="bg-earth/20 p-2 rounded-md w-full text-center border border-white/10">
                <p>Keine Auswahl getroffen</p>
            </div>
        {/if}
    </div>
</section>