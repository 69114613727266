<script>
    export let job;
    export let containerClass;
    export let editable = false;

    import Time from '@/icons/Time.svelte';
    import Location from '@/icons/Location.svelte';
    import ExpandDescription from '@/components/listing/ExpandDescription.svelte';
    import PremiumBadge from '@/components/listing/PremiumBadge.svelte';
    import CompanyLogo from '@/components/listing/CompanyLogo.svelte';

    import Pen from '@/icons/Pen.svelte';
    import Redo from '@/icons/Redo.svelte';
    import Delete from '@/icons/Delete.svelte';

    import moment from 'moment';
    import Chevron from '@/icons/Chevron.svelte';
    import { link } from 'svelte-routing';
    import { navigate } from 'svelte-routing';
    import { clickOutside, getAvailableFileSize } from '@/lib/utils';
    import UpgradeJobPopup from '@/components/UpgradeJobPopup.svelte';
    import DeleteJobPopup from '@/components/DeleteJobPopup.svelte';
    import Company from '@/icons/Company.svelte';
    import Ribbon from '@/icons/Ribbon.svelte';
    import Eye from '@/icons/Eye.svelte';

    let expanded = false;
    let contextMenuOpen = false;
    let expandComponent;

    let deleteJobPopupOpen = false;
    let publishPopupOpen = false;

    const expand = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if(editable) {
            contextMenuOpen = !contextMenuOpen;
        }
        else if(expandComponent) {
            expanded = !expanded;
            expandComponent.toggleOpen();
        }
    }

    const removeJob = () => {
        deleteJobPopupOpen = true;
    }

    const publishJob = () => {
        publishPopupOpen = true;
    }

    const formatPublishedAt = (date) => {
        return moment(date).format("DD.MM.YYYY");
    }

    const getTitleImage = (job) => {
        if(job.title_images.data[0]) {
            return getAvailableFileSize(job.title_images.data[0], "lg");
        }
        else if(job.employer.title_images.data[0]) {
            return getAvailableFileSize(job.employer.title_images.data[0], "lg");
        }
        else {
            return "/images/employee-1.jpg";
        }
    }
</script>

<a use:link href="{editable ? `/dashboard/job/${job.id}` : job.slug}" use:clickOutside on:clickOutside={() => contextMenuOpen = false} class="bg-white transition-all ease-in-out w-full rounded-2xl relative block {containerClass}">
    {#if editable && contextMenuOpen}
        <div class="absolute top-1/3 right-3 rounded-md bg-white z-40 flex flex-col shadow-xl border border-limegreen mt-1">
            {#if job.ending_at !== null && moment(job.ending_at) >= moment()}
                <button on:click|stopPropagation|preventDefault={() => navigate(`/${job.slug}`)} class="flex flex-row gap-1.5 hover:bg-limegreen hover:text-white transition-all ease-in-out p-1 items-center">
                    <Eye mainClass="w-4 h-4"/>
                    Stelle anzeigen
                </button>
            {/if}
            <button on:click|stopPropagation|preventDefault={() => navigate(`/dashboard/job/${job.id}`)} class="flex flex-row gap-1.5 hover:bg-limegreen hover:text-white transition-all ease-in-out rounded-t-md p-1 items-center">
                <Pen mainClass="w-4 h-4"/>
                Bearbeiten
            </button>
            {#if job.terminated_at === null && job.published_at}
                <button on:click|stopPropagation|preventDefault={removeJob} class="flex flex-row gap-1.5 hover:bg-limegreen hover:text-white transition-all ease-in-out p-1 items-center">
                    <Delete mainClass="w-4 h-4"/>
                    Inserat löschen
                </button>
            {/if}
            {#if job.publishable === true}
                <button on:click|stopPropagation|preventDefault={publishJob} class="flex flex-row gap-1.5 hover:bg-limegreen hover:text-white transition-all ease-in-out rounded-b-md p-1 items-center">
                    <Redo mainClass="w-4 h-4"/>
                    Veröffentlichen
                </button>
            {/if}
        </div>
    {/if}
    <div class="flex flex-row gap-3 lg:gap-6 text-left m-3">
        <div class="w-1/3">
            <div class="relative">
                {#if job.employer.logos.data[0]}
                    <CompanyLogo image={job.employer.logos.data[0].thumb} mainClass="w-12 lg:w-20 h-12 lg:h-20 absolute lg:bottom-2 -bottom-2.5 lg:left-2 -left-2.5 !rounded-2xl z-10"/>
                {:else}
                    <img class="w-[50px] lg:w-[75px] aspect-square absolute -bottom-2 -left-2 !rounded-2xl border-2 border-white shadow-xl z-10" src="/images/logo-placeholder.png" alt="Logo Platzhalter"/>
                {/if}
                <div class="w-full h-full aspect-[4/3.7] lg:aspect-[4/2.5] rounded-2xl bg-moss">
                    <img 
                        class="w-full h-full rounded-2xl object-contain object-center" 
                        src="{getTitleImage(job)}" 
                        alt=""
                    />
                </div>
            </div>
        </div>
        <div class="w-2/3 relative flex flex-col">
            {#if job.is_premium}
                <PremiumBadge mainClass="!top-0.5 !right-0.5 !p-1"/>
            {/if}

            <div class="mt-1">
                <small class="text-earth/60">
                    ab: {formatPublishedAt(job.job_available_at)}
                </small>
            </div>
            
            <div class="flex justify-between mt-1 text-limegreen">
                <div class="flex flex-row gap-0.5 items-center grow-0 {editable ? "w-[90%]" : "w-[95%]"}">
                    <div class="grow-1 w-auto max-w-[75%]">
                        <p class="font-bold truncate">
                            {job.position.name}
                        </p>
                    </div>
                    <p class="font-light !text-xs w-[25%] min-w-14">
                        (m/w/d)
                    </p>
                </div>
                {#if editable}
                    <div class="w-[10%] shrink-0">
                        <button on:click={expand} class="bg-white transition-all ease-in-out z-40 cursor-pointer border border-limegreen rounded-md w-7 h-7 flex items-center justify-center shrink-0">
                            <Ribbon mainClass="text-limegreen w-4 h-4 transform {expanded ? 'rotate-180' : ''}"/>
                        </button>
                    </div>
                {:else}
                    <button on:click={expand} class="transition-all ease-in-out z-40 w-[5%] cursor-pointer">
                        <Chevron mainClass="text-earth w-5 h-5 transform {expanded ? 'rotate-180' : ''}"/>
                    </button>
                {/if}
            </div>
            
            {#if job.description}
                <ExpandDescription bind:this={expandComponent}>
                    <div class="pt-2">
                        <hr class="border-earth/20"/>
                        <div class="pt-2">
                            {job.description}
                        </div>
                    </div>
                </ExpandDescription>
            {/if}
        </div>
    </div>
    <div class="flex flex-row justify-between my-4 px-3 max-w-full grow-0">
        <div class="w-1/3 inline-flex gap-1 items-center grow-0 max-w-full px-0.5 lg:justify-start">
            <Time mainClass="w-3 w-3 shrink-0 text-moss"/>
            <p class="text-sm truncate text-left overflow-clip grow-0">
                {job.working_time}
            </p>
        </div>
        <div class="w-1/3 inline-flex gap-1 items-center grow-0 max-w-full px-0.5 lg:justify-center">
            <Company mainClass="w-4 w-3 shrink-0 text-moss"/>
            <p class="text-sm truncate text-left overflow-clip grow-0">
                {job.employer.company}
            </p>
        </div>
        <div class="w-1/3 inline-flex gap-1 items-center grow-0 max-w-full px-0.5 lg:justify-end">
            <Location mainClass="w-3 w-3 shrink-0 text-moss"/>
            <p class="text-sm truncate text-left overflow-clip grow-0">
                {job.location}
            </p>
        </div>
    </div>
</a>

{#if editable}
    <UpgradeJobPopup bind:open={publishPopupOpen} job={job}/>
    <DeleteJobPopup bind:open={deleteJobPopupOpen} job={job}/>
{/if}