<script>
    export let id;

    import { onMount } from "svelte"

    import Swiper, { Pagination, Navigation, Scrollbar } from 'swiper';
    Swiper.use([Pagination, Navigation, Scrollbar]);

    let slider = undefined;

    onMount(() => {
        new Swiper(slider, {
            watchSlidesProgress: true,
            pagination: {
                el: `.slider-${id}-swiper-pagination`,
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            grabCursor: true,
            spaceBetween: 15,
            breakpoints: {
                0: {
                    loop: false,
                    slidesPerView: 1,
                    centeredSlides: true,
                },
            },
        })
    })
</script>

<main>
    <div class="{id}-slider relative mx-auto mb-12">
        <div bind:this={slider} class="overflow-x-hidden">
            <div class="swiper-wrapper">
                <slot/>
            </div>
        </div>
        <div class="swiper-navigation">
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
        </div>
        <div class="slider-{id}-swiper-pagination flex items-center !w-auto px-2 py-3 absolute !-bottom-12 !left-1/2 !right-unset p-2 !-translate-x-1/2 z-10"></div>
    </div>
</main>