import Home from "./pages/Home.svelte"
import Datenschutz from "./pages/Datenschutz.svelte"
import Impressum from "./pages/Impressum.svelte"
import AGB from "./pages/AGB.svelte"
import FAQ from "./pages/FAQ.svelte"
import Kontakt from "./pages/Kontakt.svelte"
import Arbeitgeber from "./pages/Arbeitgeber.svelte"
import About from "./pages/About.svelte"
import Dashboard from "./pages/dashboard/Dashboard.svelte"
import CreateEditJobAdvertisement from "./pages/dashboard/CreateEditJobAdvertisement.svelte"
import PreviewJobAdvertisement from "./pages/dashboard/PreviewJobAdvertisement.svelte"
import Jobs from "./pages/jobs/Jobs.svelte"
import Job from "./pages/jobs/Job.svelte"
import Login from "./pages/authentication/Login.svelte"
import Register from "./pages/authentication/Register.svelte"
import ResetPassword from "./pages/authentication/ResetPassword.svelte"
import ForgotPassword from "./pages/authentication/ForgotPassword.svelte"
import EmailVerify from "./pages/authentication/EmailVerify.svelte"
import Process from "./pages/Process.svelte"
import Failed from "./pages/Failed.svelte"

export const routes = [
    {
        name: "Home",
        path: "/",
        component: Home,
        auth: false,
    },
    {
        name: "Datenschutz",
        path: "/datenschutz",
        component: Datenschutz,
        auth: false,
    },
    {
        name: "Impressum",
        path: "/impressum",
        component: Impressum,
        auth: false,
    },
    {
        name: "AGB",
        path: "/agb",
        component: AGB,
        auth: false,
    },
    {
        name: "FAQ",
        path: "/faq",
        component: FAQ,
        auth: false,
    },
    {
        name: "Kontakt",
        path: "/kontakt",
        component: Kontakt,
        auth: false,
    },
    {
        name: "Über Uns",
        path: "/ueber-uns",
        component: About,
        auth: false,
    },
    {
        name: "Arbeitgeber",
        path: "/arbeitgeber",
        component: Arbeitgeber,
        auth: false,
    },
    {
        name: "Dashboard",
        path: "/dashboard",
        component: Dashboard,
        auth: false,
    },
    {
        name: "Dashboard",
        path: "/dashboard/job",
        component: CreateEditJobAdvertisement,
        auth: false,
    },
    {
        name: "Dashboard",
        path: "/dashboard/job/preview",
        component: PreviewJobAdvertisement,
        auth: false,
    },
    {
        name: "Dashboard",
        path: "/dashboard/job/:id",
        component: CreateEditJobAdvertisement,
        auth: false,
    },
    {
        name: "Jobs",
        path: "/jobs",
        component: Jobs,
        auth: false,
    },
    {
        name: "Jobs",
        path: "/jobs/:id",
        component: Job,
        auth: false,
    },
    {
        name: "Login",
        path: "/login",
        component: Login,
        auth: false,
    },
    {
        name: "Register",
        path: "/register",
        component: Register,
        auth: false,
    },
    {
        name: "ResetPassword",
        path: "/reset-password/:token",
        component: ResetPassword,
        auth: false,
    },
    {
        name: "ForgotPassword",
        path: "/forgot-password",
        component: ForgotPassword,
        auth: false,
    },
    {
        name: "EmailVerify",
        path: "/verify-email",
        component: EmailVerify,
        auth: false,
    },
    {
        name: "Process",
        path: "/checkout/process",
        component: Process,
        auth: false,
    },
    {
        name: "Failed",
        path: "/checkout/failed",
        component: Failed,
        auth: false,
    },
    {
        name: "Verify Email",
        path: "/email/verify",
        component: EmailVerify,
        auth: false,
    },
]