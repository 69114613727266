<script>
    import { syncHeight, countLines } from "@/lib/utils";
    import { spring } from "svelte/motion";
    import { createEventDispatcher, onMount } from "svelte";

    let element;
    let wrapper;
    let open = false;

    export let initialHeight = 0;
    export let index = 0;

    const dispatch = createEventDispatcher();
    const heightSpring = spring(0, { stiffness: 0.4, damping: 1 });

    $: heightStore = syncHeight(element);
    $: heightSpring.set(
        open ? $heightStore || 0 : parseInt(initialHeight || 0),
    );

    export const toggleOpen = () => {
        open = !open
        
        dispatch("toggle", {index: index, open: open})
    };

    onMount(() => {
        initialHeight = wrapper.clientHeight;
    })
</script>

<main class="transition-all ease-in-out grow" bind:this={wrapper}>
    <div class="overflow-hidden" style="height: {$heightSpring}px;">
        <div bind:this={element} class="relative">
            <slot>
                <span class="missing">No description</span>
            </slot>
            {#if !open}
                <div class="absolute left-0 right-0 h-[30px] bg-gradient-to-b from-transparent to-white" style="top: {$heightSpring - 30}px;"></div>
            {/if}
        </div>
    </div>
</main>