<script>
    import { SortableList } from '@sonderbase/svelte-sortablejs';
    import axios from "@/lib/axios";
    import { success, failure } from "@/lib/toast";
    import Information from "@/icons/Information.svelte";
    import Close from "@/icons/Close.svelte";
    import Popup from "@/components/Popup.svelte";

    export let files;
    export let resource_class_name;
    export let resource_id;
    export let collection_name;
    export let containerClass;

    let ids = [];
    let loading = false;
    let confirmDeletePopupOpenId = null;

    const handleSort = (e) => {
        ids = [];

        for (let item of e.from.children) {
            ids.push(item.dataset.id);
        }

        updateOrder(ids);
    }

    const handleDelete = (id) => {
        axios.delete(`/api/files/media/${id}`).then(() => {
            files = files.filter(file => file.id !== id);
            confirmDeletePopupOpenId = null;
            success("Die Datei wurde erfolgreich gelöscht.");
        }).catch((error) => {
            failure("Es gab einen Fehler beim Löschen der Datei.");
            error.response.data.message && failure(error.response.data.message);
        });
    }

    const updateOrder = async (ids) => {
        if (loading) return;
        
        loading = true;
        
        await axios.put("/api/files/set-new-order", {
            resource_class_name: resource_class_name,
            resource_id: resource_id,
            collection_name: collection_name,
            ids: ids
        }).then(() => {
            success("Die Reihenfolge wurde erfolgreich gespeichert.");
        }).catch((error) => {
            failure("Es gab einen Fehler beim Speichern der Reihenfolge.");
            error.response.data.message && failure(error.response.data.message);
        });

        loading = false;
    }
</script>

<main class="{containerClass}">
    {#if files?.length > 0}
        <div class="flex flex-row gap-2 my-2 text-current sm:text-center text-limegreen">
            <Information mainClass="w-5 shrink-0"/>
            <p>
                Sie können die Reihenfolge der Dateien durch Drag & Drop ändern oder Dateien löschen.
            </p>
        </div>

        <SortableList direction="horizontal" animation={150} class="grid grid-cols-2 md:grid-cols-6 gap-2" onSort={handleSort}>
            {#each files as file, index (file.id)}
                <div class="aspect-square relative group cursor-move" data-id={file.id}>
                    <button disabled={loading} class="absolute top-2 right-2 z-10 opacity-0 group-hover:opacity-100 transition-all" on:click={() => confirmDeletePopupOpenId = file.id}>
                        <Close mainClass="w-6 h-6 text-red"/>
                    </button>
                    <img class="w-full h-full object-cover rounded-xl shadow-md border border-moss/50" src="{file.thumb}" alt="thumb"/>
                </div>
            {/each}
        </SortableList>
    {/if}

    <Popup open={confirmDeletePopupOpenId} on:close={() => confirmDeletePopupOpenId = null}>
        <strong class="text-2xl text-snow">
            Datei löschen
        </strong>
        <p class="text-poisongreen mt-2">
            Sind Sie sicher, dass Sie diese Datei löschen möchten?
        </p>
        <div class="flex justify-end mt-4">
            <button on:click={() => confirmDeletePopupOpenId = null} class="button sm limegreen">
                Abbrechen
            </button>
            <button on:click={() => handleDelete(confirmDeletePopupOpenId)} class="button sm red ml-2">
                Löschen
            </button>
        </div>
    </Popup>
</main>