<script>
    export let popover = undefined;
    export let label = undefined;
    export let cols = undefined;
    export let rows = 5;
    export let maxlength = undefined;
    export let disabled = false;
    export let name = undefined;
    export let placeholder = undefined;
    export let required = false;
    export let type = "text";
    export let value = undefined;
    export let inputClass = undefined;
    export let containerClass = undefined;
    export let formID = undefined;

    import Label from "@/components/form/Label.svelte";
    import InputError from "@/components/form/InputError.svelte";
    import { createEventDispatcher } from "svelte";

    const dispatch = createEventDispatcher();

    let hasErrors = undefined;

    const onKeyPress = (e) => {
        // only on enter
        if (e.charCode === 13) {
            dispatch("enter");
        }
    };
</script>

<div class="{containerClass} relative">
    {#if label}
        <Label {label} {required} {popover} />
    {/if}

    <textarea
        {cols}
        {rows}
        {maxlength}
        {disabled}
        {name}
        {placeholder}
        {required}
        {type}
        {value}
        on:input={(event) => (value = event.target.value)}
        on:keypress={onKeyPress}
        class="input w-full border {inputClass} {hasErrors
            ? '!border-red'
            : ''}"
    />

    {#if required}
        <span class="text-limegreen required absolute top-3 left-2.5 z-10 transition-all {value ? 'opacity-0' : 'opacity-100'}">*</span>
    {/if}

    <InputError bind:hasErrors {name} {formID} {value}/>
</div>
