<script>
    export let formID;

    import { search } from "@/stores/globalsearch"
    import InputError from "@/components/form/InputError.svelte"

    export let activeClass = "bg-poisongreen text-moss"
    export let passiveClass = "bg-white/20"

    let hasErrors = false;
    let name = "working_time";

    const change = async (e) => {
        return search.update((store) => {
            store.working_time = e.target.dataset.value
            return store;
        })
    }
</script>

<section id="working_time">
    <slot></slot>

    <div class="grid grid-cols-2 md:grid-cols-4 gap-2 mt-2 {hasErrors ? "border border-red rounded-md p-1" : ""}">
        <button on:click={change} data-value="Vollzeit" class="{$search.working_time === "Vollzeit" ? activeClass : passiveClass} font-medium transition all ease-in-out text-white rounded-md p-4">
            Vollzeit
        </button>
        <button on:click={change} data-value="Teilzeit" class="{$search.working_time === "Teilzeit" ? activeClass : passiveClass} font-medium transition all ease-in-out text-white rounded-md p-4">
            Teilzeit
        </button>
        <button on:click={change} data-value="Saisonarbeit" class="{$search.working_time === "Saisonarbeit" ? activeClass : passiveClass} font-medium transition all ease-in-out text-white rounded-md p-4">
            Saisonarbeit
        </button>
        <button on:click={change} data-value="Ausbildung" class="{$search.working_time === "Ausbildung" ? activeClass : passiveClass} font-medium transition all ease-in-out text-white rounded-md p-4">
            Ausbildung
        </button>
    </div>

    <InputError bind:hasErrors {name} {formID} value={$search.working_time}/>
</section>