<script>
    export let popover = undefined;
    export let formID = undefined;
    export let value = undefined;
    export let name = undefined;
    export let label = undefined;
    export let required = undefined;
    export let option = undefined;
    export let containerClass = undefined;

    import InputError from "@/components/form/InputError.svelte";
    import Label from "@/components/form/Label.svelte";
    import { onMount, createEventDispatcher } from "svelte";

    let hasErrors;
    let checkbox;

    const dispatch = createEventDispatcher();

    onMount(() => {
        checkbox.checked = value;
    });

    const handleCheckbox = (event) => {
        value = event.target.checked;
        dispatch("change", event);
    };
</script>

<div class={containerClass}>
    {#if label}
        <Label {label} {required} />
    {/if}

    <div class="flex flex-col gap-2 w-full {label ? 'mt-2' : ''}">
        <label class="flex flex-row gap-3 cursor-pointer">
            <input
                bind:this={checkbox}
                type="checkbox"
                class="shrink-0"
                {value}
                {name}
                on:change={(event) => handleCheckbox(event)}
            />
            {#if option}
                <div class="flex flex-row gap-3">
                    {@html option}

                    <span class="group">
                        {#if popover}
                            <div class="relative">
                                <span
                                    class="material-icons-outlined text-sm -mt-0.5 ml-1.5 text-white/90"
                                >
                                    info
                                </span>
                                <div
                                    class="absolute pb-4 bottom-4 left-0 -translate-x-1/2 hidden group-hover:block"
                                >
                                    <span
                                        class="whitespace-nowrap backdrop-blur-xl bg-primary/30 px-2 py-0.5 rounded-xl shadow-md border border-primary leading-none"
                                    >
                                        {@html popover}
                                    </span>
                                </div>
                            </div>
                        {/if}
                    </span>
                </div>
            {/if}
        </label>
    </div>

    <InputError bind:hasErrors {name} {formID} {value}/>
</div>
