<script>
    import { TelInput, normalizedCountries } from "svelte-tel-input";
    import Select from "@/components/form/Select.svelte";
    import InputError from "@/components/form/InputError.svelte";
    import Tooltip from "@/components/form/Tooltip.svelte";
    import Information from "@/icons/Information.svelte";

    export let inputClass = "";
    export let telInputClass = "";
    export let formID = "";
    export let value = "";
    export let name = "";
    export let valid = true;
    export let required = false;
    export let containerClass = "";
    export let placeholder = "";
    export let tooltip = "";
    export let selectableCountries = normalizedCountries;

    let selectedCountry = "DE";
    let hasErrors = false;
    let detailedValue = null;
</script>

<div>
    <div class="tel-input flex flex-row items-center bg-sandlight rounded-md border border-white {hasErrors ? '!border-red placeholder:text-red text-red' : ''} {containerClass}">
        <Select
            bind:value={selectedCountry}
            containerClass="w-22 shrink-0"
            placeholder="Vorwahl"
            required={true}
            clearable={false}
            showChevron={false}
            items={selectableCountries}
            itemId="id"
            label="iso2"
            multiFullItemClearable={false}
            multiple={false}
            mainClass="{inputClass}"
            on:change={(e) => selectedCountry = e.detail.detail.id}
            on:input={(e) => selectedCountry = e.detail.detail.id}
        >
            <div slot="item" let:item let:index class="flex flex-row gap-2 items-center group">
                <span class="font-regular flex flex-row gap-2 items-center">
                    <img src={`/images/flags/${item.iso2.toLowerCase()}.svg`} alt={item.name} class="w-4"/>
                    +{item.dialCode}
                </span>
            </div>
            <div slot="selection" let:selection let:index>
                {#if selection?.dialCode}
                    <span class="font-regular flex flex-row gap-2 items-center">
                        <img src={`/images/flags/${selection?.iso2?.toLowerCase()}.svg`} alt={selection?.name} class="w-4"/>
                        {selection?.dialCode ? `+${selection?.dialCode}` : ""}
                    </span>
                {:else}
                    <p class="font-regular flex flex-row gap-2 items-center">
                        Vorwahl
                    </p>
                {/if}
            </div>
        </Select>

        <div class="w-full relative">
            {#if required}
                <span class="text-limegreen required absolute top-3 left-2.5 z-10">*</span>
            {/if}
            <TelInput
                bind:country={selectedCountry}
                bind:value
                bind:valid
                bind:detailedValue
                options={{ autoPlaceholder: false }}
                class="input w-full focus:shadow-none border-l !border-earth/20 !rounded-none !bg-transparent {telInputClass}"
                placeholder={placeholder}
                autoPlaceholder={false}
            />

            {#if tooltip}
                <div class="absolute top-1/2 -translate-y-1/2 right-4">
                    <Tooltip title={tooltip}>
                        <Information mainClass="w-5 text-limegreen"/>
                    </Tooltip>
                </div>
            {/if}
        </div>
    </div>

    <InputError bind:hasErrors {name} {formID} {value}/>
</div>

<style>
    .wrapper :global(.basic-tel-input) {
        height: 32px;
        padding-left: 12px;
        padding-right: 12px;
        border-radius: 6px;
        border: 1px solid;
        outline: none;
    }

    .wrapper :global(.country-select) {
        height: 36px;
        padding-left: 12px;
        padding-right: 12px;
        border-radius: 6px;
        border: 1px solid;
        outline: none;
    }

    .wrapper :global(.invalid) {
        border-color: red;
    }
</style>
