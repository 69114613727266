import { writable, derived, get } from "svelte/store";

export const requiredKeys = ["sectors", "working_time", "job_available_at", "location"];
export const search = writable(JSON.parse(sessionStorage.getItem("search")) || {});

export const singleSearchOpen = writable(false);
export const multiSearchOpen = writable(false);

search.subscribe((store) => {
    Object.keys(store).forEach((key) => {
        if (store[key] === null || store[key] === "") {
            delete store[key];
        }
    });

    store["updated_at"] = new Date();

    store
        ? sessionStorage.setItem("search", JSON.stringify(store))
        : sessionStorage.removeItem("search");
});

export const missingKeys = derived(
	search, ($search) => {
        let missingKeys = [];

        for (let key of requiredKeys) {
            if ($search[key] === undefined || $search[key] === "" || $search[key] === null) {
                missingKeys.push(key);
            }
        }

        return missingKeys;
    }
);

export const preparedSearch = derived(
	search, ($search) => {
        // push the ids to array
        // because the search expects
        // ids not objects for the sectors
        let preparedSectors = [];

        if($search?.sectors?.length > 0) {
            $search.sectors.forEach((sector) => {
                preparedSectors.push(sector.id)
            });
        }

        // refactor the geocoding because the
        // backend requires the location to be
        // received in a different structure as follows
        let preparedLocation = {}

        if($search?.location) {
            preparedLocation["latitude"] = $search.location.latitude
            preparedLocation["longitude"] = $search.location.longitude
            preparedLocation["geo_radius"] = $search.location.radius
        }

        // TODO: additional filtering if needed!

        return {
            ...$search,
            ...preparedLocation,
            ...{sectors: preparedSectors}
        }
    }
);

export const searchComplete = derived(
	missingKeys, ($missingKeys) => {
        return $missingKeys.length > 0 ? false : true;
    }
);

export const translatedKeys = {
    "1_to_3_months": "1-3 Monate",
    "more_than_3_months": "mehr als 3 Monate",
    "Vollzeit": "Vollzeit",
    "Teilzeit": "Teilzeit",
    "Saisonarbeit": "Saisonarbeit",
    "Ausbildung": "Ausbildung"
}

export const clear = () => {
    search.set({});
};


export const removeItemByKey = (key, item) => {
    search.update((store) => {
        if (typeof store[key] === "object" && item) {
            store[key] = store[key].filter((i) => i.id !== item.id)
        }
        else if (typeof store[key] === "string") {
            delete store[key];
        }

        Object.keys(store).forEach((item) => {
            if (store[item] === null || store[item] === "" || store[item] === undefined || store[item].length === 0) {
                delete store[key];
            }
        });

        return store;
    })
}

export const openSearch = () => {
    console.log('test');
    // if singleSearchOpened are given in localStorage
    // we open up the step by step search form but
    // othewhise full search
    localStorage.getItem("singleSearchOpened") === "true"
        ? multiSearchOpen.set(true)
        : singleSearchOpen.set(true)
}

export const closeSearch = () => {
    singleSearchOpen.set(false)
    multiSearchOpen.set(false)
}