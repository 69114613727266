<script>
    import { Router, Route, navigate } from "svelte-routing";
    import { routes } from "@/routes.js";
    import { capslock } from "@/stores/keystate";
    import { onDestroy, onMount } from "svelte";
    import { getQueryParameterByName, removeQueryParams } from "@/lib/utils";
    import { success } from "@/lib/toast";

    import Header from "@/components/Header.svelte";
    import Footer from "@/components/Footer.svelte";
    import Impersonate from "@/components/Impersonate.svelte";
    import MultiSearchWrapper from "@/components/filter/MultiSearchWrapper.svelte";
    import SingleSearchWrapper from "@/components/filter/SingleSearchWrapper.svelte";
    import Fallback from "@/pages/Fallback.svelte";
    import UnsavedChanges from "@/components/UnsavedChanges.svelte";

    let scrollY;
    let outerHeight;
    let header;

    onMount(() => {
        // Check if the user has a verified E-Mail Address
        if (
            getQueryParameterByName("verified") &&
            getQueryParameterByName("verified") == 1
        ) {
            // if pending checkout is in localstorage redirect the user to the employer page
            if(localStorage.getItem("checkoutInvoice") || localStorage.getItem("checkoutStripe")) {
                navigate("/arbeitgeber?processCheckout=1")
            }
            success("Deine E-Mail Adresse wurde erfolgreich verifiziert.");
            removeQueryParams(["verified"]);
        }

        // Register Key Up and Down Event Listener for Caps Lock recognition
        document.addEventListener("keyup", (event) =>
            capsLockEventListener(event),
        );
        document.addEventListener("keydown", (event) =>
            capsLockEventListener(event),
        );

        // Scroll to the Element with the ID in the URL Hash
        if (getQueryParameterByName("scrollto")) {
            setTimeout(function () {
                let element = document.getElementById(
                    getQueryParameterByName("scrollto"),
                );
                if (element) {
                    element.scrollIntoView();
                }
            }, 300);
        }
    });

    onDestroy(() => {
        document.removeEventListener("keyup", (event) =>
            capsLockEventListener(event),
        );
        document.removeEventListener("keydown", (event) =>
            capsLockEventListener(event),
        );
    });

    const capsLockEventListener = (event) => {
        if (typeof event.getModifierState === "function") {
            capslock.set(event?.getModifierState("CapsLock"));
        }
    };

    const appearSections = () => {
        let appearObserver = new IntersectionObserver(appearOnIntersect, {
            threshold: 0,
        });

        Array(...document.querySelectorAll(".appear")).forEach(
            (odometerElement) => {
                odometerElement.innerHTML;
                appearObserver.observe(odometerElement);
            },
        );
    };

    const appearOnIntersect = (entries) => {
        entries.forEach((entry) => {
            entry.isIntersecting
                ? entry.target.classList.add("inview")
                : entry.target.classList.remove("inview");
        });
    };

    const componentInternallyLoaded = () => {
        appearSections();
    };
</script>

<svelte:window bind:scrollY bind:outerHeight />

<Router>
    <main class="w-full flex flex-col min-h-screen relative bg-sandlight max-w-[2400px] mx-auto overflow-x-hidden">
        <Header bind:this={header} />

        <main class="min-h-[var(--app-height)]">
            <div>
                {#each routes as route}
                    <Route path={route.path} let:params>
                        <svelte:component
                            this={route.component}
                            {params}
                            on:fixheader={(event) => header.fixheader(event)}
                            on:showlogo={(event) => header.showlogo(event)}
                            on:showbackdrop={(event) => header.showbackdrop(event)}
                            on:loaded={componentInternallyLoaded}
                        />
                    </Route>
                {/each}
                <Route to="/*">
                    <Fallback 
                        on:fixheader={(event) => header.fixheader(event)}
                        on:showlogo={(event) => header.showlogo(event)}
                        on:showbackdrop={(event) => header.showbackdrop(event)}
                        on:loaded={componentInternallyLoaded} />
                </Route>
            </div>
        </main>

        <!-- <UnsavedChanges/> -->
        <Footer />
        <Impersonate />
        <SingleSearchWrapper />
        <MultiSearchWrapper />
    </main>
</Router>