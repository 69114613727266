<script>
    import Pen from "@/icons/Pen.svelte";
    import { createEventDispatcher } from "svelte";
    
    export let autocomplete = "off";
    export let disabled = false;
    export let name = undefined;
    export let placeholder = undefined;
    export let required = false;
    export let label = undefined;
    export let type = "text";
    export let value = undefined;

    export let formID = undefined;

    export let hasErrors = false;

    import { errors } from "@/stores/error";

    let inputError = false;
    $: hasErrors = inputError ? true : false;

    errors.subscribe((data) => {
        inputError = _.get(data, `${formID}.${name?.replaceAll(".", "-")}`);
    });

    let field;

    const dispatch = createEventDispatcher();

    const onKeyPress = (event) => {
        // only on enter
        if (event.charCode === 13) {
            event.preventDefault();
            dispatch("enter");
        }
    };
</script>

<div class="my-2 border-2 border-dashed rounded-lg bg-poisongreen/30 p-4 relative flex flex-col {hasErrors ? "border-red" : "border-limegreen"}">
    <button class="absolute top-1 right-1 text-limegreen w-5 h-5">
        <Pen mainClass=""/>
    </button>
    <input class="mx-4 text-2xl bg-transparent placeholder:text-limegreen/30 !text-limegreen font-bold text-center appearance-none border-b border-dashed focus:outline-none"
        {autocomplete}
        {disabled}
        {name}
        {placeholder}
        {required}
        {type}
        {value}
        on:input={(event) => (value = event.target.value)}
        on:input={(event) => dispatch("input", event)}
        on:focus={(event) => dispatch("focus", event)}
        on:keypress={onKeyPress}
        bind:this={field}>
    <p class="text-center font-bold text-moss mt-1">
        {label}
    </p>
    {#if inputError}
        <div class="my-1 text-red text-xs text-center">
            {inputError}
        </div>
    {/if}
</div>