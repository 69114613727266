<div class="loader mx-auto"></div>

<style>
    .loader {
        width: 40px;
        aspect-ratio: 4;
        --loader: no-repeat radial-gradient(circle closest-side, rgb(var(--tw-poisongreen)) 90%,#0000);
        background: var(--loader) 0%   50%, var(--loader) 50%  50%, var(--loader) 100% 50%;
        background-size: calc(100%/3) 100%;
        animation: animation 1s infinite linear;
    }
    @keyframes animation {
        33% {
            background-size:calc(100%/3) 0%  ,calc(100%/3) 100%,calc(100%/3) 100%
        }
        50% {
            background-size:calc(100%/3) 100%,calc(100%/3) 0%  ,calc(100%/3) 100%
        }
        66% {
            background-size:calc(100%/3) 100%,calc(100%/3) 100%,calc(100%/3) 0%
        }
    }
</style>