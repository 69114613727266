<script>
    import { onMount } from "svelte"

    import Swiper, { Pagination, Navigation, Scrollbar } from 'swiper';
    Swiper.use([Pagination, Navigation, Scrollbar]);

    import Star from "@/icons/Star.svelte";

    let slider = undefined;

    function setSlideOpacity(swiper) {
        if(window.matchMedia('(max-width: 768px)').matches) {
            swiper.slides.forEach((slide, index) => {
                const slideOffset = Math.abs(slide.progress);
                const opacity = 1 - (slideOffset + 0) + 0.3;
                const scale = 1 - (slideOffset + 0) + 0.93;

                slide.style.opacity = opacity;
                slide.style.scale = Math.min(1, Math.max(0, scale))

                if(index === swiper.activeIndex) {
                    slide.style.transform = `rotate(0deg)`;
                    slide.style.opacity = 1;
                }
            });
        }
    }

    onMount(() => {
        new Swiper(slider, {
            watchSlidesProgress: true,
            pagination: {
                el: '.testimonial-swiper-pagination',
                clickable: true,
            },
            grabCursor: true,
            spaceBetween: 15,
            breakpoints: {
                0: {
                    loop: true,
                    slidesPerView: 1,
                    centeredSlides: true,
                },
                480: {
                    loop: true,
                    slidesPerView: 2,
                    centeredSlides: true,
                },
                768: {
                    loop: false,
                    slidesPerView: 4,
                    centeredSlides: false,
                }
            },
            on: {
                slideChangeTransitionStart: function () {
                    setSlideOpacity(this);
                },
                setTranslate: function () {
                    setSlideOpacity(this);
                },
            },
        })
    })
</script>

<main>
    <div class="mx-1 mt-12 relative">
        <div class="testimonial-slider overflow-visible relative mx-auto">
            <div bind:this={slider}>
                <div class="swiper-wrapper">
                    <div class="swiper-slide" style="height: unset">
                        <div class="bg-white p-1 rounded-md shadow-lg h-full">
                            <div class="relative">
                                <img src="/images/gruenteam-image.jpg" alt="testimonial" class="w-full h-auto object-cover aspect-[16/9] rounded-md object-[center_40%]">
                                <div class="absolute bottom-0 left-8 bg-white p-2 rounded-t-md">
                                    <img src="/images/gruenteam-logo.jpg" class="w-20 h-20 object-contain" alt="logo"/>
                                </div>
                            </div>

                            <div class="p-4 text-center">
                                <div class="text-poisongreen flex flex-row gap-1 items-center justify-center mt-2">
                                    <Star mainClass="w-6 h-6"/>
                                    <Star mainClass="w-6 h-6"/>
                                    <Star mainClass="w-6 h-6"/>
                                    <Star mainClass="w-6 h-6"/>
                                    <Star mainClass="w-6 h-6"/>
                                </div>
                                <div class="text-center mt-2">
                                    <strong>
                                        Michael Bleichner
                                    </strong>
                                    <br/>
                                    <p>
                                        Inhaber & Geschäftsführer der Grün Team GmbH
                                    </p>
                                </div>
                                <hr class="border-earth/30 my-2 w-20 mx-auto"/>
                                <div class="mt-2">
                                    <p class="text-sm">
                                        Dank GreenCareers konnten wir, die Grün Team GmbH 3 unserer offenen Stellen für LKW-Fahrer, Vorarbeiter und Facharbeiter im GaLaBau schnell und ohne großen zeitlichen Aufwand besetzen. Das Karrierenetzwerk funktioniert einfach und wir hatten einen äußerst professionellen Eindruck. Die Spezialisierung auf die grüne Branche merkt man hier.
                                        <br/><br/>
                                        Wir sind sehr zufrieden und empfehlen GreenCareers gerne weiter.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide" style="height: unset">
                        <div class="bg-white p-1 rounded-md shadow-lg h-full">
                            <div class="relative">
                                <img src="/images/huben-image.jpg" alt="testimonial" class="w-full h-auto aspect-[16/9] rounded-md object-[center_10%] object-cover">
                                <div class="absolute bottom-0 left-8 bg-white p-2 rounded-t-md">
                                    <img src="/images/huben-logo.png" class="w-20 h-20 object-contain" alt="logo"/>
                                </div>
                            </div>

                            <div class="p-4 text-center">
                                <div class="text-poisongreen flex flex-row gap-1 items-center justify-center mt-2">
                                    <Star mainClass="w-6 h-6"/>
                                    <Star mainClass="w-6 h-6"/>
                                    <Star mainClass="w-6 h-6"/>
                                    <Star mainClass="w-6 h-6"/>
                                    <Star mainClass="w-6 h-6"/>
                                </div>
                                <div class="text-center mt-2">
                                    <strong>
                                        Carlo Huben
                                    </strong>
                                    <br/>
                                    <p>
                                        Geschäftsführer Pflanzenhandel Huben GmbH
                                    </p>
                                </div>
                                <hr class="border-earth/30 my-2 w-20 mx-auto"/>
                                <div class="mt-2">
                                    <p class="text-sm">
                                        Wir haben GreenCareers als äußerst kompetenten Recruiting-Partner kennengelernt, der sich wirklich auf die grüne Branche versteht und zuverlässig hält, was er verspricht! Die Anlage der Stellenprofile und die Suche verlief professionell und führte schnell zu guten Ergebnissen.
                                        <br/><br/>
                                        Wir bei der Baumschule Huben sind positiv überrascht von der Anzahl und Qualität der Bewerbungen, die wir durch GreenCareers erhalten haben. Der Prozess ist angenehm gestaltet und spart uns viel Zeit. Besonders für Unternehmen in der grünen Branche ist GreenCareers absolut empfehlenswert.
                                        <br/><br/>
                                        Vielen Dank aus Ladenburg!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide" style="height: unset">
                        <div class="bg-white p-1 rounded-md shadow-lg h-full">
                            <div class="relative">
                                <img src="/images/streb-image.jpg" alt="testimonial" class="w-full h-auto aspect-[16/9] rounded-md object-[center_10%] object-cover">
                                <div class="absolute bottom-0 left-8 bg-white p-2 rounded-t-md">
                                    <img src="/images/streb-logo.png" class="w-20 h-20 object-contain" alt="logo"/>
                                </div>
                            </div>

                            <div class="p-4 text-center">
                                <div class="text-poisongreen flex flex-row gap-1 items-center justify-center mt-2">
                                    <Star mainClass="w-6 h-6"/>
                                    <Star mainClass="w-6 h-6"/>
                                    <Star mainClass="w-6 h-6"/>
                                    <Star mainClass="w-6 h-6"/>
                                    <Star mainClass="w-6 h-6"/>
                                </div>
                                <div class="text-center mt-2">
                                    <strong>
                                        Tobias Stierle
                                    </strong>
                                    <br/>
                                    <p>
                                        Betriebsleiter Gartencenter Streb GmbH
                                    </p>
                                </div>
                                <hr class="border-earth/30 my-2 w-20 mx-auto"/>
                                <div class="mt-2">
                                    <p class="text-sm">
                                        Wir haben nach einer Möglichkeit gesucht, unseren Hauptkanal Social Media um eine Plattform zu ergänzen, die uns dauerhaft mit unseren wichtigsten Stellenprofilen sichtbar macht – und mit GreenCareers haben wir genau das gefunden.
                                        <br/><br/>
                                        Besonders beeindruckt hat uns die optische Aufmachung des Portals. Es gibt einfach nichts Vergleichbares für unsere Branche! GreenCareers dient uns als starker Ankerpunkt für passiv aber vor allem aktiv suchende Bewerber. Durch die dauerhafte Präsenz unserer Stellenanzeigen bleiben wir langfristig sichtbar und erhalten dauerhaft Initiativ-Bewerbungen.
                                        <br/><br/>
                                        Für jedes Unternehmen in der grünen Branche, das auf Qualität und Sichtbarkeit im Recruiting setzt, ist GreenCareers eine absolute Empfehlung. Wir sind begeistert und freuen uns auf die weitere Zusammenarbeit.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide" style="height: unset">
                        <div class="bg-white p-1 rounded-md shadow-lg h-full">
                            <div class="relative">
                                <img src="/images/schmittsteul-image.png" alt="testimonial" class="w-full h-auto aspect-[16/9] rounded-md object-[center_10%] object-cover">
                                <div class="absolute bottom-0 left-8 bg-white p-2 rounded-t-md">
                                    <img src="/images/schmittsteul-logo.jpg" class="w-20 h-20 object-contain" alt="logo"/>
                                </div>
                            </div>

                            <div class="p-4 text-center">
                                <div class="text-poisongreen flex flex-row gap-1 items-center justify-center mt-2">
                                    <Star mainClass="w-6 h-6"/>
                                    <Star mainClass="w-6 h-6"/>
                                    <Star mainClass="w-6 h-6"/>
                                    <Star mainClass="w-6 h-6"/>
                                    <Star mainClass="w-6 h-6"/>
                                </div>
                                <div class="text-center mt-2">
                                    <strong>
                                        Vincent Schmitt
                                    </strong>
                                    <br/>
                                    <p>
                                        Geschäftsführer Gartencenter Schmitt-Steul GmbH & Co. KG
                                    </p>
                                </div>
                                <hr class="border-earth/30 my-2 w-20 mx-auto"/>
                                <div class="mt-2">
                                    <p class="text-sm">
                                        Früher haben wir viel Geld in Zeitungs- und Radiowerbung gesteckt – ohne den gewünschten Erfolg. Mit GreenCareers haben wir endlich einen Kanal, der auf unsere Branche zugeschnitten ist und uns dauerhaft sichtbar macht.
                                        <br/><br/>
                                        Die Kombination aus gezielter Ansprache von aktiv suchenden Bewerbern und einer starken Präsenz für passive Talente hat uns überzeugt. Wir konnten schnell erste qualifizierte Bewerbungen gewinnen und sparen dabei Zeit und Aufwand.
                                        <br/><br/>
                                        Für Unternehmen in der grünen Branche ist GreenCareers absolut empfehlenswert!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="testimonial-swiper-pagination flex items-center !w-auto px-2 py-3 absolute !-bottom-12 !left-1/2 !right-unset p-2 !-translate-x-1/2 z-10"></div>
            </div>
        </div>
    </div>
</main>