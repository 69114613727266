import Api from "@/api.js"
import axios from "@/lib/axios.js"
import { writable, get } from "svelte/store";
import { success, failure } from "@/lib/toast.js";
import { service as employerService } from "@/services/employer.js";
import { navigate } from "svelte-routing";

export const users = writable();
export const user = writable();

class UserApi extends Api {
    constructor() {
        super('/api/users', users, user);
    }

    async register(data, config) {
        return await axios
            .post("/register", data, config)
            .then(async (response) => {
                await this.getUser()
                    .then((response) => {
                        success("Sie haben sich erfolgreich registriert");
                        return response;
                    })
                    .catch((error) => {
                        failure("Es gab einen Fehler bei der Registrierung");
                        return Promise.reject(error);
                    });

                return response;
            })
            .catch((error) => {
                failure("Es gab einen Fehler bei der Registrierung");
                return Promise.reject(error);
            });
    }

    async login(data, config) {
        return await axios
            .post("/login", data, config)
            .then(async (response) => {
                await this.getUser()
                    .then(async (response) => {
                        success("Sie haben sich erfolgreich eingeloggt");
                        return response;
                    })
                    .catch((error) => {
                        failure("Es gab einen Fehler bei dem Login");
                        return Promise.reject(error);
                    });

                return response;
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    async logout() {
        return await axios
            .post("/logout")
            .then((response) => {
                success("Sie haben sich erfolgreich ausgeloggt");
                navigate("/");
                user.set();

                location.reload()
                
                return response;
            })
            .catch((error) => {
                success("Sie haben sich erfolgreich ausgeloggt");
                return Promise.reject(error);
            });
    }

    async getUser(config) {
        if(window.meta.user) {
            user.set({data:window.meta.user});
        }
        else {
            return await axios
                .get("/api/user", config)
                .then(async (response) => {
                    user.set(response.data);
                    return response;
                })
                .catch((error) => {
                    return Promise.reject(error);
                });
        }

        // get the employer for current user
        await employerService.show({
            id: get(user).data.employers.data[0].id
        })

        window.Echo.private(`App.Models.User.${get(user).data.id}`)
            .notification(
                (notification) => {
                    if (notification) {
                        window.eventbus.emit("notification", notification);
                    }
                },
            );
    }

    async forgotPassword(data, config) {
        return await axios
            .post("/forgot-password", data, config)
            .then((response) => {
                success("Ein Link zum Zurücksetzen Ihres Passworts wurde an Ihre E-Mail-Adresse gesendet");
                return response;
            })
            .catch((error) => {
                failure("Es gab einen Fehler beim Zurücksetzen Ihres Passworts");
                return Promise.reject(error);
            });
    }

    async resetPassword(data, config) {
        return await axios
            .post("/reset-password", data, config)
            .then((response) => {
                success("Ihr Passwort wurde erfolgreich zurückgesetzt");
                return response;
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    async updateProfileInformation(data, config) {
        return await axios
            .put(`/user/profile-information`, data, config)
            .then(async (response) => {
                success("Ihre Profilinformationen wurden erfolgreich aktualisiert");
                await this.getUser();
                return response;
            })
            .catch((error) => {
                failure("Es gab einen Fehler beim Aktualisieren Ihrer Profilinformationen");
                return Promise.reject(error);
            });
    }

    async updatePassword(data, config) {
        return await axios
            .put(`/user/password`, data, config)
            .then(async (response) => {
                navigate("/login");
                success("Ihr Passwort wurde erfolgreich aktualisiert");
                window.location.reload();
                return response;
            })
            .catch((error) => {
                failure("Es gab einen Fehler beim Aktualisieren Ihres Passworts");
                return Promise.reject(error);
            });
    }
}

export const api = new UserApi('/api/users', users, user);