<script>
    import Close from "@/icons/Close.svelte";

    export let open;
    export let popupClass = undefined;
    export let outterClass = undefined;
    export let closeIcon = true;
    export let allowClickOutside = true;

    import { createEventDispatcher } from "svelte";
    import { clickOutside } from "@/lib/utils";
    import { onMount, onDestroy } from "svelte";

    const dispatch = createEventDispatcher();

    const handleEscape = (event) => {
        // only on escape
        if (event.key === "Escape") {
            if (!(event.ctrlKey || event.altKey || event.shiftKey))
                dispatch("close");
        }
    };

    onMount(() => {
        document.addEventListener("keydown", handleEscape);
    });

    onDestroy(() => {
        document.removeEventListener("keydown", handleEscape);
    });
</script>

{#if open}
    <div
        class="{outterClass} fixed w-full h-screen top-0 left-0 backdrop-blur-md z-[100]"
    >
        <div
            use:clickOutside
            on:clickOutside={() => dispatch("close")}
            class="{popupClass} popup md:w-1/2 lg:w-3/4 xl:w-1/3 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 container mx-auto"
        >
            <div
                class="bg-moss text-white rounded-xl relative shadow-2xl"
            >
                {#if closeIcon}
                    <button
                        on:click={() => dispatch("close")}
                        class="z-10 absolute top-4 right-4 flex flex-row items-center justify-center transition-all text-poisongreen rounded-xl gap-1"
                    >
                        <small>Schliessen</small>
                        <Close mainClass="w-6 h-6"/>
                    </button>
                {/if}

                <div class="min-h-[50px] max-h-[90vh] overflow-y-auto p-6 pt-10">
                    <slot />
                </div>
            </div>
        </div>
    </div>
{/if}

<style>
    .popup > div:before {
        content: "";
        top: -1px;
        z-index: 1;
    }

    .popup > div:before {
        --tw-gradient-from: rgba(0, 0, 0, 0);
        --tw-gradient-to: rgba(0, 0, 0, 0);
        --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
        --tw-gradient-to: rgba(0, 0, 0, 0);
        --tw-gradient-stops: var(--tw-gradient-from), var(--tw-primary),
            var(--tw-gradient-to);
        --tw-gradient-to: rgba(0, 0, 0, 0);
        background-image: linear-gradient(to right, var(--tw-gradient-stops));
        margin-left: auto;
        margin-right: auto;
        position: absolute;
        height: 1px;
        left: 0;
        right: 0;
        width: 70%;
    }
</style>
