import Api from "@/api.js"
import axios from "@/lib/axios.js"
import { writable } from "svelte/store";
import { success, failure } from "@/lib/toast.js";

export const users = writable();
export const user = writable();

class UserApi extends Api {
    constructor() {
        super('/api/contact', users, user);
    }

    async submit(data, config) {
        return await axios
            .post("/api/contact", data, config)
            .then(async (response) => {
                success("Ihre Kontaktanfrage wurde erfolgreich übermittelt");
                return response;
            })
            .catch((error) => {
                failure("Es gab einen Fehler bei der Übermittlung Ihrer Kontaktanfrage");
                return Promise.reject(error);
            });
    }
}

export const api = new UserApi('/api/contact', users, user);