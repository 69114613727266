import './bootstrap';
import './echo';
import "../css/app.css";
import { route } from "./stores/route";
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/grid'
import 'swiper/css';
import { closeSearch } from "./stores/globalsearch";

// Triggers on Route change
history.pushState = new Proxy(history.pushState, {
    apply(target, thisArg, argumentList) {
        Reflect.apply(target, thisArg, argumentList);
        onRouteChanged();
    },
});

// Triggers on Route change
addEventListener("popstate", function (e) {
    onRouteChanged();
});

const onRouteChanged = async () => {
    closeSearch();
    route.set(window.location);
    scrollTo(0, 0);
};

const appHeight = () => {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
}

window.addEventListener('resize', appHeight)

appHeight()