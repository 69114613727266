<script>
    export let currentTab = "jobdescription";
    export let tabs = [];
    export let mode;
    export let loading;

    import { createEventDispatcher, onMount, onDestroy } from "svelte";
    import { stickyUntilElement } from "@/lib/utils";
    
    const dispatch = createEventDispatcher();

    const watchStickyNavbar = () => stickyUntilElement("navigation-bottom", "footer");

    onMount(() => {
        window.addEventListener('scroll', watchStickyNavbar);
    });

    onDestroy(() => {
        window.removeEventListener('scroll', watchStickyNavbar);
    });
</script>

<div class="shadow-xl py-1 bg-white/70 mt-3">
    <div class="container mx-auto max-w-3xl my-2">
        <div class="flex flex-row gap-2 lg:justify-start">
            {#each tabs as tab}
                <button disabled={loading} on:click={() => dispatch("changeCurrentTab", tab.value)} class="{currentTab === tab.value ? "bg-limegreen text-white" : "bg-sandlight text-earth"} px-2 py-1.5 lg:px-4 lg:py-3 rounded-xl transition-all ease-in-out whitespace-nowrap text-sm">
                    {tab.title}
                </button>
            {/each}
        </div>
    </div>
</div>

<div class="bg-moss fixed bottom-0 left-0 right-0 z-20 lg:hidden border-b-2 border-poisongreen" id="navigation-bottom">
    <div class="container mx-auto max-w-3xl my-4">
        <div class="flex flex-row gap-2 justify-center">
            {#each tabs as tab}
                <button disabled={loading} on:click={() => dispatch("changeCurrentTab", tab.value)} class="{currentTab === tab.value ? "bg-limegreen text-white" : "bg-sandlight text-earth"} px-2 py-1.5 lg:px-4 lg:py-3 rounded-xl transition-all ease-in-out whitespace-nowrap text-sm">
                    {tab.title}
                </button>
            {/each}
        </div>
    </div>
</div>