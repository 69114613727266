import Api from "@/api.js"
import { writable } from "svelte/store";

export const employers = writable();
export const employer = writable();

class EmployerApi extends Api {
    constructor() {
        super('/api/employers', employers, employer);
    }
}

export const service = new EmployerApi('/api/employers', employers, employer);