<script>
    export let formID;

    import { search, searchComplete, clear, translatedKeys, removeItemByKey } from "@/stores/globalsearch"
    import { service as sectorService, sectors, sector } from "@/services/sector"
    import { onMount } from "svelte";

    import Select from "@/components/form/Select.svelte"
    import LoadingDots from "@/components/LoadingDots.svelte"

    onMount(() => {
        sectorService.index({
            params: {
                order_by: ['name.asc'],
            }
        })
    })

    const change = (e) => {
        return search.update((store) => {
            store.sectors = e.detail.detail
            return store;
        })
    }
</script>

{#if $sectors?.data?.length > 0}
    <section id="sector">
        <slot/>

        <Select
            value={$search.sectors}
            placeholder="Wähle hier aus..."
            mainClass="mt-2"
            required={true}
            clearable={false}
            showChevron={true}
            items={$sectors.data}
            label="name"
            multiFullItemClearable={false}
            itemId="id"
            multiple={true}
            on:change={change}
            on:input={change}
            {formID}
            name="sectors"
        />
    </section>
{:else}
    <LoadingDots/>
{/if}