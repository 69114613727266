import Api from "../api.js"
import { writable } from "svelte/store";

export const sectors = writable();
export const sector = writable();

class SectorService extends Api {
    constructor() {
        super('/api/sectors', sectors, sector, 'sector');
    }
}

export const service = new SectorService('/api/sectors', sectors, sector);