<script>
    export let jobs;
    export let clickable = false;
    export let overwriteLink = undefined;

    import { onMount } from "svelte"

    import Swiper, { Pagination, Navigation, Scrollbar } from 'swiper';
    Swiper.use([Pagination, Navigation, Scrollbar]);

    import ListingBackgroundImage from "@/components/listing/ListingBackgroundImage.svelte";

    let slider = undefined;
    
    function setSlideOpacity(swiper) {
        if(window.matchMedia('(max-width: 768px)').matches) {
            swiper.slides.forEach((slide, index) => {
                const slideOffset = Math.abs(slide.progress);
                const opacity = 1 - (slideOffset + 0) + 0.3;
                const scale = 1 - (slideOffset + 0) + 0.93;

                slide.style.opacity = opacity;
                slide.style.scale = Math.min(1, Math.max(0, scale))

                if(index === swiper.activeIndex) {
                    slide.style.transform = `rotate(0deg)`;
                    slide.style.opacity = 1;
                }
            });
        }
    }

    onMount(() => {
        new Swiper(slider, {
            watchSlidesProgress: true,
            pagination: {
                el: '.premium-swiper-pagination',
                clickable: true,
            },
            grabCursor: true,
            spaceBetween: 15,
            breakpoints: {
                0: {
                    loop: true,
                    slidesPerView: 1,
                    centeredSlides: true,
                },
                768: {
                    loop: false,
                    slidesPerView: 3,
                    centeredSlides: false,
                }
            },
            on: {
                slideChangeTransitionStart: function () {
                    setSlideOpacity(this);
                },
                setTranslate: function () {
                    setSlideOpacity(this);
                },
            },
        })
    })
</script>

<main>
    <div class="premium-slider overflow-visible relative mx-auto">
        <div bind:this={slider}>
            <div class="swiper-wrapper">
                {#each jobs?.data as job}
                    <div class="swiper-slide">
                        <ListingBackgroundImage {job} {clickable} overwriteLink={overwriteLink}/>
                    </div>
                {/each}
            </div>

            <div class="premium-swiper-pagination flex items-center !w-auto px-2 py-3 absolute !-bottom-12 !left-1/2 !right-unset p-2 !-translate-x-1/2 z-10"></div>
        </div>
    </div>
</main>