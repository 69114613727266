<script>
    export const focus = () => {
        field.focus();
    };

    export let popover = undefined;
    export let tooltip = undefined;
    export let autofocus = false;
    export let autocomplete = "off";
    export let disabled = false;
    export let name = undefined;
    export let placeholder = undefined;
    export let required = false;
    export let label = undefined;
    export let type = "text";
    export let value = undefined;

    export let inputClass = undefined;
    export let containerClass = undefined;

    export let formID = undefined;

    import Label from "@/components/form/Label.svelte";
    import InputError from "@/components/form/InputError.svelte";
    import Tooltip from "@/components/form/Tooltip.svelte";
    import Information from "@/icons/Information.svelte";

    import { createEventDispatcher } from "svelte";

    let hasErrors;
    let field;

    const dispatch = createEventDispatcher();

    const onKeyPress = (event) => {
        // only on enter
        if (event.charCode === 13) {
            event.preventDefault();
            dispatch("enter");
        }
    };
</script>

<div class="{containerClass} relative">
    {#if label}
        <Label {label} {required} {popover} />
    {/if}

    {#if tooltip}
        <div class="absolute top-1/2 -translate-y-1/2 right-4">
            <Tooltip title={tooltip}>
                <Information mainClass="w-5 text-limegreen"/>
            </Tooltip>
        </div>
    {/if}

    <input
        {autofocus}
        {autocomplete}
        {disabled}
        {name}
        {placeholder}
        {required}
        {type}
        {value}
        on:input={(event) => (value = event.target.value)}
        on:input={(event) => dispatch("input", event)}
        on:focus={(event) => dispatch("focus", event)}
        on:keypress={onKeyPress}
        bind:this={field}
        class="input {type} w-full border {inputClass} {hasErrors ? '!border-red placeholder:text-red text-red' : ''}"
    />

    {#if required}
        <span class="text-limegreen required absolute top-3 left-2.5 z-10 transition-all {value ? 'opacity-0' : 'opacity-100'}">*</span>
    {/if}

    <InputError bind:hasErrors {name} {formID} {value}/>
</div>
