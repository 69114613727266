<script>
    import {user} from "@/services/user"
    import {api, impersonation} from "@/services/impersonation"
</script>

<main>
    {#if $impersonation && $user}
        <div
            class="fixed bottom-4 right-[-360px] h-[135px] hover:right-0 w-[400px] bg-gray-400 shadow-md p-4 rounded-l-md z-50 border-t border-b border-l-8 border-red transition-all ease-in-out duration-500"
        >
            <div class="relative">
                <p
                    class="transform rotate-90 absolute top-1 left-2 text-xs w-0 h-0 font-semibold"
                >
                    Impersonate
                </p>
                <div class="ml-8">
                    <div>
                        <p>You are impersonated as:</p>
                        <p>
                            {$user.data.name}
                        </p>
                    </div>
                    <button
                        on:click={() => api.destroy()}
                        class="underline font-semibold !text-left mt-4"
                    >
                        <span> Undo Impersonate </span>
                    </button>
                </div>
            </div>
        </div>
    {/if}
</main>
