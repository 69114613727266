<script>
    export let select = undefined;
    export let mainClass = undefined;
    export let popover = undefined;
    export let formID = undefined;
    export let items = undefined;
    export let value = undefined;
    export let justValue = undefined;
    export let itemId = undefined;
    export let label = undefined;
    export let topLabel = undefined;
    export let id = undefined;
    export let filterText = undefined;
    export let placeholder = undefined;
    export let hideEmptyState = undefined;
    export let listOpen = false;
    export let containerStyles = undefined;
    export let clearable = undefined;
    export let disabled = undefined;
    export let multiple = undefined;
    export let searchable = undefined;
    export let groupHeaderSelectable = undefined;
    export let focused = undefined;
    export let listAutoWidth = undefined;
    export let showChevron = undefined;
    export let inputAttributes = undefined;
    export let placeholderAlwaysShow = undefined;
    export let loading = undefined;
    export let listOffset = undefined;
    export let debounceWait = undefined;
    export let floatingConfig = undefined;
    export let name = undefined;
    export let required = undefined;
    export let multiFullItemClearable = undefined;
    export let containerClass = undefined;
    export let groupBy = undefined;
    export let itemFilter = (label, filterText, option) => label.toLowerCase().startsWith(filterText.toLowerCase());

    import { createEventDispatcher } from "svelte";
    import Select from "svelte-select";
    import Label from "@/components/form/Label.svelte";
    import InputError from "@/components/form/InputError.svelte";

    const dispatch = createEventDispatcher();

    let hasErrors;
    let hasValue = value ? true : false;
    
    $: errorValue = value;

    const checkSelected = (item) => {
        if(value) {
            if (multiple) {
                return value.find((val) => val.name === item.name);
            } else {
                return value === item;
            }
        }
    }
</script>

<div class="{containerClass}">
    {#if topLabel}
        <Label label={topLabel} {required} {popover} />
    {/if}

    <Select
        bind:this={select}
        class={mainClass}
        {items}
        {value}
        {justValue}
        {itemId}
        {label}
        {id}
        {filterText}
        {placeholder}
        {hideEmptyState}
        {listOpen}
        {containerStyles}
        {clearable}
        {disabled}
        {multiple}
        {searchable}
        {groupHeaderSelectable}
        {focused}
        {listAutoWidth}
        {showChevron}
        {inputAttributes}
        {placeholderAlwaysShow}
        {loading}
        {listOffset}
        {debounceWait}
        {floatingConfig}
        {name}
        {required}
        {multiFullItemClearable}
        {groupBy}
        {itemFilter}
        hasError={hasErrors}
        on:change={(event) => {
            errorValue = event.detail.value;
            dispatch("change", event);
            hasValue = event.detail.value ? true : false;
        }}
        on:input={(event) => dispatch("input", event)}
        on:focus={(event) => dispatch("focus", event)}
        on:blur={(event) => dispatch("blur", event)}
        on:clear={(event) => {
            errorValue = event.detail.value;
            dispatch("clear", event);
            hasValue = false;
        }}
        on:loaded={(event) => dispatch("loaded", event)}
        on:error={(type, events) => dispatch("error", type, events)}
        on:filter={(event) => dispatch("filter", event)}
        on:hoverItem={(event) => dispatch("hoverItem", event)}
    >
        <div slot="list-prepend">
            {#if required === false && hasValue}
                <button
                    on:click={() => select.handleClear()}
                    class="w-full flex flex-row justify-between text-white/30 py-1 px-5"
                >
                    <p class="text-left">clear</p>
                    <svg
                        width="100%"
                        height="100%"
                        viewBox="-2 -2 50 50"
                        focusable="false"
                        aria-hidden="true"
                        role="presentation"
                        class="svelte-whdbu1 mr-5"
                        ><path
                            fill="currentColor"
                            d="M34.923,37.251L24,26.328L13.077,37.251L9.436,33.61l10.923-10.923L9.436,11.765l3.641-3.641L24,19.047L34.923,8.124 l3.641,3.641L27.641,22.688L38.564,33.61L34.923,37.251z"
                        ></path></svg
                    >
                </button>
            {/if}
        </div>
        <div slot="item" let:item let:index class="flex flex-row gap-2 items-center group">
            <slot name="item" {item} {index}>
                <svg class="shrink-0" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="group-hover:fill-poisongreen" fill="{checkSelected(item) ? "rgb(var(--tw-poisongreen))" : "rgb(var(--tw-sandlight))"}" d="M9.99902 0.953247C8.64012 0.953247 7.46122 1.60525 6.53022 2.57825C5.17602 2.54825 3.85822 2.90625 2.90522 3.85925C1.95232 4.81225 1.59352 6.13025 1.62402 7.48425C0.650724 8.41525 -0.000976562 9.59425 -0.000976562 10.9532C-0.000976562 12.3122 0.650724 13.4912 1.62402 14.4222C1.59352 15.7762 1.95232 17.0942 2.90522 18.0472C3.85832 19.0002 5.17602 19.3592 6.53022 19.3282C7.46122 20.3012 8.64012 20.9532 9.99902 20.9532C11.3579 20.9532 12.5368 20.3012 13.4678 19.3282C14.822 19.3582 16.1398 19.0002 17.0928 18.0472C18.0457 17.0942 18.4045 15.7762 18.374 14.4222C19.3473 13.4912 19.999 12.3122 19.999 10.9532C19.999 9.59425 19.3473 8.41525 18.374 7.48425C18.4045 6.13025 18.0457 4.81225 17.0928 3.85925C16.1397 2.90625 14.822 2.54725 13.4678 2.57825C12.5368 1.60525 11.3579 0.953247 9.99902 0.953247ZM12.999 7.95325C13.2549 7.95325 13.5225 8.03925 13.7178 8.23425C14.1083 8.62525 14.1083 9.28124 13.7178 9.67224L9.71782 13.6722C9.32722 14.0622 8.67083 14.0622 8.28022 13.6722L6.28022 11.6722C5.88972 11.2812 5.88972 10.6253 6.28022 10.2343C6.67082 9.84425 7.32722 9.84425 7.71782 10.2343L8.99902 11.5153L12.2802 8.23425C12.4755 8.03925 12.7431 7.95325 12.999 7.95325Z"/>
                </svg>
                <span>
                    {item[label]}
                </span>
            </slot>
        </div>
        <div slot="selection" let:selection let:index>
            <slot name="selection" {selection} {index}>
                <span>
                    {selection[label]}
                </span>
            </slot>
        </div>
        <div slot="empty">
            <div class="py-2">
                <p class="text-center text-earth/60">Keine Einträge gefunden</p>
            </div>
        </div>
        <div slot="chevron-icon">
            <svg
                width="15"
                height="9"
                viewBox="0 0 17 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="select-chevron"
            >
                <path
                    d="M2.18875 0.811251L8.5 7.10875L14.8113 0.811251L16.75 2.75L8.5 11L0.25 2.75L2.18875 0.811251Z"
                    fill="currentColor"
                />
            </svg>
        </div>
    </Select>

    <InputError bind:hasErrors {name} {formID} value={errorValue}/>
</div>
