import axios from "./lib/axios";
import { writable } from "svelte/store";
import { replaceQueryParams, getQueryParameterByName } from "./lib/utils";

class Api {
    constructor(
        baseUrl = "/api/v1",
        indexStore = writable(),
        detailStore = writable(),
        queryPrefix = null
    ) {
        this.baseUrl = baseUrl;
        this.indexStore = indexStore;
        this.detailStore = detailStore;
        this.queryPrefix = queryPrefix ? `${queryPrefix}_` : null;
    }

    async index(config = {}) {
        return await axios
            .get(`${this.baseUrl}`, config)
            .then(async (response) => {
                this.indexStore.set(response.data);
                return response;
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    async store(data = {}, config = {}) {
        return await axios
            .post(`${this.baseUrl}`, data, config)
            .then((response) => {
                this.detailStore.set(response.data);
                return response;
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    async show(data = {}, config = {}) {
        return await axios
            .get(`${this.baseUrl}/${data.id}`, config)
            .then((response) => {
                this.detailStore.set(response.data);
                return response;
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    async update(data = {}, config = {}) {
        return await axios
            .put(`${this.baseUrl}/${data.id}`, data, config)
            .then((response) => {
                this.detailStore.set(response.data);
                return response;
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    async destroy(data = {}, config = {}) {
        return await axios
            .delete(`${this.baseUrl}/${data.id}`, config)
            .then((response) => {
                this.detailStore.set(null);
                return response;
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    // TODO: config fehlt
    async paginate(data = {}, config = {}, page_size = 10, page = 1) {
        return await this.index({
            params: {
                page_size: getQueryParameterByName("page_size"),
                page: getQueryParameterByName("page"),
            },
        }).then(() => {
            replaceQueryParams({
                [`${this.queryPrefix}page`]: page,
                [`${this.queryPrefix}page_size`]: page_size,
            });
        });
    }

    async search(search = null) {
        return await this.index({
            params: { search: getQueryParameterByName("search") },
        })
        .then(() => {
            if (search) {
                replaceQueryParams({
                    [`${this.queryPrefix}search`]: search,
                });
            }
        });
    }

    async download(data = {}, config = {}) {
        return await axios
            .get(`${this.baseUrl}${data.id ? `/${data.id}` : null}`, config)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    async newTab(data = {}, config = {}) {
        return await axios
            .get(`${this.baseUrl}${data.id ? `/${data.id}` : null}`, config)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }
}

export default Api;
