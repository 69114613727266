<script>
    export let to = undefined;
    export let linkClass = undefined;
    export let disabled = undefined;

    import { Link } from "svelte-routing";
    import { createEventDispatcher, tick } from "svelte";

    const dispatch = createEventDispatcher();

    let isActive = false;

    const getProps = ({ location, href, isPartiallyCurrent, isCurrent }) => {
        isActive =
            href === "/"
                ? isCurrent
                : isCurrent || window.location.pathname == to.slice(0, -1);
    };

    const handleClick = async (event) => {
        dispatch("click");

        await tick();

        // scroll element into view
        if (to.includes("#")) {
            return document
                .getElementById(to.split("#").pop())
                .scrollIntoView();
        }
    };
</script>

{#if disabled}
    <div class="{linkClass} {isActive ? 'active' : 'inactive'} opacity-80">
        <slot />
    </div>
{:else}
    <Link
        {to}
        {getProps}
        class="{linkClass} {isActive ? 'active' : 'inactive'}"
        on:click={(event) => handleClick(event)}
    >
        <slot />
    </Link>
{/if}
