<script>
    import Input from "@/components/form/Input.svelte";

    import { user, api } from "@/services/user"
    import { errors } from "@/stores/error"
    import { failure } from "@/lib/toast"

    let formID = "user"

    let data = structuredClone($user.data);

    // TODO: Alle Toggles clearen wenn diese geschlosse werden
    // TODO: Wenn ein Toggle geöffnet wird, alle anderen schließen
    // TODO: Wenn Anpassungen gemacht werden toggle unterbinden und Popup öffnen
    
    // export const onClose = () => {
    //     data = structuredClone($user.data);
    //     errors.update((errors) => _.set(errors, formID, []));
    // }

    const submit = () => {
        api.updateProfileInformation(data, {formID: formID})
            .catch(error => {
                failure("Es gab einen Fehler beim Speichern der Daten.");
                error.response.data.message && failure(error.response.data.message);
            });
    }
</script>

<main>
    <Input
        on:enter={() => submit(data)}
        bind:value={data.name}
        required={true}
        name="name"
        placeholder="Name"
        type="text"
        {formID}
        containerClass="w-full my-3"
        inputClass="!bg-sandlight focus:!bg-white"
    />

    {#if data.email !== $user.data.email}
        <div class="px-2 py-1 rounded-md bg-orange text-white">
            <p>
                Wenn Sie Ihre E-Mail Adresse ändern, müssen Sie diese nach dem speichern bestätigen.
            </p>
        </div>
    {/if}
    
    <Input
        on:enter={() => submit(data)}
        bind:value={data.email}
        required={true}
        name="email"
        placeholder="E-Mail Adresse"
        type="text"
        {formID}
        containerClass="w-full my-3"
        inputClass="!bg-sandlight focus:!bg-white"
    />

    <div class="mt-4">
        <button class="button moss" on:click={() => submit()}>E-Mail speichern</button>
    </div>
</main>