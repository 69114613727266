<script>
    import NavLink from "./NavLink.svelte";
    import Facebook from "@/icons/Facebook.svelte";
    import Linkedin from "@/icons/Linkedin.svelte";
    import Youtube from "@/icons/Youtube.svelte";
    import Instagram from "@/icons/Instagram.svelte";
    import { link } from "svelte-routing";

</script>

<main id="footer">
    <div class="bg-moss py-8 relative z-30">
        <div class="container mx-auto">
            <div class="grid grid-cols-1 lg:grid-cols-4 gap-8 text-snow">
                <div class="col-span-1 lg:col-span-2">
                    <img src="/images/logo-sandlight.png" alt="logo" class="w-auto h-[80px] object-contain" />

                    <div class="mt-4">
                        <p>
                            Die erste interaktive Karriereplattform <br class="lg:hidden"/> für die grüne Branche. <br/>
                            Finde deinen Traumjob in GaLaBau, Pflanzenhandel, Kultivierung und Landwirtschaft.
                        </p>
                    </div>

                    <div class="flex flex-row gap-1 text-white/80 mt-4">
                        <a target="_blank" href="https://www.facebook.com/profile.php?id=61567918862446" class="cursor-pointer">
                            <Facebook mainClass="w-10 h-10 rounded-md hover:bg-limegreen border border-sandlight/50 p-2"/>
                        </a>
                        <a target="_blank" href="https://www.instagram.com/greencareers.de/" class="cursor-pointer">
                            <Instagram mainClass="w-10 h-10 rounded-md hover:bg-limegreen border border-sandlight/50 p-2"/>
                        </a>
                        <a target="_blank" href="https://www.linkedin.com/company/greencareers-gmbh/" class="cursor-pointer">
                            <Linkedin mainClass="w-10 h-10 rounded-md hover:bg-limegreen border border-sandlight/50 p-2"/>
                        </a>
                        <a target="_blank" href="https://www.youtube.com/@GreenCareers" class="cursor-pointer">
                            <Youtube mainClass="w-10 h-10 rounded-md hover:bg-limegreen border border-sandlight/50 p-2"/>
                        </a>
                    </div>
                </div>
                <div class="col-span-1 mt-5">
                    <strong>
                        Entdecken
                    </strong>
                    <ul class="mt-6">
                        <li>
                            <a use:link href="/arbeitgeber">
                                Jetzt inserieren
                            </a>
                        </li>
                        <li>
                            <a use:link href="/">
                                Jetzt bewerben
                            </a>
                        </li>
                        <li>
                            <a use:link href="/login">
                                Arbeitgeber-Login
                            </a>
                        </li>
                        <li>
                            <a use:link href="/faq">
                                FAQ
                            </a>
                        </li>
                        <li>
                            <a use:link href="/kontakt">
                                Kontakt
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="col-span-1 mt-5">
                    <strong>
                        Über GreenCareers
                    </strong>
                    <ul class="mt-6">
                        <li>
                            <a use:link href="/ueber-uns">
                                Über uns
                            </a>
                        </li>
                        <li>
                            <a use:link href="/datenschutz">
                                Datenschutz
                            </a>
                        </li>
                        <li>
                            <a use:link href="/impressum">
                                Impressum
                            </a>
                        </li>
                        <li>
                            <a use:link href="/agb">
                                AGB
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</main>