<script>
    document.title = "Green Careers - Über Uns";

    import { onMount, createEventDispatcher } from "svelte";

    import InformationBox from "@/components/InformationBox.svelte";
    import LogoSlider from "@/components/LogoSlider.svelte";
    import { link } from "svelte-routing";

    const dispatch = createEventDispatcher();

    onMount(() => {
        dispatch("loaded")
    })
</script>

<main>
    <div class="appear h-[300px] bg-gradient-to-br from-limegreen to-moss flex flex-col justify-end">
        <div class="container mx-auto lg:max-w-2xl text-sandlight py-8 text-center">
            <strong>
                ÜBER UNS
            </strong>
            <h1 class="mt-1">
                Gemeinsam stärken wir die Zukunft der grünen Branche.
            </h1>
        </div>
    </div>

    <div class="appear container mx-auto lg:max-w-4xl html-content">
        <section class="py-12 overflow-x-hidden appear container-fluid px-4">
            <div class="grid grid-cols-2 gap-2 max-w-4xl mx-auto">
                <div class="col-span-1 appear">
                    <img src="/images/about-1.jpg" alt="Über Uns" class="bg-moss object-cover border-2 shadow-lg border-white rounded-md w-full aspect-video"/>
                </div>
                <div class="col-span-1 appear">
                    <img src="/images/about-2.jpg" alt="Über Uns" class="bg-moss object-cover border-2 shadow-lg border-white rounded-md aspect-video lg:w-[calc(100%-3rem)] lg:mt-6 lg:ml-1 lg:mr-auto"/>
                </div>
                <div class="col-span-1 appear">
                    <img src="/images/about-3.jpg" alt="Über Uns" class="bg-moss object-cover border-2 shadow-lg border-white rounded-md aspect-video lg:w-[calc(100%-3rem)] lg:mb-6 lg:mr-1 lg:ml-auto"/>
                </div>
                <div class="col-span-1 appear">
                    <img src="/images/about-4.jpg" alt="Über Uns" class="bg-moss object-cover border-2 shadow-lg border-white rounded-md w-full aspect-video"/>
                </div>
            </div>
        </section>

        <section class="py-12 overflow-x-hidden appear">
            <h2 class="text-moss">
                Die Herausforderungen unserer Branche
            </h2>
            <h3 class="text-base leading-[auto] !text-limegreen mt-2">
                Betriebe in der grünen Branche kämpfen zunehmend mit großen Herausforderungen bei der Personalgewinnung.
            </h3>
            <div class="mt-1">
                <p>
                    Die <strong>Überalterung</strong> der bestehenden Mitarbeiterschaft und die geringe <strong>Zahl an Auszubildenden</strong> verschärfen den <strong>Fachkräftemangel</strong>.
                    Viele erfahrene Mitarbeiter scheiden altersbedingt aus, während es an Nachwuchs fehlt, der diese Lücken füllen kann. 
                    Gleichzeitig sehen sich Unternehmen mit der <strong>Abwanderung</strong> qualifizierter Fachkräfte zu Kommunen und Städten konfrontiert, die oft <strong>attraktivere Arbeitsbedingungen</strong> bieten.
                </p>
                <br/>
                <p>
                    Zudem fehlt es häufig an ausreichendem Know-how bei den vorhandenen Mitarbeitenden, was die <strong>Wettbewerbsfähigkeit</strong> der Betriebe zusätzlich beeinträchtigt. 
                    Diese Faktoren stellen erhebliche Hürden dar, die es zu bewältigen gilt, um die Zukunftsfähigkeit der Branche zu sichern.
                </p>
            </div>
        </section>

        <section class="appear py-12">
            <div class="container mx-auto max-w-lg">
                <div class="flex flex-row gap-4">
                    <InformationBox mainClass="rounded-xl shadow-xl p-4 w-1/2 bg-moss text-white" number={window.meta.settings.employee_count} suffix="+" duration={1500}>
                        <p>
                            Fachkräfte aus der grünen Branche
                        </p>
                    </InformationBox>
                    <InformationBox mainClass="rounded-xl shadow-xl p-4 w-1/2 bg-limegreen text-white" number={window.meta.settings.employer_count} suffix="+" duration={1500}>
                        <p>
                            Arbeitgeber aus der grünen Branche
                        </p>
                    </InformationBox>
                </div>
            </div>
        </section>

        <section class="py-12 overflow-x-hidden appear">
            <h2 class="text-moss">
                Unsere Lösung
            </h2>
            <div class="mt-1">
                <p>
                    Auf unserer Plattform wird Ihr Unternehmen gezielt dort sichtbar, wo engagierte Mitarbeiter der <strong>grünen Branche</strong> nach den besten Jobs suchen. 
                    Anders als bei allgemeinen Stellenportalen oder traditionellen Medien, die oft Streuverluste erzeugen, sind wir der <strong>relevanteste Kanal</strong> für Fachkräfte in der grünen Branche, die gezielt nach attraktiven Stellenangeboten suchen. 
                    Ihre Präsenz wird <strong>erheblich gesteigert</strong>, sodass Sie genau die qualifizierten Talente erreichen, die zu Ihnen passen, ohne in der Masse unterzugehen.
                </p>
                <br/>
                <p>
                    Unser Fokus liegt darauf, Ihr Unternehmen <strong>aus der Vergleichbarkeit hervorzuheben</strong>. 
                    Durch gezieltes Bewerben und eine <strong>hochwertige visuelle Darstellung</strong> des Unternehmens und des Jobprofils schaffen wir eine starke Präsenz, die die Aufmerksamkeit der besten Talente auf sich zieht.
                </p>
                <br/>
                <p>
                    Zudem setzen wir darauf, die <strong>Hürden im Bewerbungsprozess</strong> zu minimieren und den gesamten Prozess so einfach und schlank wie möglich zu gestalten. 
                    So ermöglichen wir es potenziellen Mitarbeitern, sich schnell und unkompliziert bei Ihnen zu bewerben – und Sie finden die passenden Kandidaten effizienter."
                </p>
            </div>
        </section>

        <section class="py-12 overflow-x-hidden appear">
            <img src="/images/about-5.jpg" alt="" class="object-cover border-2 shadow-lg border-white rounded-xl w-full aspect-video mb-12"/>

            <h2 class="text-moss block">
                Das Team
            </h2>
            <h3 class="text-base leading-[auto] !text-limegreen mt-2">
                Wir sind ein engagiertes Team aus 15 motivierten Menschen, das sich darauf spezialisiert hat, die grüne Branche in Zeiten des Fachkräftemangels zu unterstützen.
            </h3>
            <div class="mt-1">
                <p>
                    Mit unserer Plattform bringen wir Unternehmen und qualifizierte Fachkräfte zusammen und sorgen dafür, dass die besten Talente genau die richtigen Stellen finden. Unsere Vision ist es, den Fachkräftemangel in der grünen Branche kosteneffizient zu lösen und gleichzeitig dafür zu sorgen, dass Unternehmen die Sichtbarkeit und Reichweite erhalten, die sie benötigen, um langfristig erfolgreich zu sein. Wir verstehen die spezifischen Herausforderungen der Branche und setzen uns leidenschaftlich dafür ein, nachhaltige und effektive Lösungen zu bieten.
                </p>
            </div>
            <div class="flex justify-center mt-8">
                <a href="/arbeitgeber?scrollto=unsere-pakete" class="button moss max-w-sm text-center !no-underline">
                    Jetzt Stellen besetzen
                </a>
            </div>
        </section>

        <section class="py-12 overflow-x-hidden appear container-fluid relative">
            <div class="container mx-auto">
                <h2 class="text-moss text-center">
                    {window.meta.settings.employer_count}+ Arbeitgeber suchen Dich!
                </h2>

                <LogoSlider/>
            </div>
        </section>
    </div>
</main>