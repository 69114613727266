<script>
    document.title = "Green Careers - Kontakt";

    import { api } from "@/services/contact";
    import LoadingDots from "@/components/LoadingDots.svelte";
    import Input from "@/components/form/Input.svelte";
    import Textarea from "@/components/form/Textarea.svelte";
    import Toggle from "@/components/form/Toggle.svelte";
    import TelInput from "@/components/form/TelInput.svelte";
    import { Turnstile } from "svelte-turnstile";

    import { onDestroy, onMount, createEventDispatcher } from "svelte";
    import { errors } from "@/stores/error";
    import _ from "lodash";

    let formID = "contact";

    let loading = false;

    let reset = undefined;

    let turnstileKey = import.meta.env.VITE_TURNSTILE_SITE_KEY;

    const dispatch = createEventDispatcher();

    let data = {
        type: 'employee',
        name: null,
        company: null,
        email: null,
        phone: null,
        subject: null,
        message: null,
        token: null,
    }

    onMount(() => {
        dispatch("loaded")
    })

    onDestroy(() => {
        errors.update((errors) => _.set(errors, formID, []));
    });

    const submit = async () => {
        loading = true;

        await api.submit(data, { formID: formID })
            .then(() => {
                data = {
                    type: 'employee',
                    name: null,
                    company: null,
                    email: null,
                    phone: null,
                    subject: null,
                    message: null,
                }       
            })
            .finally(() => {
                reset?.();
                loading = false;
            })
    }
</script>

<main>
    <section class="appear min-h-[450px] bg-cover bg-[center_10%] relative" style="background-image: url('/images/contact.jpg');">
        <div class="absolute left-0 right-0 bottom-0 top-1/3 bg-gradient-to-t from-black/90"></div>
        <div class="absolute left-0 right-0 bottom-0 py-12">
            <div class="container mx-auto max-w-4xl text-white">
                <h1>
                    Kontakt
                </h1>
            </div>
        </div>
    </section>

    <div class="appear container mx-auto lg:max-w-4xl py-8">
        <div class="bg-white rounded-xl p-8 mx-auto">
            <h2 class="text-earth">
                Schreiben Sie uns:
            </h2>
            <p class="text-earth mt-1">
                <span class="text-limegreen">*</span> Pflichtfelder
            </p>

            <div class="mt-8">
                <Toggle on:change={(e) => data.type = e.detail} value={data.type} labels={[
                    {label: "Arbeitnehmer", value: "employee"},
                    {label: "Arbeitgeber", value: "employer"},
                ]}/>

                <Input
                    on:enter={submit}
                    bind:value={data.name}
                    required
                    name="name"
                    placeholder="Name"
                    type="text"
                    {formID}
                    containerClass="w-full my-3"
                    inputClass="!bg-sandlight focus:!bg-white"
                />

                {#if data.type === "employer"}
                    <Input
                        on:enter={submit}
                        bind:value={data.company}
                        required
                        name="company"
                        placeholder="Firma"
                        type="text"
                        {formID}
                        containerClass="w-full my-3"
                        inputClass="!bg-sandlight focus:!bg-white"
                    />
                {/if}

                <Input
                    on:enter={submit}
                    bind:value={data.email}
                    required
                    name="email"
                    placeholder="E-Mail Adresse"
                    type="text"
                    {formID}
                    containerClass="w-full my-3"
                    inputClass="!bg-sandlight focus:!bg-white"
                />

                <TelInput
                    bind:value={data.phone}
                    required
                    name="phone"
                    {formID}
                    placeholder="Telefonnummer"
                    inputClass="!bg-sandlight focus:!bg-white"
                    selectableCountries={[
                        {id: "AT", iso2: "AT", dialCode: "43", name: "Österreich", label: "Österreich"},
                        {id: "CH", iso2: "CH", dialCode: "41", name: "Schweiz", label: "Schweiz"},
                        {id: "DE", iso2: "DE", dialCode: "49", name: "Deutschland", label: "Deutschland"},
                        {id: "FR", iso2: "FR", dialCode: "33", name: "Frankreich", label: "Frankreich"},
                        {id: "IT", iso2: "IT", dialCode: "39", name: "Italien", label: "Italien"},
                        {id: "NL", iso2: "NL", dialCode: "31", name: "Niederlande", label: "Niederlande"},
                    ]}
                />

                <Input
                    on:enter={submit}
                    bind:value={data.subject}
                    required
                    name="subject"
                    placeholder="Betreff"
                    type="text"
                    {formID}
                    containerClass="w-full my-3"
                    inputClass="!bg-sandlight focus:!bg-white"
                />

                <Textarea 
                    bind:value={data.message}
                    required
                    name="message"
                    rows="8"
                    placeholder="Ihr Anliegen"
                    {formID}
                    inputClass="!bg-sandlight focus:!bg-white"/>

                <Turnstile
                    siteKey={turnstileKey}
                    theme="dark"
                    on:turnstile-callback={(event) => data.token = event.detail.token}
                    bind:reset
                />
                
                <div class="flex justify-end">
                    <button class="button moss mt-1 lg:w-auto" on:click={submit}>
                        {#if loading}
                            <LoadingDots/>
                        {:else}
                            <span>Absenden</span>
                        {/if}
                    </button>
                </div>
            </div>
        </div>
    </div>
</main>