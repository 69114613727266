<script>
    export let mode;

    let formID = "vacancy";

    import CompanyLogo from '@/components/listing/CompanyLogo.svelte';
    import Location from '@/icons/Location.svelte';
    import Popup from '@/components/Popup.svelte';
    import Pen from '@/icons/Pen.svelte';
    import Upload from '@/components/form/Upload.svelte';
    import Information from '@/icons/Information.svelte';
    import Company from '@/icons/Company.svelte';

    import { employer as tempEmployer } from "@/stores/temporary";
    import { getAvailableFileSize } from '@/lib/utils';
    import { success } from '@/lib/toast';

    let uploadPopupOpen = false;
</script>

<div class="-mt-[2rem] text-moss font-medium flex flex-row gap-4 items-end z-10 container mx-auto max-w-3xl">
    <div class="relative">
        {#if mode === "edit"}
            <button on:click={() => uploadPopupOpen = true} class="absolute top-1 right-1 w-8 h-8 bg-limegreen text-white flex items-center justify-center rounded-full">
                <Pen mainClass="w-5 h-5"/>
            </button>
        {/if}
        {#if $tempEmployer?.data?.logos?.data?.length > 0}
            <CompanyLogo mainClass="h-[100px] w-[100px] !rounded-2xl border-2 border-white shadow-xl" image="{getAvailableFileSize($tempEmployer.data.logos.data[0], "sm")}"/>
        {:else if $tempEmployer?.data?.logos[0]}
            <CompanyLogo mainClass="h-[100px] w-[100px] !rounded-2xl border-2 border-white shadow-xl" image="{`/uploads/${$tempEmployer?.data?.logos[0].path}`}"/>
        {:else}
            <CompanyLogo mainClass="h-[100px] w-[100px] !rounded-2xl border-2 border-white shadow-xl" image="/images/logo-placeholder.png"/>
        {/if}
    </div>
    <div class="flex flex-col gap-1">
        <div class="inline-flex items-top gap-1">
            <Company mainClass="text-poisongreen w-4 h-4 shrink-0"/>
            <p class="text-sm line-clamp-2">{$tempEmployer.data.company}</p>
        </div>
        <div class="inline-flex items-top gap-1">
            <Location mainClass="text-poisongreen w-4 h-4 shrink-0"/>
            {#if $tempEmployer.data?.zipcode && $tempEmployer.data?.location}
                <p class="text-sm line-clamp-1 overflow-ellipsis">{$tempEmployer.data.zipcode} {$tempEmployer.data.location}</p>
            {:else}
                <p class="text-sm line-clamp-1 overflow-ellipsis">Kein Standort hinterlegt</p>
            {/if}
        </div>
    </div>

    <Popup open={uploadPopupOpen} on:close={() => uploadPopupOpen = null}>
        <strong class="text-2xl text-snow">
            Logo hochladen
        </strong>

        <div class="bg-sandlight rounded-md mt-4">
            <Upload 
                bind:files={$tempEmployer.data.logos}
                type="vertical"
                title="Laden Sie hier das Logo Ihres Unternehmens hoch."
                message="Das ideale Format beträgt 200x200 Pixel oder ein Seitenverhältnis von 1/1."
                acceptedFileTypes={["image/jpg","image/jpeg","image/png","image/webp"]}
                formID={formID}
                name="logos"
                maxFileSize="2MB"
                on:uploaded={() => {
                    uploadPopupOpen = false
                    success("Logo erfolgreich hochgeladen")
                }}
            />
        </div>

        <div class="flex flex-row gap-2 mt-4 text-poisongreen">
            <Information mainClass="w-5 shrink-0"/>
            <p>
                Beachten Sie, dass die Logos für alle Anzeigen verwendet werden.
            </p>
        </div>
    </Popup>
</div>