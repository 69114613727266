<script>
    import Pen from "@/icons/Pen.svelte";
    import Employer from "./Employer.svelte";
    import Expand from "@/components/Expand.svelte";
    import ProfileInformation from "./ProfileInformation.svelte";
    import ProfilePassword from "./ProfilePassword.svelte";
    import Transactions from "./Transactions.svelte";
    import Vacancies from "./Vacancies.svelte";

    import { getQueryParameterByName } from "@/lib/utils";
    import { onMount, tick, onDestroy } from "svelte";
    import { api as userApi, user } from "@/services/user";
    import { employer } from "@/services/employer";
    import { navigate } from "svelte-routing";
    import { link } from "svelte-routing";
    import { service as employerService } from "@/services/employer";

    let activeTab = undefined;
    let sections = [];
    let loading = true;
    let currentLocation = window.location.pathname;

    const showTab = (name, force = false) => {
        if (activeTab !== undefined && activeTab !== name) {
            sections[activeTab].close();
        }
        
        if(force && sections[name])  {
            sections[name].show();
        }

        activeTab = name;
    }

    const handleToggle = (e) => {
        showTab(e.detail.index);
    }

    onMount(async () => {
        if ($user === undefined) {
            navigate("/login")
        }
        else {
            await employerService.show({
                id: $user.data.employers.data[0].id
            });

            loading = false;

            await tick();

            if (getQueryParameterByName("tab")) {
                showTab(getQueryParameterByName("tab"), true);
            }

            if (getQueryParameterByName("checkoutSuccesfull")) {
                // TODO: Show success Banner
            }
        }
    })
</script>

{#if loading === false}
    <main>
        <div class="h-[250px] bg-gradient-to-br from-limegreen to-moss flex flex-col justify-end">
            <div class="container mx-auto text-sandlight py-8">
                <h1>
                    In wenigen Schritten zur Stellenanzeige
                </h1>
            </div>
        </div>

        <div class="container mx-auto py-8">
            <p>
                Erstellen Sie in wenigen Schritten Ihr Arbeitgeberprofil und legen Sie Ihre gewünschten Stellenanzeigen an. 
            </p>
        </div>

        <div class="container mx-auto py-8">
            <!-- Arbeitgeber Section Start-->
            <Expand open={activeTab === "arbeitgeber" ? true : false } bind:this={sections["arbeitgeber"]} on:toggle={handleToggle} index={"arbeitgeber"} showHeader="true" mainClass="max-w-3xl mx-auto bg-white rounded-2xl p-4 pb-5 pt-6">
                <div slot="title" class="flex flex-col justify-between gap-4 w-full">
                    <div class="font-bold text-xl border-2 border-poisongreen text-poisongreen w-10 h-10 rounded-full flex items-center justify-center text-center">
                        <span>1</span>
                    </div>

                    <div class="flex flex-row justify-between gap-4 w-full border-t pt-2 border-earth/20">
                        <p class="text-xl text-moss font-bold">
                            Arbeitgeber
                        </p>
                        <button class="text-limegreen underline underline-offset-2 flex items-center gap-2">
                            <Pen mainClass="w-5 h-5"/>
                            <small>Bearbeiten</small>
                        </button>
                    </div>

                    {#if $employer.data.all_data_available === false && activeTab !== "arbeitgeber"}
                        <button on:click|stopPropagation={() => showTab("arbeitgeber", true)} class="button gradient">
                            Arbeitgeberprofil vervollständigen
                        </button>
                    {/if}
                </div>
                <div slot="content" class="bg-white rounded-xl mx-auto">
                    <!-- Main Content -->
                    <div>
                        <p class="text-earth font-regular mb-1">
                            Erstellen Sie zunächst Ihr Arbeitgeberprofil. Arbeitgeberdaten können auch für künftige Inserate wiederverwendet werden.
                        </p>
                        <p class="text-poisongreen">
                            * Pflichtfelder
                        </p>

                        <Employer/>
                    </div>
                    <!-- Main Content End -->
                </div>
            </Expand>
            <!-- Arbeitgeber Section End-->

            <!-- Inserieren Section Start-->
            <Expand clickable={$employer.data.all_data_available} open={activeTab === "inserieren" ? true : false } bind:this={sections["inserieren"]} on:toggle={handleToggle} index={"inserieren"} showHeader="true" mainClass="max-w-3xl mx-auto bg-white rounded-2xl p-4 pb-5 pt-6 {$employer.data.all_data_available ? '' : 'opacity-50'}">
                <div slot="title" class="flex flex-col justify-between gap-4 w-full">
                    <div class="font-bold text-xl border-2 border-poisongreen text-poisongreen w-10 h-10 rounded-full flex items-center justify-center text-center">
                        <span>2</span>
                    </div>

                    <div class="flex flex-row justify-between gap-4 w-full border-t pt-2 border-earth/20">
                        <p class="text-xl text-moss font-bold">
                            {#if $employer.data.job_advertisements_count > 0}
                                <span class="hidden lg:inline-block">Ihre</span> Stellenanzeigen
                            {:else}
                                Jetzt Inserieren
                            {/if}
                        </p>
                        <button class="text-limegreen underline underline-offset-2 flex items-center gap-2">
                            <Pen mainClass="w-5 h-5"/>
                            <small>Bearbeiten</small>
                        </button>
                    </div>

                    {#if $employer.data.all_data_available === true}
                        <a use:link href="/dashboard/job" class="button gradient !shadow-none">
                            Inserat erstellen
                        </a>
                    {/if}
                </div>
                <div slot="content" class="bg-white rounded-xl mx-auto">
                    <!-- Main Content -->
                    <div class="!text-earth mt-4">
                        <strong class="text-xl">
                            Ihre verfügbaren Kontingente:
                        </strong>
                        <hr class="border-sandlight my-3"/>
                        <div class="flex flex-col gap-2">
                            <div class="flex flex-row items-center gap-3">
                                <strong class="w-8 h-8 bg-poisongreen text-white rounded-md flex items-center justify-center text-center text-xl">
                                    {$employer?.data?.free_smart_contingents_count ?? 0}
                                </strong>
                                <p>
                                    Smart-Stellenanzeigen
                                </p>
                            </div>
                            <div class="flex flex-row items-center gap-3">
                                <strong class="w-8 h-8 bg-poisongreen text-white rounded-md flex items-center justify-center text-center text-xl">
                                    {$employer?.data?.free_premium_contingents_count ?? 0}
                                </strong>
                                <p>
                                    Premium-Stellenanzeigen
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="mt-4">
                        <strong>
                            Aktive Stellen:
                        </strong>

                        <Vacancies emptyText="Sie haben aktuell noch keine aktiven Stellenanzeigen" params={{
                                params: {
                                    order_by: ['created_at.desc'],
                                    per_page: 5,
                                    is_published: true,
                                }
                            }}
                        />
                    </div>

                    <div class="mt-4">
                        <strong>
                            Entwürfe:
                        </strong>

                        <Vacancies emptyText="Sie haben aktuell noch keine Entwürfe" params={{
                                params: {
                                    order_by: ['created_at.desc'],
                                    per_page: 5,
                                    was_published: false,
                                }
                            }}
                        />
                    </div>

                    <div class="mt-4">
                        <strong>
                            Vergangene Stellen:
                        </strong>

                        <Vacancies emptyText="Sie haben aktuell noch keine vergangenen Stellen" params={{
                                params: {
                                    order_by: ['created_at.desc'],
                                    per_page: 5,
                                    was_published: true,
                                }
                            }}
                        />
                    </div>
                    <!-- Main Content End -->
                </div>
            </Expand>
            <!-- Inserieren Section End-->

            <!-- Rechnungen Section Start-->
            <Expand clickable={$employer.data.all_data_available} open={activeTab === "rechnungen" ? true : false } bind:this={sections["rechnungen"]} on:toggle={handleToggle} index={"rechnungen"} showHeader="true" mainClass="max-w-3xl mx-auto bg-white rounded-2xl p-4 pb-5 pt-6 {$employer.data.all_data_available ? '' : 'opacity-50'}">
                <div slot="title" class="flex flex-col justify-between gap-4 w-full">
                    <div class="font-bold text-xl border-2 border-poisongreen text-poisongreen w-10 h-10 rounded-full flex items-center justify-center text-center">
                        <span>3</span>
                    </div>

                    <div class="flex flex-row justify-between gap-4 w-full border-t pt-2 border-earth/20">
                        <p class="text-xl text-moss font-bold">
                            Rechnungen
                        </p>
                        <button class="text-limegreen underline underline-offset-2 flex items-center gap-2">
                            <Pen mainClass="w-5 h-5"/>
                            <small>Herunterladen</small>
                        </button>
                    </div>
                </div>
                <div slot="content" class="bg-white rounded-xl mx-auto">

                    <!-- Main Content -->
                    <div>
                        <p class="text-earth font-regular mb-1">
                            Hier können Sie Ihre Rechnungen einsehen und herunterladen.
                        </p>

                        <Transactions/>
                    </div>
                    <!-- Main Content End -->
                </div>
            </Expand>
            <!-- Rechnungen Section End-->

            <!-- Profil Section Start-->
            <Expand clickable={$employer.data.all_data_available} open={activeTab === "profil" ? true : false } bind:this={sections["profil"]} on:toggle={handleToggle} index={"profil"} showHeader="true" mainClass="max-w-3xl mx-auto bg-white rounded-2xl p-4 pb-5 pt-6 {$employer.data.all_data_available ? '' : 'opacity-50'}">
                <div slot="title" class="flex flex-col justify-between gap-4 w-full">
                    <div class="font-bold text-xl border-2 border-poisongreen text-poisongreen w-10 h-10 rounded-full flex items-center justify-center text-center">
                        <span>4</span>
                    </div>

                    <div class="flex flex-row justify-between gap-4 w-full border-t pt-2 border-earth/20">
                        <p class="text-xl text-moss font-bold">
                            Logindaten
                        </p>
                        <button class="text-limegreen underline underline-offset-2 flex items-center gap-2">
                            <Pen mainClass="w-5 h-5"/>
                            <small>Bearbeiten</small>
                        </button>
                    </div>
                </div>
                <div slot="content" class="bg-white rounded-xl mx-auto">

                    <!-- Main Content -->
                    <div>
                        <p class="text-earth font-regular mb-1">
                            Hier können Sie Ihre E-Mail Adresse anpassen.
                        </p>
                        <p class="text-poisongreen">
                            * Pflichtfelder
                        </p>
                        <!-- Content goes here -->
                    </div>

                    <ProfileInformation/>

                    <hr class="my-4 border-earth/20"/>

                    <div>
                        <p class="text-earth font-regular mb-1">
                            Hier können Sie Ihr Passwort anpassen.
                        </p>
                        <p class="text-poisongreen">
                            * Pflichtfelder
                        </p>
                        <!-- Content goes here -->
                    </div>

                    <ProfilePassword/>
                    <!-- Main Content End -->
                </div>
            </Expand>
            <!-- Profil Section End-->

            <div class="mt-4 mb-2 transition-all ease-in-out text-earth max-w-3xl mx-auto bg-white/50 rounded-2xl p-4 py-3 flex flex-row justify-between items-center shadow-md">
                <p class="text-moss">
                    Weitere Aktionen:
                </p>
                <button class="button sm red" on:click={userApi.logout}>
                    Logout
                </button>
            </div>
        </div>
    </main>
{/if}