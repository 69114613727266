<script>
    export let allowMultiple = false;
    export let url = "/api/files";
    export let maxFileSize = "1MB";
    export let type = "vertical";
    export let title = "No title given";
    export let message = "No message given";
    export let imagePreview = true;
    export let acceptedFileTypes = ["image/jpg","image/jpeg","image/png","image/webp","video/mp4"];
    export let files;
    export let containerClass;
    export let name;
    export let formID;

    import { onMount, onDestroy, createEventDispatcher } from "svelte";
    import { getRandomInt } from "@/lib/utils";

    import axios from "axios";
    import * as FilePond from "filepond";

    import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';    
    if(acceptedFileTypes) FilePond.registerPlugin(FilePondPluginFileValidateType);

    import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
    if (imagePreview) FilePond.registerPlugin(FilePondPluginImagePreview);

    import { errors } from "@/stores/error";

    let inputError = false;

    errors.subscribe((data) => {
        inputError = _.get(data, `${formID}.${name?.replaceAll(".", "-")}`);
    });

    let pond = null;
    let pondElement = null;
    let csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    const dispatch = createEventDispatcher();

    let messageTypes = {
        vertical: `
            <div class="filepond--drop-label p-2 h-full gap-8 group">
                <div class="w-1/4">
                    <div class="transition-all ease-in-out w-full aspect-square border-4 border-limegreen rounded-xl flex items-center justify-center relative group-hover:shadow-xl">
                        <div class="w-8 h-8 bg-limegreen rounded-full flex items-center justify-center absolute -top-3 -right-3 shadow-limegreen shadow-md">
                            <svg class="text-white w-4 h-4" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.92407 1.13965C9.70718 1.13965 9.57978 1.24988 9.49409 1.33622L8.48918 2.33547L7.99102 1.8974C7.71638 1.62265 7.35431 1.48558 6.99433 1.48558C6.63436 1.48558 6.25484 1.6448 5.98015 1.91955L3.08816 4.8088C2.86066 5.0363 2.8704 5.40966 3.09796 5.63716C3.32546 5.86524 3.70562 5.85531 3.93317 5.62723L6.81647 2.73972C6.91068 2.64522 7.05762 2.60324 7.17202 2.72224L7.65682 3.16205L4.23225 6.58622C3.65358 7.16547 3.12147 7.94597 2.61181 8.86472C2.22173 9.56764 1.86082 10.3207 1.5558 11.0703C1.44917 11.3322 1.16602 12.0906 1.16602 12.23C1.16602 12.3688 1.21181 12.4989 1.30094 12.6016C1.30975 12.6115 1.48172 12.8011 1.75017 12.8063L9.89467 12.8017C10.2164 12.8017 10.4773 12.5409 10.4773 12.2183C10.4773 11.8963 10.2164 11.635 9.89467 11.635L4.61754 11.6362C4.79668 11.5452 4.97868 11.4489 5.15252 11.3521C6.06987 10.8422 6.81945 10.3155 7.39724 9.7368L11.1874 5.96614L12.644 4.48972C12.7267 4.40572 12.8317 4.31765 12.832 4.0534C12.8334 2.90015 12.3627 2.19491 12.0757 1.90032C11.7886 1.60632 11.0894 1.14082 9.92407 1.13965ZM10.1379 2.33024C10.3821 2.32266 10.9837 2.4638 11.2445 2.71113C11.5053 2.95905 11.6554 3.60247 11.6471 3.84572L10.9161 4.57606L9.34972 3.12997L10.1379 2.33024Z" fill="currentColor"/>
                            </svg>
                        </div>
                        <svg class="w-12 h-12" viewBox="0 0 36 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#path-${getRandomInt()})">
                                <path d="M24 27.3333L18 20.5L12 27.3333" stroke="#468845" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M18 20.5V35.875" stroke="#468845" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M30.5849 31.416C32.0479 30.5076 33.2037 29.0702 33.8697 27.3307C34.5358 25.5912 34.6743 23.6486 34.2632 21.8095C33.8522 19.9704 32.9152 18.3395 31.5999 17.1743C30.2847 16.0092 28.6662 15.376 26.9999 15.3747H25.1099C24.6559 13.3747 23.8097 11.5179 22.6349 9.9439C21.46 8.36995 19.9872 7.11979 18.3271 6.28741C16.667 5.45504 14.8628 5.06211 13.0501 5.13817C11.2375 5.21423 9.46354 5.7573 7.86171 6.72655C6.25987 7.6958 4.87181 9.06601 3.80187 10.7342C2.73194 12.4023 2.00798 14.325 1.68441 16.3577C1.36084 18.3903 1.4461 20.4801 1.93376 22.4699C2.42142 24.4596 3.2988 26.2976 4.49994 27.8455" stroke="#468845" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                            <defs>
                                <clipPath id="path-${getRandomInt()}">
                                    <rect width="36" height="41" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                </div>
                <div class="w-3/4 text-left text-limegreen">
                    <strong class="text-xl">
                        ${title}
                    </strong>
                    <p>
                        ${message}
                    </p>
                    <strong>
                        max ${maxFileSize}
                    </strong>
                </div>
            </div>
        `,
        horizontal: `
            <div class="filepond--drop-label flex-col !items-center p-2 h-full gap-2 group">
                <svg class="w-20 h-20" viewBox="0 0 36 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#path-${getRandomInt()})">
                        <path d="M24 27.3333L18 20.5L12 27.3333" stroke="#468845" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M18 20.5V35.875" stroke="#468845" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M30.5849 31.416C32.0479 30.5076 33.2037 29.0702 33.8697 27.3307C34.5358 25.5912 34.6743 23.6486 34.2632 21.8095C33.8522 19.9704 32.9152 18.3395 31.5999 17.1743C30.2847 16.0092 28.6662 15.376 26.9999 15.3747H25.1099C24.6559 13.3747 23.8097 11.5179 22.6349 9.9439C21.46 8.36995 19.9872 7.11979 18.3271 6.28741C16.667 5.45504 14.8628 5.06211 13.0501 5.13817C11.2375 5.21423 9.46354 5.7573 7.86171 6.72655C6.25987 7.6958 4.87181 9.06601 3.80187 10.7342C2.73194 12.4023 2.00798 14.325 1.68441 16.3577C1.36084 18.3903 1.4461 20.4801 1.93376 22.4699C2.42142 24.4596 3.2988 26.2976 4.49994 27.8455" stroke="#468845" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                        <clipPath id="path-${getRandomInt()}">
                            <rect width="36" height="41" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>

                <div class="text-center text-limegreen">
                    <strong class="text-xl">
                        ${title}
                    </strong>
                    <p>
                        ${message}
                    </p>
                    <strong>
                        max ${maxFileSize}
                    </strong>
                </div>
            </div>
        `,
    }

    export const getFiles = async () => {
        return await pond.getFiles();
    }

    onMount(() => {
        pond = FilePond.create(pondElement, {
            allowMultiple: allowMultiple,
            server: {
                url: url,
                headers: {
                    'X-CSRF-TOKEN': csrfToken,
                },
                remove: (source, load, error) => {
                    load();
                },
                revert: (uniqueFileId, load, error) => {
                    axios.delete(`/api/files/${JSON.parse(uniqueFileId).path}`)
                        .then(() => {
                            load();
                        })
                        .catch((error) => {
                            error('Fehler beim löschen der Datei');
                            error.response.data.message && failure(error.response.data.message);
                        });
                },
            },
            name: 'file',
            acceptedFileTypes: acceptedFileTypes,
            fileValidateTypeDetectType: (source, type) => {
                return new Promise((resolve, reject) => {
                    resolve(type);
                })
            },
            labelIdle: messageTypes[type],
            imagePreview: imagePreview,
            onprocessfile: () => {
                files = pond.getFiles().map(file => JSON.parse(file.serverId));
                files = files.filter((el) => el !== null)
                dispatch("uploaded", files);
            },
            onremovefile: () => {
                files = pond.getFiles().map(file => JSON.parse(file.serverId));
                files = files.filter((el) => el !== null)
                dispatch("removed", files);
            },
        });
    });

    onDestroy(() => {
        pond.destroy();
    })
</script>

<main class="my-2 !border-limegreen border-2 border-dashed rounded-lg {inputError ? "border-red" : "border-poisongreen/10"} z-0 relative {containerClass}">
    <input type="file" credits="" bind:this={pondElement} />
</main>

{#if inputError}
    <div class="my-1 text-red text-xs text-left">
        {inputError}
    </div>
{/if}

<style global>
    @import "filepond/dist/filepond.css";
    @import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
</style>