<script>
    import { openSearch } from "@/stores/globalsearch"
    import Search from "@/icons/Search.svelte";
    import Filter from "@/icons/Filter.svelte";
</script>

<button on:click={() => openSearch()} class="rounded-full bg-snow px-3 py-2 w-full flex flex-row items-center justify-between text-gray border-2 border-poisongreen">
    <div class="flex flex-row gap-4 items-center">
        <span>Welchen Job suchst du?</span>
    </div>
    <span class="relative flex h-10 w-10">
        <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-limegreen opacity-50"></span>
        <span class="relative inline-flex rounded-full h-10 w-10 bg-limegreen items-center justify-center">
            <Filter mainClass="text-white"/>
        </span>
    </span>
</button>