<script>
    export let popover = undefined;
    export let autocomplete = "new-password";
    export let disabled = false;
    export let name = undefined;
    export let placeholder = undefined;
    export let required = false;
    export let label = undefined;
    export let type = "text";
    export let value = undefined;

    export let inputClass = undefined;
    export let containerClass = undefined;

    export let formID = undefined;

    import Label from "@/components/form/Label.svelte";
    import InputError from "@/components/form/InputError.svelte";
    import Delete from "@/icons/Delete.svelte";
    import { clickOutside } from "@/lib/utils";

    import axios from "@/lib/axios";
    import { createEventDispatcher } from "svelte";
    import { debounce } from "lodash";

    let hasErrors;
    let loading = false;
    let searchOpen = false;
    let results = [];

    const dispatch = createEventDispatcher();

    const geocodeAddress = async (value) => {
        loading = true
        
        if(value) {
            await axios.post('/api/geocoding', {address: value})
                .then((response) => {
                    results = response.data
                })
        }
        else {
            results = []
        }

        loading = false;
        searchOpen = true;
    }

    const geocodeAddressDebounced = debounce((value) => geocodeAddress(value), 300);

    const setLocation = (result) => {
        searchOpen = false
        dispatch("location", result)
    }

    const clearLocation = () => {
        let result = {
            formatted_address: "",
            street: "",
            street_number: "",
            location: "",
            zipcode: "",
            country: "",
            latitude: "",
            longitude: ""
        }

        setLocation(result);
    }

    const onKeyPress = (event) => {
        // only on enter
        if (event.charCode === 13) {
            event.preventDefault();
            if(results.length > 0) {
                setLocation(results[0]);
            }
        }
    };
</script>

<div class="{containerClass} relative">
    {#if label}
        <Label {label} {required} {popover} />
    {/if}

    {#if required}
        <span class="text-limegreen required absolute top-3 left-2.5 z-10">*</span>
    {/if}

    <div class="relative">
        <input
            {autocomplete}
            {disabled}
            {name}
            {placeholder}
            {required}
            {type}
            {value}
            on:input={(event) => geocodeAddressDebounced(event.target.value)}
            on:keypress={(event) => onKeyPress(event)}
            class="input w-full border {inputClass} {hasErrors
                ? 'border-red placeholder:text-red text-red'
                : ''}"
        />

        <button on:click={() => clearLocation()} class="absolute right-2 top-1/2 -translate-y-1/2 text-sm bg-sandlight rounded-md border border-moss/30 p-1 shadow-sm !text-moss">
            <Delete mainClass="w-4 h-4"/>
        </button>
    </div>

    {#if loading === false && results.length > 0 && searchOpen === true}
        <div use:clickOutside on:clickOutside={() => searchOpen = false} class="absolute w-full max-h-[400px] border border-poisongreen bg-white !text-moss rounded-md shadow-md overflow-y-auto z-30 mt-2">
            {#if results.length === 0}
                <div class="p-4">
                    Keine Ergebnisse
                </div>
            {:else}
                <div>
                    {#each results as result}
                        <button on:click={() => setLocation(result)} class="line-clamp-1 text-ellipsis overflow-hidden text-left hover:bg-moss hover:text-white w-full">
                            <p class="py-2 px-4">
                                {result.formatted_address}
                            </p>
                        </button>
                    {/each}
                </div>
            {/if}
        </div>
    {/if}

    <InputError bind:hasErrors {name} {formID} {value}/>
</div>