<script>
    document.title = "Green Careers - Registrieren";

    import Input from "@/components/form/Input.svelte";
    import NavLink from "@/components/NavLink.svelte";

    import { fly } from "svelte/transition";
    import {api, user} from "@/services/user"
    import { errors } from "@/stores/error";
    import { onMount, onDestroy } from "svelte";
    import { navigate } from "svelte-routing";
    import { intended, clearintended } from "@/stores/intended";
    import { getQueryParameterByName } from "@/lib/utils";

    let data = {
        email: null,
        password: null,
        password_confirmation: null,
        name: null,
        company: null,
    };

    let formID = "register";
    let loading = false;

    onDestroy(() => {
        errors.update((errors) => _.set(errors, formID, []));
    });

    onMount(() => {
        scrollTo(0, 0);

        if ($user === undefined) {
            intended.set(
                getQueryParameterByName("intended")
                    ? getQueryParameterByName("intended")
                    : null,
            );
        } else {
            navigate("/dashboard");
        }
    });

    const register = async () => {
        if (loading === false) {
            loading = true;

            await api
                .register(data, { formID: formID })
                .then(async (response) => {
                    if (response.data.intended) {
                        window.location = response.data.intended;
                        clearintended();
                    } else if ($intended) {
                        window.location.href = $intended;
                        clearintended();
                    } else {
                        navigate("/email/verify");
                    }
                })
                .finally(() => {
                    loading = false;
                });
        }
    };
</script>

<main in:fly out:fly>
    <div
        class="bg-earth bg-cover" style="background-image: url('/images/home-1.jpg');"
    >
        <div class="min-h-screen py-24 w-full flex items-center justify-center backdrop-blur-md">
            <div class="w-screen px-6 mt-4 space-y-8 md:mt-0 md:px-2 max-w-lg">
                <div
                    class="p-8 space-y-4 bg-gray-700 text-white backdrop-blur-xl border border-gray-900 shadow-2xl rounded-md relative bg-sandlight"
                >
                    <img class="w-1/3 mx-auto" src="/images/signage.png" alt="Green Careers"/>
                    <h2 class="text-center text-earth">Herzlich<br/>Willkommen</h2>
                    <p class="text-center text-limegreen font-bold">Registrieren</p>

                    <div class="mt-6">
                        <form
                            novalidate
                            class="w-full"
                            on:submit|preventDefault={() => register()}
                            disabled={loading}
                        >

                            <Input
                                on:enter={() => register(data)}
                                bind:value={data.email}
                                name="email"
                                placeholder="E-Mail Adresse"
                                type="text"
                                {formID}
                                containerClass="w-full my-4"
                            />

                            <Input
                                on:enter={() => register(data)}
                                bind:value={data.password}
                                name="password"
                                placeholder="Passwort"
                                type="password"
                                autocomplete="new-password"
                                {formID}
                                containerClass="w-full my-4"
                            />


                            <Input
                                on:enter={() => register(data)}
                                bind:value={data.password_confirmation}
                                name="password_confirmation"
                                placeholder="Passwort Bestätigung"
                                type="password"
                                autocomplete="new-password"
                                {formID}
                                containerClass="w-full my-4"
                            />

                            <Input
                                on:enter={() => register(data)}
                                bind:value={data.name}
                                name="name"
                                placeholder="Name"
                                type="text"
                                {formID}
                                containerClass="w-full my-4"
                            />

                            <Input
                                on:enter={() => register(data)}
                                bind:value={data.company}
                                name="company"
                                placeholder="Firma"
                                type="text"
                                {formID}
                                containerClass="w-full my-4"
                            />
                        </form>

                        <button
                            on:click|preventDefault={register(data)}
                            type="submit"
                            class="button moss mt-6 block w-full"
                        >
                            <span> Weiter </span>
                        </button>

                        <div class="flex flex-col lg:flex-row gap-2 justify-center mt-3 text-moss">
                            <NavLink
                                to="/login{getQueryParameterByName('intended') ? '?intended=' + getQueryParameterByName('intended') : ''}"
                                linkClass="underline text-center block"
                            >
                                Ich habe bereits ein Benutzerkonto
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>

<style>
    .register-page form:before {
        content: "";
        top: -1px;
        z-index: 1;
    }

    .register-page form:before {
        --tw-gradient-from: rgba(0, 0, 0, 0);
        --tw-gradient-to: rgba(0, 0, 0, 0);
        --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
        --tw-gradient-to: rgba(0, 0, 0, 0);
        --tw-gradient-stops: var(--tw-gradient-from), var(--tw-primary),
            var(--tw-gradient-to);
        background-image: linear-gradient(to right, var(--tw-gradient-stops));
        margin-left: auto;
        margin-right: auto;
        position: absolute;
        height: 2px;
        left: 0;
        right: 0;
        width: 70%;
    }
</style>
