<script>
  import { createEventDispatcher } from "svelte";
  import { fly, fade } from "svelte/transition";

  export let min = 0;
  export let max = 100;
  export let initialValue = 0;
  export let id = null;
  export let value = typeof initialValue === "string" ? parseInt(initialValue) : initialValue;

  let container = null;
  let thumb = null;
  let progressBar = null;
  let element = null;

  let elementX = null;
  let currentThumb = null;
  let holding = false;
  let thumbHover = false;
  let keydownAcceleration = 0;
  let accelerationTimer = null;

  const dispatch = createEventDispatcher();

  const mouseEventShield = document.createElement("div");
  mouseEventShield.setAttribute("class", "mouse-over-shield");
  mouseEventShield.addEventListener("mouseover", (e) => {
    e.preventDefault();
    e.stopPropagation();
  });

  function resizeWindow() {
    elementX = element.getBoundingClientRect().left;
  }

  function setValue(val) {
    value = val;
    dispatch("change", { value });
  }

  function onTrackEvent(e) {
    updateValueOnEvent(e);
    onDragStart(e);
  }

  function onDragStart(e) {
    if (e.type === "mousedown") document.body.append(mouseEventShield);
    currentThumb = thumb;
  }

  function onDragEnd(e) {
    if (e.type === "mouseup") {
      if (document.body.contains(mouseEventShield))
        document.body.removeChild(mouseEventShield);
      if (isMouseInElement(e, thumb)) thumbHover = true;
    }
    currentThumb = null;
  }

  function isMouseInElement(event, element) {
    let rect = element.getBoundingClientRect();
    let { clientX: x, clientY: y } = event;
    if (x < rect.left || x >= rect.right) return false;
    if (y < rect.top || y >= rect.bottom) return false;
    return true;
  }

  function onKeyPress(e) {
    if (keydownAcceleration < 50) keydownAcceleration++;
    let throttled = Math.ceil(keydownAcceleration / 5);

    if (e.key === "ArrowUp" || e.key === "ArrowRight") {
      if (value + throttled > max || value >= max) {
        setValue(max);
      } else {
        setValue(value + throttled);
      }
    }
    if (e.key === "ArrowDown" || e.key === "ArrowLeft") {
      if (value - throttled < min || value <= min) {
        setValue(min);
      } else {
        setValue(value - throttled);
      }
    }

    // Reset acceleration after 100ms of no events
    clearTimeout(accelerationTimer);
    accelerationTimer = setTimeout(() => (keydownAcceleration = 1), 100);
  }

  function calculateNewValue(clientX) {
    let delta = clientX - (elementX + 10);
    let percent = (delta * 100) / (container.clientWidth - 10);
    percent = percent < 0 ? 0 : percent > 100 ? 100 : percent;

    setValue(parseInt((percent * (max - min)) / 100) + min);
  }

  function updateValueOnEvent(e) {
    if (!currentThumb && e.type !== "touchstart" && e.type !== "mousedown")
      return false;

    if (e.stopPropagation) e.stopPropagation();
    if (e.preventDefault) e.preventDefault();

    const clientX =
      e.type === "touchmove" || e.type === "touchstart"
        ? e.touches[0].clientX
        : e.clientX;

    calculateNewValue(clientX);
  }

  $: if (element) elementX = element.getBoundingClientRect().left;
  $: holding = Boolean(currentThumb);
  $: if (progressBar && thumb) {
    value = value > min ? value : min;
    value = value < max ? value : max;

    let percent = ((value - min) * 100) / (max - min);
    let offsetLeft = (container.clientWidth - 10) * (percent / 100) + 5;

    thumb.style.left = `${offsetLeft}px`;
    progressBar.style.width = `${offsetLeft}px`;
  }
</script>

<svelte:window
  on:touchmove|nonpassive={updateValueOnEvent}
  on:touchcancel={onDragEnd}
  on:touchend={onDragEnd}
  on:mousemove={updateValueOnEvent}
  on:mouseup={onDragEnd}
  on:resize={resizeWindow}
/>

<div class="range -mx-1">
  <div
    class="range__wrapper"
    tabindex="0"
    on:keydown={onKeyPress}
    bind:this={element}
    role="slider"
    aria-valuemin={min}
    aria-valuemax={max}
    aria-valuenow={value}
    {id}
    on:mousedown={onTrackEvent}
    on:touchstart={onTrackEvent}
  >
    <div class="range__track" bind:this={container}>
      <div class="range__track--highlighted" bind:this={progressBar} />
      <div
        aria-role="slider"
        class="range__thumb"
        class:range__thumb--holding={holding}
        bind:this={thumb}
        on:touchstart={onDragStart}
        on:mousedown={onDragStart}
        on:mouseover={() => (thumbHover = true)}
        on:focus={() => (thumbHover = true)}
        on:mouseout={() => (thumbHover = false)}
        on:blur={() => (thumbHover = false)}
      >
        {#if holding || thumbHover}
          <div
            class="range__tooltip"
            in:fly={{ y: 7, duration: 200 }}
            out:fade={{ duration: 100 }}
          >
            {value}
          </div>
        {/if}
      </div>
    </div>
  </div>
</div>

<svelte:head>
  <style>
    .mouse-over-shield {
      position: fixed;
      top: 0px;
      left: 0px;
      height: 100%;
      width: 100%;
      background-color: rgba(255, 0, 0, 0);
      z-index: 10000;
      cursor: grabbing;
    }
  </style>
</svelte:head>

<style>
  .range {
    position: relative;
    flex: 1;
  }

  .range__wrapper {
    margin-top: 20px;
    min-width: 100%;
    position: relative;
    padding: 0.5rem;
    box-sizing: border-box;
    outline: none;
  }

  .range__wrapper:focus-visible > .range__track {
    box-shadow: 0 0 0 2px white, 0 0 0 3px rgb(var(--tw-poisongreen));
  }

  .range__track {
    height: 6px;
    background-color: #d0d0d0;
    border-radius: 999px;
  }

  .range__track--highlighted {
    background-color: rgb(var(--tw-poisongreen));
    width: 0;
    height: 6px;
    position: absolute;
    border-radius: 999px;
  }

  .range__thumb {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: white;
    cursor: pointer;
    border-radius: 999px;
    margin-top: -8px;
    transition: box-shadow 100ms;
    user-select: none;
    box-shadow:  0 1px 1px 0 rgba(0, 0, 0, 0.04);
  }

  .range__thumb--holding {
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04),
      0 1px 2px 1px rgba(0, 0, 0, 0.2),
      0 0 0 6px rgb(var(--tw-poisongreen) / 20%);
  }

  .range__tooltip {
    pointer-events: none;
    position: absolute;
    top: -33px;
    color: var(--tooltip-text, white);
    width: 38px;
    padding: 4px 0;
    border-radius: 4px;
    text-align: center;
    background: rgb(var(--tw-earth));
  }

  .range__tooltip::after {
    content: "";
    display: block;
    position: absolute;
    height: 7px;
    width: 7px;
    background-color: rgb(var(--tw-earth));
    bottom: -3px;
    left: calc(50% - 3px);
    clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    transform: rotate(-45deg);
    border-radius: 0 0 0 3px;
  }
</style>