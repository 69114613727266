import { writable } from "svelte/store";

export const leaveNotification = (event) => {
    event.returnValue =
        "Are you sure you want to leave the Page? You have unsaved changes.";
};

export const unsaved_changes = writable(
    localStorage.getItem("unsaved_changes") || null,
);
export const unsaved_changes_popup = writable(null);

unsaved_changes.subscribe((val) => {
    val
        ? localStorage.setItem("unsaved_changes", val)
        : localStorage.removeItem("unsaved_changes");
});

export const disableNavigation = async (event) => {
    navigationDisabled = event.detail.isDisabled;

    if (navigationDisabled) {
        await unsaved_changes.set(JSON.stringify(event.detail.data));
        await window.addEventListener("beforeunload", leaveNotification);
    } else {
        await unsaved_changes_popup.set(null);
        await unsaved_changes.set(null);
        await window.removeEventListener("beforeunload", leaveNotification);
    }
};