<script>
    document.title = "Green Careers - FAQ";

    import { onMount } from "svelte";
    import { api, slug } from "@/services/slug";

    import LoadingDots from "@/components/LoadingDots.svelte";
    import Search from "@/icons/Search.svelte";
    import FaqComponent from "@/components/flexible/FaqComponent.svelte";

    onMount(() => api.search("faq"))

    let search = ""
</script>

<main>
    <div class="bg-moss flex flex-col justify-end">
        <div class="container mx-auto lg:max-w-4xl text-white pt-16 pb-8">
            <div class="rounded-full bg-snow px-3 py-2 w-full flex flex-row items-center justify-between text-gray border-2 border-poisongreen mt-4">
                <input bind:value={search} type="text" class="w-full h-full bg-transparent text-gray focus:outline-none placeholder:text-gray" placeholder="Durchsuche FAQ Einträge"/>
                <span class="relative flex h-10 w-10">
                    <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-limegreen opacity-50"></span>
                    <span class="relative inline-flex rounded-full h-10 w-10 bg-limegreen items-center justify-center">
                        <Search mainClass="text-white w-5 h-5"/>
                    </span>
                </span>
            </div>
        </div>
    </div>

    <div class="bg-moss h-12">
        <div class="bg-sandlight w-full h-full rounded-t-3xl"></div>
    </div>

    <div class="container mx-auto lg:max-w-4xl pb-8 rounded-3xl">
        {#if $slug?.data?.content}
            {#each $slug.data.content as content}
                <div class="html-content">
                    {#if content.type === "html"}
                        {@html content.data.content}
                    {/if}
                    {#if content.type === "faq_list"}
                        <FaqComponent data={content.data.content} search={search}/>
                    {/if}
                </div>
            {/each}
        {:else}
            <LoadingDots/>
        {/if}
    </div>
</main>