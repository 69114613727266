<script>
    document.title = "Green Careers - Impressum";

    import { createEventDispatcher, onMount } from "svelte";
    import { api, slug } from "@/services/slug";
    import LoadingDots from "@/components/LoadingDots.svelte";
    import HtmlComponent from "@/components/flexible/HtmlComponent.svelte";

    const dispatch = createEventDispatcher();

    onMount(async () => {
        await api.search("impressum")

        dispatch("loaded")
    })
</script>

<main>
    <section class="appear min-h-[450px] bg-cover bg-[center_top_20%] relative" style="background-image: url('/images/privacy.jpg');">
        <div class="absolute left-0 right-0 bottom-0 top-1/3 bg-gradient-to-t from-black/90"></div>
        <div class="absolute left-0 right-0 bottom-0 py-12">
            <div class="container mx-auto max-w-4xl text-white">
                <h1>
                    Impressum
                </h1>
            </div>
        </div>
    </section>

    <div class="appear container mx-auto lg:max-w-4xl py-8">
        {#if $slug?.data?.content}
            {#each $slug.data.content as content}
                {#if content.type === "html"}
                    <HtmlComponent data={content.data}/>
                {/if}
            {/each}
        {:else}
            <LoadingDots/>
        {/if}
    </div>
</main>