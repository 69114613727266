import Api from "@/api.js"
import axios from "@/lib/axios.js"
import { writable } from "svelte/store";
import { success, failure } from "@/lib/toast.js";

export const slugs = writable();
export const slug = writable();

class SlugApi extends Api {
    constructor() {
        super('/api/slugs', slugs, slug);
    }

    async search(path, config) {
        slug.set(null);
        
        return await axios
            .get(`/api/slugs/search/${path}`, config)
            .then(async (response) => {
                slug.set(response.data);
                return response;
            })
            .catch((error) => {
                console.log(error);
                return Promise.reject(error);
            });
    }
}

export const api = new SlugApi('/api/slugs', slugs, slug);