<script>
    export const focus = () => {
        field.focus();
    };

    export const clear = () => {
        field.value = "";
    }

    export let popover = undefined;
    export let tooltip = undefined;
    export let autofocus = false;
    export let autocomplete = "off";
    export let disabled = false;
    export let name = undefined;
    export let placeholder = undefined;
    export let required = false;
    export let label = undefined;
    export let type = "text";
    export let value = undefined;
    export let renderValue = (value) => value;
    export let dataToSend = (value) => value;
    export let showNoResults = true;
    export let search = undefined;

    export let service = undefined;
    export let serviceMethod = undefined;
    

    export let inputClass = undefined;
    export let containerClass = undefined;

    export let formID = undefined;

    import Label from "@/components/form/Label.svelte";
    import InputError from "@/components/form/InputError.svelte";
    import Tooltip from "@/components/form/Tooltip.svelte";
    import Information from "@/icons/Information.svelte";

    import { createEventDispatcher } from "svelte";
    import { clickOutside } from "@/lib/utils";

    import { debounce } from "lodash";

    let hasErrors;
    let results = [];
    let searchOpen = false;
    let field;

    const dispatch = createEventDispatcher();

    const onKeyPress = (event) => {
        // only on enter
        if (event.charCode === 13) {
            event.preventDefault();
            dispatch("enter");
        }
    };

    const handleSearch = async (event) => {
        search = event.target.value

        await service[serviceMethod](dataToSend({name: event.target.value}))
            .then((response) => {
                results = response.data.data;
                searchOpen = true;
            });
    }

    const handleSearchDebounced = debounce((event) => handleSearch(event), 300);
</script>

<div class="{containerClass} relative" use:clickOutside on:clickOutside={() => (searchOpen = false)}>
    {#if label}
        <Label {label} {required} {popover} />
    {/if}

    {#if tooltip}
        <div class="absolute top-1/2 -translate-y-1/2 right-4">
            <Tooltip title={tooltip}>
                <Information mainClass="w-5 text-limegreen"/>
            </Tooltip>
        </div>
    {/if}

    <div class="relative">
        <input
            {autofocus}
            {autocomplete}
            {disabled}
            {name}
            {placeholder}
            {required}
            {type}
            value="{renderValue(value)}"
            on:input={(event) => handleSearchDebounced(event)}
            on:focus={(event) => handleSearchDebounced(event)}
            on:click={() => searchOpen = true}
            on:keypress={onKeyPress}
            bind:this={field}
            class="input w-full border {inputClass} {hasErrors ? '!border-red placeholder:text-red text-red' : ''}"
        />

        {#if required}
            <span class="text-limegreen required absolute top-3 left-2.5 z-10 transition-all {value ? 'opacity-0' : 'opacity-100'}">*</span>
        {/if}

        {#if searchOpen}
            <div class="absolute w-full max-h-[400px] bg-white rounded-md shadow-md overflow-y-auto z-30 mt-2">
                {#if results.length === 0 && showNoResults}
                    <div class="p-4">
                        Keine Ergebnisse
                    </div>
                {:else if results.length > 0}
                    <div>
                        {#each results as result}
                            <button class="line-clamp-1 text-ellipsis overflow-hidden text-left hover:bg-moss hover:text-white w-full" on:click={() => {
                                value = result;
                                searchOpen = false;
                            }}>
                                <p class="py-2 px-4">
                                    {result.name}
                                </p>
                            </button>
                        {/each}
                    </div>
                {/if}
            </div>
        {/if}
    </div>

    <InputError bind:hasErrors {name} {formID} {value}/>
</div>
