<script>
    import { merge } from "lodash";
    import { onMount } from "svelte";
    import { service as transactionService, transactions } from "@/services/transaction";
    import Pagination from "@/components/Pagination.svelte";
    import LoadingDots from "@/components/LoadingDots.svelte";
    import Tooltip from "@/components/form/Tooltip.svelte";
    import moment from "moment";

    let loading = true;

    const getTransactions = async (config) => {
        loading = true;

        await transactionService.index(
            merge({
                params: {
                    order_by: ['created_at.desc'],
                    per_page: 5,
                }
            }, config)
        );

        loading = false;
    }

    const paginate = (event) => {
        getTransactions({
            params: {
                page: event.detail,
            }
        });
    };

    onMount(async () => {
        await getTransactions();
    });

    const formatDate = (date) => moment(date).format('DD.MM.YYYY HH:mm');
</script>

<main>
    {#if $transactions?.data}
        <div class="mt-8">
            {#if $transactions.data.length === 0}
                <div>
                    Sie haben aktuell noch keine Rechnungen
                </div>
            {:else}
                {#each $transactions.data as transaction}
                    <div class="rounded-xl p-3 shadow-md my-2 border border-moss/20 flex flex-row items-center gap-8 justify-between {loading ? "opacity-20" : "opacity-100"}">
                        {#if loading}
                            <div class="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">
                                <LoadingDots/>
                            </div>
                        {/if}
                        <div class="w-full">
                            <strong>
                                Rechnung
                            </strong>
                            <div class="flex flex-col lg:flex-row gap-2 border-t border-moss/20 w-full py-1 mt-1">
                                <div class="w-full lg:w-1/2">
                                    Datum: {formatDate(transaction.created_at)}
                                </div>
                                <div class="w-full lg:w-1/2 lg:text-right">
                                    Gesamtbetrag: {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format((transaction.amount_total / 100))}
                                </div>
                            </div>
                        </div>
                        <div class="w-12 flex justify-end relative">
                            <div class="absolute top-1/2 -translate-y-1/2 right-4">
                                {#if transaction.has_invoice}
                                <a target="_blank" href="/transactions/{transaction.id}/invoice/download" class="w-8 h-8 bg-moss hover:bg-limegreen transition-all text-white rounded-md flex items-center justify-center p-2">
                                    <svg class="w-full h-full" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.99073 -0.000976546C8.43873 -0.000976546 7.99073 0.446724 7.99073 0.999024V11.561L4.99073 8.59303L3.58472 9.99903L8.27174 14.718C8.66274 15.108 9.31872 15.108 9.70973 14.718L14.3967 9.99903L12.9907 8.59303L9.99073 11.561V0.999024C9.99073 0.446724 9.54273 -0.00107655 8.99073 -0.000976546ZM0.990723 11.999C0.438723 11.999 -0.00927734 12.447 -0.00927734 12.999V14.999C-0.00927734 17.208 1.78172 18.999 3.99073 18.999H13.9907C16.1997 18.999 17.9907 17.208 17.9907 14.999V12.999C17.9907 12.447 17.5427 11.999 16.9907 11.999C16.4387 11.999 15.9907 12.447 15.9907 12.999V14.999C15.9907 16.104 15.0957 16.999 13.9907 16.999H3.99073C2.88573 16.999 1.99072 16.104 1.99072 14.999V12.999C1.99072 12.447 1.54272 11.999 0.990723 11.999Z" fill="currentColor"/>
                                    </svg>
                                </a>
                                {:else}
                                    <Tooltip title="Ihre Rechnung wird aktuell noch generiert, bitte versuchen Sie es in ein paar Minuten erneut">
                                        <p class="w-8 h-8 bg-moss hover:bg-limegreen transition-all text-white rounded-md flex items-center justify-center p-2">
                                            <svg class="w-full h-full" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.99073 -0.000976546C8.43873 -0.000976546 7.99073 0.446724 7.99073 0.999024V11.561L4.99073 8.59303L3.58472 9.99903L8.27174 14.718C8.66274 15.108 9.31872 15.108 9.70973 14.718L14.3967 9.99903L12.9907 8.59303L9.99073 11.561V0.999024C9.99073 0.446724 9.54273 -0.00107655 8.99073 -0.000976546ZM0.990723 11.999C0.438723 11.999 -0.00927734 12.447 -0.00927734 12.999V14.999C-0.00927734 17.208 1.78172 18.999 3.99073 18.999H13.9907C16.1997 18.999 17.9907 17.208 17.9907 14.999V12.999C17.9907 12.447 17.5427 11.999 16.9907 11.999C16.4387 11.999 15.9907 12.447 15.9907 12.999V14.999C15.9907 16.104 15.0957 16.999 13.9907 16.999H3.99073C2.88573 16.999 1.99072 16.104 1.99072 14.999V12.999C1.99072 12.447 1.54272 11.999 0.990723 11.999Z" fill="currentColor"/>
                                            </svg>
                                        </p>
                                    </Tooltip>
                                {/if}
                            </div>
                        </div>
                    </div>
                {/each}
            {/if}

            <div class="flex justify-center">
                <Pagination
                    on:paginate={(event) => paginate(event)}
                    meta={$transactions.meta}
                />
            </div>
        </div>
    {:else}
        <div class="my-4">
            <LoadingDots />
        </div>
    {/if}
</main>