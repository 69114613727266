<script>
    import Calendar from '@/icons/Calendar.svelte'
    import moment from "moment";

    export let mainClass;
    export let date;

    const formatJobAvailableAt = (date) => {
        return moment(date).format("DD.MM.YYYY");
    }
</script>

<div class="rounded-md inline-flex gap-2 text-moss absolute top-4 left-4 z-10 bg-poisongreen p-1.5 items-center shadow-lg {mainClass}">
    <Calendar mainClass="w-4"/>
    <small class="font-bold leading-none">
        ab {formatJobAvailableAt(date)}
    </small>
</div>