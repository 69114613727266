<script>
    export let params;
    export let currentTab = getQueryParameterByName("tab") || "jobdescription";

    import Header from '@/components/job/Header.svelte';
    import Navigation from '@/components/job/Navigation.svelte';
    import JobDescription from '@/components/job/JobDescription.svelte';
    import Benefits from '@/components/job/Benefits.svelte';
    import About from '@/components/job/About.svelte';
    import ContactPerson from '@/components/job/ContactPerson.svelte';
    import ApplyPopover from '@/components/job/ApplyPopover.svelte';
    import UpgradeJobPopup from '@/components/UpgradeJobPopup.svelte';

    import { onDestroy, onMount, tick } from "svelte";
    import { link, navigate } from "svelte-routing";

    import { jobAdvertisement as tempJobAdvertisement, employer as tempEmployer } from "@/stores/temporary";
    import { service as employerService, employer } from "@/services/employer";
    import { service as jobService } from "@/services/job";
    import { omit } from "lodash";
    import { stickyUntilElement, replaceQueryParams, getQueryParameterByName } from "@/lib/utils";
    import { user } from "@/services/user";
    import { errors } from "@/stores/error";
    import { failure } from "@/lib/toast";
    import moment from "moment";

    let mode = "edit";
    let applyOpen = false;
    let loading = false;
    let vacancyLoading = true;
    let forceViewPublish = false;
    let publishPopupOpen = getQueryParameterByName("publishPopupOpen") === "true" ?? false;

    const tabs = [
        {
            title: "Jobbeschreibung", value: "jobdescription"
        },
        {
            title: "Benefits", value: "benefits"
        },
        {
            title: "Über Uns", value: "about"
        },
    ];

    let jobAdvertisement = {
        data: {
            user_id: $user.data.id,
            employer_id: $employer.data.id,

            employment_type: null,
            job_available_at: null,
            description: null,
            
            your_tasks: null,
            your_profile: null,

            // benefits
            benefits: {
                data: [],
            },

            // initially set files from employer
            logos: $employer.data.logos,
            // files: $employer.data.files, // this is related to the employer
            title_images: $employer.data.title_images,
            contact_images: $employer.data.contact_images,
            
            // initially set contact from employer
            contact_name: $employer.data.contact_name ?? "",
            contact_phone: $employer.data.contact_phone ?? "",
            contact_whatsapp: $employer.data.contact_whatsapp ?? "",
            contact_position: $employer.data.contact_position ?? "",
            contact_email: $employer.data.contact_email ?? "",

            // initially set from employer
            street: $employer.data.street ?? "",
            zipcode: $employer.data.zipcode ?? "",
            location: $employer.data.location ?? "",
            country: $employer.data.country ?? "",
            latitude: $employer.data.latitude ?? "",
            longitude: $employer.data.longitude ?? "",
        }
    }

    // is used for preparing the data for update view
    const prepareDataForUpdate = (data) => {
        // initially set the sector id with the first sector
        data.data.sector_id = data.data.sectors.data[0].id;

        // initially set the date value
        data.data.job_available_at = data.data.job_available_at ? moment(data.data.job_available_at).format("YYYY-MM-DD") : "";

        // files: $employer.data.files, // this is related to the employer
        data.data.title_images = data.data.title_images ?? $employer.data.title_images
        data.data.contact_images = data.data.contact_images ?? $employer.data.contact_images

        return data;
    } 

    const watchStickyNavbar = () => stickyUntilElement("navbar", "footer");

    // set initial values
    onMount(async () => {
        vacancyLoading = true;

        if(params.id) {
            await jobService.show({id: params.id}).then((response) => {
                let data = prepareDataForUpdate(structuredClone(response.data));

                tempJobAdvertisement.set(data);
            });
        }
        else {
            tempJobAdvertisement.set(jobAdvertisement);
        }

        tempEmployer.set($employer);
        vacancyLoading = false;

        window.addEventListener('scroll', watchStickyNavbar);
    });

    onDestroy(() => {
        window.removeEventListener('scroll', watchStickyNavbar);
    });

    const saveEmployer = async (employer) => {
        let storeEmployer = omit(employer, [
            "free_premium_contingents_count",
            "free_smart_contingents_count"
        ])

        // remove files with id from data because they are already stored
        storeEmployer["contact_images"] = storeEmployer["contact_images"]?.hasOwnProperty("data") ? [] : storeEmployer["contact_images"];
        storeEmployer["title_images"] = storeEmployer["title_images"]?.hasOwnProperty("data") ? [] : storeEmployer["title_images"];
        storeEmployer["logos"] = storeEmployer["logos"]?.hasOwnProperty("data") ? [] : storeEmployer["logos"];
        storeEmployer["files"] = storeEmployer["files"]?.hasOwnProperty("data") ? [] : storeEmployer["files"];

        return await employerService.update(storeEmployer, {formID: "employer"})
            .then((response) => {
                tempEmployer.set(response.data);
            })
            .catch((error) => {
                error.response.data.message && failure(error.response.data.message);
                return Promise.reject(error);
            });
    }

    const storeJobAdvertisement = async (storeJobAdvertisement) => {
        // multiselects to single values for storing
        storeJobAdvertisement.employment_type = storeJobAdvertisement?.employment_type?.id ?? null;
        storeJobAdvertisement.position_id = storeJobAdvertisement?.position?.id ?? null;
        storeJobAdvertisement.sector_id = storeJobAdvertisement?.sector?.id ?? null;
        storeJobAdvertisement.working_time = storeJobAdvertisement?.working_time?.id ?? null;

        // remove nested objects not required for storing
        storeJobAdvertisement = omit(storeJobAdvertisement, ["position", "sector", "sectors"]);

        // remove files with id from data because they are alredy stored
        storeJobAdvertisement["files"] = storeJobAdvertisement["files"]?.hasOwnProperty("data") ? [] : storeJobAdvertisement["files"];
        storeJobAdvertisement["title_images"] = storeJobAdvertisement["title_images"]?.hasOwnProperty("data") ? [] : storeJobAdvertisement["title_images"];
        storeJobAdvertisement["contact_images"] = storeJobAdvertisement["contact_images"]?.hasOwnProperty("data") ? [] : storeJobAdvertisement["contact_images"];

        return await jobService.store(storeJobAdvertisement, {formID: "vacancy"})
            .then(async (response) => {
                saveEmployer($tempEmployer.data);

                navigate(`/dashboard/job/${response.data.data.id}?tab=benefits`);
                return response;
            })
            .catch((error) => {
                error.response.data.message && failure(error.response.data.message);
                return Promise.reject(error);
            });
    }

    const updateJobAdvertisement = async (storeJobAdvertisement) => {
        // multiselects to single values for storing
        storeJobAdvertisement.employment_type = storeJobAdvertisement?.employment_type?.id ?? storeJobAdvertisement?.employment_type ?? null;
        storeJobAdvertisement.position_id = storeJobAdvertisement?.position?.id ?? storeJobAdvertisement?.position ?? null;
        storeJobAdvertisement.sector_id = storeJobAdvertisement?.sector?.id ?? storeJobAdvertisement?.sectors.data[0].id ?? null;
        storeJobAdvertisement.working_time = storeJobAdvertisement?.working_time?.id ?? storeJobAdvertisement?.working_time ?? null;

        // remove nested objects not required for storing
        storeJobAdvertisement = omit(storeJobAdvertisement, ["position", "sectors", "sector"]);

        // remove files with id from data because they are alredy stored
        storeJobAdvertisement["files"] = storeJobAdvertisement["files"]?.hasOwnProperty("data") ? [] : storeJobAdvertisement["files"];
        storeJobAdvertisement["title_images"] = storeJobAdvertisement["title_images"]?.hasOwnProperty("data") ? [] : storeJobAdvertisement["title_images"];
        storeJobAdvertisement["contact_images"] = storeJobAdvertisement["contact_images"]?.hasOwnProperty("data") ? [] : storeJobAdvertisement["contact_images"];

        // prepare Benefits
        storeJobAdvertisement["benefits"] = storeJobAdvertisement["benefits"]?.hasOwnProperty("data") 
            ? storeJobAdvertisement["benefits"].data.map((entry) => entry.id) 
            : [];

        return await jobService.update(storeJobAdvertisement, {formID: "vacancy"})
            .then(async (response) => {
                saveEmployer($tempEmployer.data);

                tempJobAdvertisement.set(
                    prepareDataForUpdate(structuredClone(response.data))
                );
                return response;
            })
            .catch((error) => {
                error.response.data.message && failure(error.response.data.message);
                return Promise.reject(error);
            });
    }

    const triggerCurrentSaveMode = async (tempJobAdvertisement) => params.id 
        ? await updateJobAdvertisement(structuredClone(tempJobAdvertisement)) 
        : await storeJobAdvertisement(structuredClone(tempJobAdvertisement));

    const preview = () => {
        navigate(`/dashboard/job/preview?id=${$tempJobAdvertisement.data.id}`);
    }

    const publish = async () => {
        await triggerCurrentSaveMode($tempJobAdvertisement.data)

        publishPopupOpen = true;
    }

    const save = async () => {
        await updateJobAdvertisement(structuredClone($tempJobAdvertisement.data))

        navigate(`/dashboard/?tab=inserieren`);
    }

    const changeCurrentTab = async (e) => {
        loading = true;

        await tick();
        scrollTo(0, 0);

        const next = () => {
            currentTab = e.detail;
            replaceQueryParams({tab: currentTab});
        }
        
        // if about tab is selected, force view publish
        if(currentTab === "about") {
            forceViewPublish = true;
        }

        await triggerCurrentSaveMode($tempJobAdvertisement.data)
            .then(() => {
                next();
            })
            .catch(() => {
                return;
            })
            .finally(() => {
                loading = false;
            });

        // if job is in edit mode allow navigation without saving
        if(params.id) next();
        loading = false;
    }

    onDestroy(() => {
        // reset error stores
        errors.update((errors) => {
            _.set(errors, "vacancy", [])
            _.set(errors, "employer", [])

            return errors;
        });
    })

    const prevStep = () => {
        let currentIndex = tabs.findIndex((tab) => tab.value === currentTab);
        let prevTab = tabs[currentIndex - 1];

        changeCurrentTab({detail: prevTab?.value ?? null});
    }

    const nextStep = () => {
        let currentIndex = tabs.findIndex((tab) => tab.value === currentTab);
        let nextTab = tabs[currentIndex + 1];

        changeCurrentTab({detail: nextTab?.value ?? null})
    }
</script>

{#if vacancyLoading === false}
    <main class="mb-32 mt-20">
        <Header 
            mode={mode}
            formID={"vacancy"}
        />

        <Navigation 
            mode={mode}
            formID={"vacancy"}
            currentTab={currentTab} 
            tabs={tabs}
            loading={loading}
            on:changeCurrentTab={changeCurrentTab}
        />

        <div class="container mx-auto max-w-3xl">
            {#if currentTab === "jobdescription"}
                <JobDescription 
                    mode={mode}
                    formID={"vacancy"}
                    loading={loading}
                />
            {/if}

            {#if currentTab === "benefits"}
                <Benefits 
                    mode={mode}
                    formID={"vacancy"} 
                    loading={loading}
                />
            {/if}

            {#if currentTab === "about"}
                <About 
                    mode={mode}
                    formID={"employer"} 
                    loading={loading}
                />
            {/if}

            {#if mode === "view"}
                <section class="py-6 max-w-sm mx-auto">
                    <button on:click={() => applyOpen = true} class="button gradient">
                        Bewirb dich jetzt
                    </button>
                </section>
            {/if}

            <ContactPerson 
                mode={mode}
                formID={"vacancy"} 
                loading={loading}
            />
        </div>

        <div class="fixed bottom-0 left-0 right-0 p-4 z-50 bg-moss/30 backdrop-blur-md flex justify-center" id="navbar">
            <div class="flex flex-col gap-4 w-full max-w-sm">
                {#if (currentTab === "about" && $tempJobAdvertisement.data.published_at === null) || forceViewPublish}
                    <button disabled={loading} on:click={publish} class="button moss !py-3">
                        Inserat veröffentlichen
                    </button>
                {:else if currentTab === "about"}
                    <button disabled={loading} on:click={save} class="button moss !py-3">
                        Inserat speichern
                    </button>
                {/if}
                <div class="flex flex-row gap-4">
                    {#if currentTab === "jobdescription"}
                        <button on:click={() => navigate("/dashboard")} class="button sandlight !py-2">
                            Zurück
                        </button>
                    {:else}
                        <button disabled="{currentTab === "jobdescription" || loading}" on:click={prevStep} class="button sandlight !py-2">
                            Zurück
                        </button>
                    {/if}
                    {#if currentTab !== "about"}
                        <button disabled={loading} on:click={nextStep} class="button moss !py-2">
                            Weiter
                        </button>
                    {:else}
                        <button disabled={loading} on:click={preview} class="button sandlight !py-2">
                            Vorschau
                        </button>
                    {/if}
                </div>
            </div>
        </div>

        <ApplyPopover open={applyOpen} on:close={() => applyOpen = false}/>
        <UpgradeJobPopup bind:open={publishPopupOpen} job={$tempJobAdvertisement.data}/>
    </main>
{/if}