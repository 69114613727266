<script>
    export let hasErrors = false;
    export let formID = "default";
    export let name = undefined;
    export let value = undefined;

    import { errors } from "@/stores/error";

    let inputError = false;
    $: hasErrors = inputError ? true : false;

    function stripHtml(html)
    {
        let tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    }

    const resetErrors = () => {
        errors.update((store) => {
            let formErrors = _.get(store, formID);

            if (formErrors) {
                delete formErrors[name?.replaceAll(".", "-")];
                hasErrors = false;
            }

            return store;
        });
    }

    errors.subscribe((data) => {
        inputError = _.get(data, `${formID}.${name?.replaceAll(".", "-")}`);
    });

    $: value && resetErrors();
</script>

<main>
    {#if inputError}
        {#each inputError as error}
            <div class="my-1 text-red text-xs text-left">
                {@html stripHtml(error).replaceAll("\n", "<br/>")}
            </div>
        {/each}
    {/if}
</main>