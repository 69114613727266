<script>
	export let title = '';
	let isHovered = false;
	let x;
	let y;
    let tooltip;
	
	function mouseOver(event) {
        isHovered = true;
        
        if(tooltip) {
            x = event.offsetX - tooltip.offsetWidth + 16;
            y = event.offsetY - (tooltip.offsetHeight + 20);
        }

        document.body.style.cursor = 'pointer';
	}
	function mouseMove(event) {
        if(tooltip) {
            x = event.offsetX - tooltip.offsetWidth + 16;
            y = event.offsetY - (tooltip.offsetHeight + 20);
        }
	}
	function mouseLeave() {
		isHovered = false;
        document.body.style.cursor = 'unset';
	}
</script>

<div
    on:mouseover={mouseOver}
    on:focus={mouseOver}
    on:mouseleave={mouseLeave}
    on:mousemove={mouseMove}>
    <slot />
</div>

{#if isHovered && title}
	<div bind:this={tooltip} style="top: {y}px; left: {x}px;" class="tooltip">{@html title}</div>
{/if}

<style>
	.tooltip {
		position: absolute;
        z-index: 1000;
        cursor: pointer;
        @apply text-xs border-[#696F7D] p-2 bg-moss text-sandlight rounded-md whitespace-nowrap text-center;
	}

    .tooltip::after {
        content: '';
        border-top: 10px solid rgb(var(--tw-moss));
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        position: absolute;
        top: 100%;
        right: 10px;
        pointer-events: none;
    }
</style>