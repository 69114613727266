import Api from "@/api.js"
import axios from "@/lib/axios.js"
import { writable } from "svelte/store";
import { success, failure } from "@/lib/toast.js";

export const checkout = writable();

class CheckoutApi extends Api {
    constructor() {
        super('/api/checkout');
    }

    async checkout(data, config) {
        return await axios
            .post(`/api/checkout`, data, config)
            .then(async (response) => {
                return response;
            })
            .catch((error) => {
                console.log(error);
                return Promise.reject(error);
            });
    }

    async checkoutInvoice(data, config) {
        return await axios
            .post(`/api/checkout/invoice`, data, config)
            .then(async (response) => {
                return response;
            })
            .catch((error) => {
                console.log(error);
                return Promise.reject(error);
            });
    }

    async process(data, config) {
        return await axios
            .post(`/api/checkout/process`, data, config)
            .then(async (response) => {
                return response;
            })
            .catch((error) => {
                console.log(error);
                return Promise.reject(error);
            });
    }

    async upgrade(data, config) {
        return await axios
            .post(`/api/checkout/upgrade`, data, config)
            .then(async (response) => {
                return response;
            })
            .catch((error) => {
                console.log(error);
                return Promise.reject(error);
            });
    }
}

export const api = new CheckoutApi('/api/checkout');