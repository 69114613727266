<script>
    import { createEventDispatcher } from "svelte"
    
    export let labels
    export let value

    const dispatch = createEventDispatcher()
</script>

<main class="bg-white rounded-md inline-flex flex-row shadow-lg border border-moss overflow-hidden">
    {#each labels as label}
        <button on:click={() => dispatch("change", label.value)} class="w-[140px] transition-all ease-in-out p-1 border {label.value === value ? "bg-moss text-white border-moss" : "bg-white text-moss border-white"}">
            {label.label}
        </button>
    {/each}
</main>