<script>
    import { onMount } from "svelte"

    import Swiper, { Pagination, Navigation, Scrollbar, Autoplay } from 'swiper';
    Swiper.use([Pagination, Navigation, Scrollbar, Autoplay]);

    let left_slider = undefined;
    let right_slider = undefined;

    const half = Math.ceil(window.meta.settings.logos[0].data.content.length / 2);

    onMount(() => {
        new Swiper(left_slider, {
            slidesPerView: 4,
            watchSlidesProgress: true,
            autoplay: {
                reverseDirection: false,
                delay: 0,
            },
            grabCursor: true,
            spaceBetween: 15,
            centeredSlides: false,
            loop: true,
            loopFillGroupWithBlank: true,
            speed: 3500,
            breakpoints: {
                0: {
                    slidesPerView: 2,
                    spaceBetween: 30
                },
                640: {
                    slidesPerView: 3,
                    spaceBetween: 30
                },
                768: {
                    slidesPerView: 5,
                    spaceBetween: 30
                },
            }
        })

        new Swiper(right_slider, {
            slidesPerView: 4,
            watchSlidesProgress: true,
            autoplay: {
                reverseDirection: true,
                delay: 0,
            },
            grabCursor: true,
            spaceBetween: 15,
            centeredSlides: false,
            loop: true,
            loopFillGroupWithBlank: true,
            speed: 3500,
            breakpoints: {
                0: {
                    slidesPerView: 2,
                    spaceBetween: 30
                },
                640: {
                    slidesPerView: 3,
                    spaceBetween: 30
                },
                768: {
                    slidesPerView: 5,
                    spaceBetween: 30
                },
            }
        })
    })
</script>

{#if window.meta.settings.logos}
    <main>
        <div class="mx-1 mt-12 relative">
            <div class="logo-slider relative">
                <div bind:this={left_slider}>
                    <div class="swiper-wrapper">
                        {#each window.meta.settings.logos[0].data.content.slice(0, half) as logo}
                            <div class="swiper-slide text-center font-semibold">
                                {#if logo.data.url}
                                    <a href="{logo.data.url}" target="_blank">
                                        <img src="/storage/{logo.data.logo}" alt="{logo.data.name}" class="rounded-md shadow-md"/>
                                    </a>
                                {:else}
                                    <img src="/storage/{logo.data.logo}" alt="{logo.data.name}" class="rounded-md shadow-md"/>
                                {/if}
                            </div>
                        {/each}
                    </div>
                </div>
                <br/>
                <div bind:this={right_slider}>
                    <div class="swiper-wrapper">
                        {#each window.meta.settings.logos[0].data.content.slice(half) as logo}
                            <div class="swiper-slide text-center font-semibold">
                                {#if logo.data.url}
                                    <a href="{logo.data.url}" target="_blank">
                                        <img src="/storage/{logo.data.logo}" alt="{logo.data.name}" class="rounded-md shadow-md"/>
                                    </a>
                                {:else}
                                    <img src="/storage/{logo.data.logo}" alt="{logo.data.name}" class="rounded-md shadow-md"/>
                                {/if}
                            </div>
                        {/each}
                    </div>
                </div>
            </div>

            <div class="absolute top-0 bottom-0 right-0 w-12 lg:w-32 from-transparent to-sandlight bg-gradient-to-r z-20"></div>
            <div class="absolute top-0 bottom-0 left-0 w-12 lg:w-32 from-transparent to-sandlight bg-gradient-to-l z-20"></div>
        </div>
    </main>
{/if}