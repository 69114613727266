<script>
    export let mode;

    import { service as benefitsService, benefits } from '@/services/benefit';
    import { employer } from '@/services/employer';
    import { user } from '@/services/user';
    import { slide } from 'svelte/transition';
    import { onMount } from 'svelte';
    import { jobAdvertisement as tempJobAdvertisement, employer as tempEmployer } from "@/stores/temporary";
    import { failure } from '@/lib/toast';

    import AutocompleteInput from '@/components/form/AutocompleteInput.svelte';
    import Checkbox from '@/components/form/Checkbox.svelte';
    import Trash from '@/icons/Trash.svelte';

    onMount(async () => {
        if(mode === "edit") {
            await benefitsService.index({
                params: {
                    per_page: 500,
                }
            });

            // merge benefits from employer to benefits from index initially
            if($tempJobAdvertisement.data.benefits.data.length > 0) {
                benefits.update((benefitStore) => {
                    $tempJobAdvertisement.data.benefits.data.forEach(element => {
                        if(benefitStore.data.find((benefit) => benefit.id === element.id) === undefined) {
                            benefitStore.data = [...benefitStore.data, element];
                        }
                    });

                    return benefitStore;
                })
            }
        }
    })

    let benefit = null;
    let search = null;
    let formID = "benefits";
    let autocompleteField = undefined;

    const addBenefit = () => {
        if(benefit) {
            benefits.update((store) => {
                if(store.data.find((data) => data.id === benefit.id)) {
                    return store;
                };

                store.data = [...store.data, benefit];

                changeBenefit(benefit);
                search = null;
                autocompleteField.clear();

                return store;
            })
        } else if(search) {
            benefitsService.store({name: search, employer_id: $employer.data.id, user_id: $user.data.id}, {formID: formID})
                .then((response) => {
                    benefits.update((store) => {
                        store.data = [...store.data, response.data.data];
                        return store;
                    })

                    changeBenefit(response.data.data);
                    search = null;
                    autocompleteField.clear();
                })
        }
        else {
            failure("Bitte geben Sie einen Benefit ein.");
        }
    }

    const changeBenefit = (benefit) => {
        tempJobAdvertisement.update((store) => {
            (store.data.benefits.data.find((b) => b.id === benefit.id))
                ? store.data.benefits.data = store.data.benefits.data.filter((b) => b.id !== benefit.id)
                : store.data.benefits.data = [...store.data.benefits.data, benefit];

            return store;
        })
    }

    const removeBenefit = (benefit) => {
        // if benefit is not displayed and has no employer_id, remove it from the list but no need to store this in the database
        if(benefit.is_displayed === false && benefit.employer_id === null) {
            tempJobAdvertisement.update((store) => {
                store.data.benefits.data = store.data.benefits.data.filter((b) => b.id !== benefit.id);
                return store;
            })

            benefits.update((store) => {
                store.data = store.data.filter((b) => b.id !== benefit.id);
                return store;
            })
        }
        else {
            benefitsService.destroy(benefit)
                .then(() => {
                    tempJobAdvertisement.update((store) => {
                        store.data.benefits.data = store.data.benefits.data.filter((b) => b.id !== benefit.id);
                        return store;
                    })

                    benefits.update((store) => {
                        store.data = store.data.filter((b) => b.id !== benefit.id);
                        return store;
                    })
                })
        }
    }
</script>

<div transition:slide>
    <section class="py-6">
        <h1 class="text-2xl font-semibold text-moss">
            Benefits
        </h1>
        <h2 class="text-xl font-semibold text-limegreen">
            Was wir Dir bieten
        </h2>
        <p class="mt-2 text-earth">
            Bei {$tempEmployer.data.company} profitierst Du von folgenden Vorteilen:
        </p>

        <div class="mt-4">
            {#if mode === "view"}
                {#if $tempJobAdvertisement.data.benefits.data.length === 0}
                    <p>
                        Aktuell sind keine Benefits verfügbar.
                    </p>
                {:else}
                    <div class="contenteditable">
                        <ul>
                            {#each $tempJobAdvertisement.data.benefits.data as benefit}
                                <li class="mt-2">
                                    {benefit.name}
                                </li>
                            {/each}
                        </ul>
                    </div>
                {/if}
            {:else if (mode === "edit" && $benefits?.data)}
                <!-- Do this both for Users and non Users benefits -->
                <div class="flex flex-col gap-2">
                    {#each $benefits.data.filter((benefit) => benefit.is_displayed === true && benefit.employer_id === null) as benefit}
                        <div class="flex items-center gap-2">
                            <Checkbox
                                value={$tempJobAdvertisement.data.benefits.data.find((b) => b.id === benefit.id)}
                                on:change={(event) => changeBenefit(benefit)}
                                name="remember_me"
                                option={benefit.name}
                                containerClass=""
                            />
                        </div>
                    {/each}
                    {#if $benefits.data.filter((benefit) => benefit.is_displayed === false && benefit.employer_id === null).length > 0}
                        <hr class="my-2 border-sanddark"/>
                        {#each $benefits.data.filter((benefit) => benefit.is_displayed === false && benefit.employer_id === null) as benefit}
                            <div class="flex items-center gap-2">
                                <button on:click={(event) => removeBenefit(benefit)} class="w-6 h-6 bg-red text-white rounded-md flex items-center justify-center">
                                    <Trash mainClass="w-4 h-4"/>
                                </button>
                                <Checkbox
                                    value={$tempJobAdvertisement.data.benefits.data.find((b) => b.id === benefit.id)}
                                    on:change={(event) => changeBenefit(benefit)}
                                    name="remember_me"
                                    option={benefit.name}
                                    containerClass=""
                                />
                            </div>
                        {/each}
                    {/if}
                    {#if $benefits.data.filter((benefit) => benefit.employer_id !== null).length > 0}
                        <hr class="my-2 border-sanddark"/>
                        {#each $benefits.data.filter((benefit) => benefit.employer_id !== null) as benefit}
                            <div class="flex items-center gap-2">
                                <button on:click={(event) => removeBenefit(benefit)} class="w-6 h-6 bg-red text-white rounded-md flex items-center justify-center">
                                    <Trash mainClass="w-4 h-4"/>
                                </button>
                                <Checkbox
                                    value={$tempJobAdvertisement.data.benefits.data.find((b) => b.id === benefit.id)}
                                    on:change={(event) => changeBenefit(benefit)}
                                    name="remember_me"
                                    containerClass="leading-none"
                                    option={benefit.name}
                                />
                            </div>
                        {/each}
                    {/if}
                </div>

                <div class="mt-8">
                    <p class="text-2xl text-limegreen font-medium">
                        Haben Sie weitere Vorteile?
                    </p>

                    <AutocompleteInput
                        bind:this={autocompleteField}
                        service={benefitsService}
                        serviceMethod="search"
                        showNoResults={false}
                        renderValue={(value) => value?.name ?? ""}
                        on:enter={addBenefit}
                        bind:search={search}
                        bind:value={benefit}
                        name="benefit"
                        placeholder="Neuer Benefit..."
                        type="text"
                        {formID}
                        containerClass="w-full my-4"
                    />

                    <div class="flex flex-row justify-end">
                        <button on:click={addBenefit} class="button moss sm">
                            Hinzufügen
                        </button>
                    </div>
                </div>
            {/if}
        </div>
    </section>
</div>