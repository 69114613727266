<script>
    export let open = false;
    export let mode;

    import { blur } from "svelte/transition";
    import { createEventDispatcher, onMount, onDestroy } from "svelte";
    import { clickOutside, stickyUntilElement } from "@/lib/utils";

    import WhatsApp from "@/icons/WhatsApp.svelte";
    import Mail from "@/icons/Mail.svelte";
    import Phone from "@/icons/Phone.svelte";

    const dispatch = createEventDispatcher();

    const watchStickyNavbar = () => stickyUntilElement("apply-form", "footer");

    onMount(() => {
        window.addEventListener('scroll', watchStickyNavbar);
    });

    onDestroy(() => {
        window.removeEventListener('scroll', watchStickyNavbar);
    });

    import { jobAdvertisement as tempJobAdvertisement } from "@/stores/temporary";
</script>

<div id="apply-form" transition:blur class="fixed top-0 bottom-0 left-0 right-0 backdrop-blur-sm backdrop-brightness-30 flex flex-col justify-end z-30 {open ? 'block' : 'hidden'}">
    <div class="w-full max-w-lg mx-auto rounded-t-xl bg-moss p-4 text-snow text-center border-b-2 border-poisongreen" use:clickOutside on:clickOutside={() => (dispatch("close"))}>
        <strong class="block">
            Bewerbe Dich ganz einfach per
        </strong>
        <div class="inline-flex flex-row gap-2 mx-auto mt-4">
            {#if $tempJobAdvertisement?.data?.contact_whatsapp && $tempJobAdvertisement?.data?.position?.name}
                <a target="_blank" href={`https://wa.me/${$tempJobAdvertisement.data.contact_whatsapp}?text=Guten%20Tag%2C%0A%0Aich%20habe%20Ihre%20Stellenanzeige%20bei%20GreenCareers%20gefunden%20und%20bin%20sehr%20interessiert%20an%20der%20Stelle%20${$tempJobAdvertisement.data.position.name}.%20Über%20Ihre%20Kontaktaufnahme%20würde%20ich%20mich%20sehr%20freuen.%0A%0AMeine%20Kontaktdaten%3A%0AName%3A%20%7BPlatzhalter%7D%0AMail%3A%20%7BPlatzhalter%7D%0ATelefonnummer%3A%20%7BPlatzhalter%7D%0A%0ABeste%20Grüße`} class="w-16 h-16 bg-limegreen inline-flex items-center justify-center rounded-2xl hover:bg-poisongreen transition-all ease-in-out">
                    <WhatsApp mainClass="w-10 h-10"/>
                </a>
            {/if}
            {#if $tempJobAdvertisement?.data?.contact_email && $tempJobAdvertisement?.data?.position?.name}
                <a target="_blank" href="{`mailto:${$tempJobAdvertisement.data.contact_email}?subject=GreenCareers%20Bewerbung%20${$tempJobAdvertisement.data.position.name}&body=Guten%20Tag%2C%0A%0Aich%20habe%20Ihre%20Stellenanzeige%20bei%20GreenCareers%20gefunden%20und%20bin%20sehr%20interessiert%20an%20der%20Stelle%20${$tempJobAdvertisement.data.position.name}.%20Über%20Ihre%20Kontaktaufnahme%20würde%20ich%20mich%20sehr%20freuen.%0A%0AMeine%20Kontaktdaten%3A%0AName%3A%20%7BPlatzhalter%7D%0AMail%3A%20%7BPlatzhalter%7D%0ATelefonnummer%3A%20%7BPlatzhalter%7D%0A%0ABeste%20Grüße`}" class="w-16 h-16 bg-limegreen inline-flex items-center justify-center rounded-2xl hover:bg-poisongreen transition-all ease-in-out">
                    <Mail mainClass="w-10 h-10"/>
                </a>
            {/if}
            {#if $tempJobAdvertisement.data.contact_phone}
                <a target="_blank" href="{`tel:${$tempJobAdvertisement.data.contact_phone}`}" class="w-16 h-16 bg-limegreen inline-flex items-center justify-center rounded-2xl hover:bg-poisongreen transition-all ease-in-out">
                    <Phone mainClass="w-10 h-10"/>
                </a>
            {/if}
        </div>
    </div>
</div>