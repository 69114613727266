<script>
    import ColorThief from 'colorthief'
    import { onMount } from 'svelte';

    export let mainClass;
    export let image;

    const colorThief = new ColorThief();

    let img = undefined;
    let r = 0;
    let g = 0;
    let b = 0;
    let colorPalette = [];
    let wrapper = undefined;

    onMount(() => {
        if (img.complete) {
            colorPalette = colorThief.getPalette(img);

            if(colorPalette.length > 0) {
                r = colorPalette[2][0];
                g = colorPalette[2][1];
                b = colorPalette[2][2];

                wrapper.style.backgroundColor = `rgb(${r}, ${g}, ${b})`;
            }
        } else {
            img.addEventListener('load', function() {
                if(img.complete){
                    colorPalette = colorThief.getPalette(img);

                    if(colorPalette.length > 0) {
                        r = colorPalette[2][0];
                        g = colorPalette[2][1];
                        b = colorPalette[2][2];

                        wrapper.style.backgroundColor = `rgb(${r}, ${g}, ${b})`;
                    }
                }
            });
        }
    });
</script>

<div bind:this={wrapper} class="flex items-center justify-center rounded-md overflow-hidden {mainClass}">
    <img bind:this={img} alt="Company Logo" src="{image}" class="object-contain object-center w-full h-full"/>
</div>