<script>
    import Star from '@/icons/Star.svelte'

    export let mainClass;
</script>

<div class="rounded-md inline-flex gap-2 text-white absolute top-4 right-4 z-10 bg-orange p-1.5 items-center shadow-lg {mainClass}">
    <Star mainClass="w-4"/>
    <small class="font-bold leading-none">
        PREMIUM
    </small>
</div>