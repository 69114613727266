<script>
    export let mainClass;
</script>

<svg class={mainClass} viewBox="0 0 23 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    <g transform="matrix(1,0,0,1,-11.5,-10.5801)">
        <path d="M11.5,33.58L13.15,27.45C11.819,25.062 11.397,22.275 11.959,19.601C12.522,16.927 14.033,14.545 16.214,12.893C18.395,11.24 21.101,10.427 23.834,10.604C26.568,10.78 29.146,11.934 31.095,13.854C33.045,15.773 34.235,18.329 34.447,21.053C34.66,23.777 33.88,26.487 32.251,28.683C30.623,30.88 28.255,32.418 25.581,33.014C22.908,33.61 20.109,33.224 17.698,31.928L11.5,33.58ZM17.997,29.634L18.38,29.86C20.127,30.893 22.167,31.32 24.182,31.076C26.197,30.831 28.075,29.928 29.522,28.508C30.97,27.088 31.905,25.23 32.184,23.224C32.462,21.219 32.067,19.177 31.061,17.418C30.054,15.66 28.493,14.282 26.62,13.501C24.747,12.72 22.667,12.578 20.705,13.099C18.743,13.62 17.009,14.773 15.773,16.38C14.537,17.986 13.868,19.955 13.871,21.98C13.87,23.659 14.335,25.306 15.216,26.737L15.457,27.132L14.534,30.554L17.997,29.634Z" style="fill:rgb(221,216,206);fill-rule:nonzero;"/>
        <path d="M27.3,23.555C27.076,23.374 26.812,23.247 26.531,23.183C26.25,23.119 25.957,23.119 25.676,23.185C25.254,23.36 24.981,24.021 24.708,24.352C24.65,24.431 24.566,24.486 24.47,24.508C24.375,24.529 24.274,24.515 24.188,24.468C22.642,23.864 21.346,22.757 20.511,21.325C20.44,21.236 20.406,21.123 20.417,21.009C20.428,20.895 20.483,20.79 20.57,20.716C20.874,20.416 21.097,20.044 21.219,19.634C21.246,19.182 21.142,18.732 20.92,18.338C20.749,17.785 20.422,17.292 19.978,16.919C19.75,16.816 19.496,16.782 19.248,16.82C19,16.858 18.769,16.966 18.581,17.133C18.256,17.412 17.998,17.762 17.826,18.154C17.654,18.547 17.573,18.973 17.587,19.401C17.588,19.641 17.619,19.881 17.678,20.113C17.829,20.673 18.061,21.208 18.367,21.701C18.588,22.079 18.828,22.444 19.088,22.796C19.933,23.953 20.994,24.934 22.213,25.687C22.825,26.069 23.479,26.38 24.162,26.613C24.872,26.934 25.655,27.057 26.43,26.97C26.871,26.903 27.289,26.729 27.647,26.464C28.005,26.198 28.292,25.849 28.483,25.447C28.595,25.204 28.629,24.932 28.58,24.669C28.463,24.131 27.742,23.814 27.3,23.555Z" style="fill:rgb(221,216,206);"/>
    </g>
</svg>
