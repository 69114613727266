<script>
    import { onDestroy, tick, createEventDispatcher } from "svelte";
    import {api as slugApi} from "@/services/slug";
    import { route } from "@/stores/route";
    import { translate } from "@/lib/utils";

    $: $route && getSlug();
    $: data = null;

    let destroyed = false;
    let loading = false;
    let path = null;

    const dispatch = createEventDispatcher();

    onDestroy(() => {
        destroyed = true;
    });

    const loadComponent = async (layout) => {
        return (await import(`../pages/${layout}.svelte`)).default;
    };

    const getSlug = async () => {
        path = window.location.pathname.substr(1) || "";

        if (destroyed === false && !loading) {
            loading = true;

            await slugApi
                .search(path)
                .then(async (response) => {
                    data = response.data || {};
                    data.component = await loadComponent(
                        data.meta.resource_class_name,
                    );
                    setMeta();
                })
                .catch(async (response) => {
                    data = response.data || {};
                    data.component = await loadComponent("NotFound");
                })
                .finally(() => (loading = false));
        }
    };

    const setMeta = () => {
        document.title =
            translate(data?.data?.meta_title) ||
            translate(data?.data?.name) ||
            "";
    };

    const componentLoaded = async () => {
        await tick();

        dispatch("loaded", true);
    };

    export function loadScript(src, element) {
        return new Promise((resolve, reject) => {
            const script = document.createElement("script");
            script.src = src;

            element.appendChild(script);

            script.addEventListener("load", () => resolve(script));
            script.addEventListener("error", () => reject(script));
        });
    }

    getSlug();
</script>

<main>
    {#if data && !loading}
        <svelte:component
            this={data.component}
            on:loaded={componentLoaded}
            on:fixheader={(e) => dispatch("fixheader", e.detail)}
            {data}
        />
    {/if}
</main>
