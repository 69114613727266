<script>
    import { syncHeight, countLines } from "@/lib/utils";
    import { spring } from "svelte/motion";
    import { createEventDispatcher, onMount } from "svelte";
    import Chevron from "@/icons/Chevron.svelte";

    let lines;
    let element;
    let open = false;

    export let initialHeight = 0;
    export let showHeader = false;
    export let index = 0;
    export let mainClass = undefined;
    export let clickable = true;

    const dispatch = createEventDispatcher();
    const heightSpring = spring(0, { stiffness: 0.4, damping: 1 });

    $: heightStore = syncHeight(element);
    $: heightSpring.set(
        open ? $heightStore || 0 : parseInt(initialHeight || 0),
    );

    export const toggleOpen = () => {
        if (clickable === false) {
            return 
        }
        
        open = !open
        dispatch("toggle", {index: index, open: open})
    };

    export function close() {
        open = false;
    }

    export function show() {
        open = true;
    }

    onMount(() => {
        lines = countLines(element);
    });

    // Only show toggle if lines are more then the initial height or initial Height is not set
    $: showToggle = initialHeight ? lines > 4 : true;
</script>

<main
    class="pt-2 pb-4 my-2 transition-all ease-in-out text-earth {mainClass}"
>
    {#if showHeader && showToggle}
        <button
            on:click={toggleOpen}
            class="flex flex-row gap-4 justify-between w-full items-center cursor-pointer text-xl relative"
        >
            <slot name="title" {open}>
                <span class="missing">Unknown title</span>
            </slot>
            <div
                class="absolute top-2 right-4 shrink-0 transition-all ease-in-out h-6 w-6 rounded-md bg-light-green flex items-center justify-center"
            >
                <Chevron mainClass="transition-all ease-in-out text-limegreen {open ? 'rotate-180' : ''}"/>
            </div>
        </button>
    {/if}
    <div class="overflow-hidden" style="height: {$heightSpring}px;">
        <div
            bind:this={element}
            class="pt-2 relative"
            id="content"
        >
            <slot name="content">
                <span class="missing">Unknown content</span>
            </slot>
        </div>
    </div>
</main>
