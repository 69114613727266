<script>
    export let formID;

    import { search } from "@/stores/globalsearch"

    import InputError from "@/components/form/InputError.svelte"

    export let activeClass = "bg-poisongreen text-moss"
    export let passiveClass = "bg-white/20"

    const change = async (e) => {
        return search.update((store) => {
            store.job_available_at = e.target.dataset.value
            return store;
        })
    }

    let hasErrors = false;
    let name = "job_available_at";
</script>

<section id="job_available_at">
    <slot></slot>
    
    <div class="grid grid-cols-1 md:grid-cols-2 gap-2 mt-2 {hasErrors ? "border border-red rounded-md p-1" : ""}">
        <button on:click={change} data-value="1_to_3_months" class="{$search.job_available_at === "1_to_3_months" ? activeClass : passiveClass} font-medium transition all ease-in-out text-white rounded-md p-4">
            1-3 Monate
        </button>
        <button on:click={change} data-value="more_than_3_months" class="{$search.job_available_at === "more_than_3_months" ? activeClass : passiveClass} font-medium transition all ease-in-out text-white rounded-md p-4">
            mehr als 3 Monate
        </button>
    </div>

    <InputError bind:hasErrors {name} {formID} value="{$search.job_available_at}"/>
</section>