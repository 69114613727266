<script>
    export let open = false;
    export let job = {};

    import Popup from "@/components/Popup.svelte";
    import { service as jobService } from "@/services/job";

    const terminateJob = () => {
        open = false;

        jobService.terminate(job.id)
            .then(() => {
                window.eventbus.emit("reload:employer");
                window.eventbus.emit("reload:vacancies");
            })
    }
</script>

<Popup open={open} on:close={() => (open = false)} closeIcon={false}>
    <strong class="text-2xl text-snow">
        Inserat beenden
    </strong>

    <div class="my-4 px-0.5 w-full">
        <p>
            Wenn Sie das Inserat <strong>{job.position.name}</strong> vorzeitig beenden möchten können Sie dies hier tun. Das Inserat wird dann nicht mehr auf der Plattform angezeigt.
        </p>
    </div>

    <div class="flex flex-col lg:flex-row gap-2 mt-6">
        <button
            on:click={() => open = false}
            class="button sm limegreen block w-full"
        >
            <span>
                Abbrechen
            </span>
        </button>
        <button
            on:click={() => terminateJob()}
            class="button sm red block w-full"
        >
            <span>
                Beenden
            </span>
        </button>
    </div>
</Popup>