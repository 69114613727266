<script>
    import { writable, derived } from "svelte/store";

    import Expand from "@/components/Expand.svelte";

    export let data;
    export let search;

    let activeFaq = undefined;
    let faqs = [];

    const items = writable(data);
    const term = writable('');

    $: term.set(search);

    export const filtered = derived(
        [term, items], 
        ([$term, $items]) => $items.filter(x => {
            return (x.data.question.toLowerCase().includes($term.toLowerCase()) || x.data.answer.toLowerCase().includes($term.toLowerCase()))
        })
    );

    const handleToggle = (e) => {
        if (activeFaq !== undefined && activeFaq !== e.detail.index) {
            faqs[activeFaq].close();
        }
        
        activeFaq = e.detail.index;
    }

    // close all faqs when search changes
    $: search && (
        faqs.forEach((faq) => {
            if (faq) faq.close()
        })
    )
</script>

<main>
    {#each $filtered as content, index}
        <Expand bind:this={faqs[index]} showHeader showToggle on:toggle={(e) => handleToggle(e)} index={index} mainClass="border-b border-earth/50">
            <div slot="title" class="text-left font-medium">{content.data.question}</div>
            <div slot="content" class="text-left">{@html content.data.answer}</div>
        </Expand>
    {/each}
</main>