<script>
	export let texts = [];
	export let mainClass;

	function animateTyping(node, texts) {
		let textsToType = texts;

		let textsIndex = 0;
		let charIndex = 0;
		let currentText = '';

		const updateInnerHTML = (isTyping) => {
			// node.innerHTML = '&#8203;' + currentText + (isTyping ? '|' : '');
			node.innerHTML = '&#8203;' + currentText;
		};

		const blinkingCursor = () => {
			if (currentText.endsWith('|')) {
				currentText = currentText.slice(0, -1);
			} else {
				currentText += '|';
			}
			updateInnerHTML();
		};

		const waitingEffect = (delay) => {
			const interval = setInterval(blinkingCursor, 500);

			setTimeout(() => {
				clearInterval(interval);
				if (currentText.endsWith('|')) {
					currentText = currentText.slice(0, -1);
				}
				updateInnerHTML();
			}, delay);
		};

		const typeEffect = () => {
			const currentString = textsToType[textsIndex];
			const delay = currentString[charIndex] === ' ' ? 75 : 100;

			if (charIndex < currentString.length) {
				currentText += currentString[charIndex++];
				setTimeout(typeEffect, delay);
			} else {
				// waitingEffect(1500);
				setTimeout(deleteEffect, 1500);
			}

			updateInnerHTML(true);
		};

		const deleteEffect = () => {
			if (charIndex > 0) {
				currentText = currentText.slice(0, --charIndex);
				setTimeout(deleteEffect, 50);
			} else {
				textsIndex = (textsIndex + 1) % textsToType.length;
				currentText = '';
				// waitingEffect(1500);
				setTimeout(typeEffect, 1500);
			}

			updateInnerHTML();
		};

		setTimeout(typeEffect, 500);

		return {
			onDestroy() {},
			update(newTexts) {
				textsToType = newTexts;
			}
		};
	}
</script>

<main class={mainClass}>
	<span use:animateTyping={texts} />
</main>