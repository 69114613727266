<script>
    export let required;
    export let label;
    export let popover;
</script>

<div class="text-xs mb-1 flex items-center group">
    <span class="validation">
        {#if label}
            {label}
            {#if required}
                <sup class="text-limegreen required">*</sup>
            {/if}
        {/if}
    </span>
</div>
