import Api from "../api.js"
import { writable } from "svelte/store";
import axios from "@/lib/axios.js"

export const positions = writable();
export const position = writable();

class PositionService extends Api {
    constructor() {
        super('/api/positions', positions, position, 'position');
    }

    async search(data, config) {
        return await axios
            .post(`/api/positions/search`, data, config)
            .then(async (response) => {
                return response;
            })
            .catch((error) => {
                console.log(error);
                return Promise.reject(error);
            });
    }
}

export const service = new PositionService('/api/positions', positions, position);