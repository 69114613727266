<script>
    document.title = "Green Careers - Login";

    import Input from "@/components/form/Input.svelte";
    import NavLink from "@/components/NavLink.svelte";
    import Checkbox from "@/components/form/Checkbox.svelte";

    import { fly } from "svelte/transition";
    import { errors } from "@/stores/error";
    import { intended, clearintended } from "@/stores/intended";
    import { onMount, onDestroy, tick } from "svelte";
    import { navigate } from "svelte-routing";
    import { api, user } from "@/services/user"
    import { getQueryParameterByName } from "@/lib/utils";

    let data = {
        email: null,
        password: null,
    };

    let formID = "login";
    let message = null;
    let loading = false;

    onDestroy(() => {
        errors.update((errors) => _.set(errors, formID, []));
    });

    onMount(() => {
        scrollTo(0, 0);

        if (getQueryParameterByName("oAuth")) {
            if (
                getQueryParameterByName("oAuth") ===
                "registration-wasnt-done-via-oauth"
            ) {
                message = {
                    type: "error",
                    content:
                        "The registration for this email was not done via oAuth. Please login using your password.",
                };
            }
        }

        if ($user === undefined) {
            intended.set(
                getQueryParameterByName("intended")
                    ? getQueryParameterByName("intended")
                    : null,
            );
        } else {
            navigate("/dashboard");
        }
    });

    const login = async () => {
        if (loading === false) {
            loading = true;

            await api
                .login(data, { formID: formID })
                .then((response) => {
                    data = {
                        email: null,
                        password: null,
                    };

                    if (response.data.intended) {
                        window.location = response.data.intended;
                        clearintended();
                    } else if ($intended) {
                        window.location.href = $intended;
                        clearintended();
                    } else {
                        window.location = "/dashboard";
                    }
                })
                .finally(() => {
                    loading = false;
                });
        }
    };
</script>

<main in:fly out:fly>
    <div
        class="bg-earth bg-cover" style="background-image: url('/images/home-1.jpg');"
    >
        <div class="min-h-screen py-24 w-full flex items-center justify-center backdrop-blur-md">
            <div class="w-screen px-6 mt-4 space-y-8 md:mt-0 md:px-2 max-w-lg">
                <div
                    class="p-8 space-y-4 bg-gray-700 text-white backdrop-blur-xl shadow-2xl rounded-md relative bg-sandlight"
                >
                    {#if message}
                        <div class="my-2">
                            {#if message.type === "error"}
                                <div
                                    class="flex flex-row gap-2 items-center p-2 bg-gray-500 border-l-2 border-red text-sm"
                                >
                                    <div>
                                        {@html message.content}
                                    </div>
                                    <button
                                        on:click={() => (message = null)}
                                        class="shrink-0 bg-gray-400 flex items-center justify-center h-8 w-8 rounded-md border border-gray-100"
                                    >
                                        x
                                    </button>
                                </div>
                            {/if}
                        </div>
                    {/if}

                    <img class="w-1/3 mx-auto" src="/images/signage.png" alt="Green Careers"/>
                    <h2 class="text-center text-earth">Herzlich<br/>Willkommen</h2>
                    <p class="text-center text-limegreen font-bold">Anmelden</p>

                    <div class="mt-6">
                        <form
                            novalidate
                            class="w-full"
                            on:submit|preventDefault={() => login()}
                            disabled={loading}
                        >
                            <Input
                                on:enter={() => login(data)}
                                bind:value={data.email}
                                name="email"
                                placeholder="E-Mail"
                                type="text"
                                {formID}
                                containerClass="w-full my-4"
                            />

                            <Input
                                on:enter={() => login(data)}
                                bind:value={data.password}
                                name="password"
                                placeholder="Passwort"
                                type="password"
                                {formID}
                                containerClass="w-full my-4"
                            />

                            <Checkbox
                                bind:value={data.remember_me}
                                name="remember_me"
                                option="Angemeldet bleiben"
                                {formID}
                                containerClass="w-full mt-6 text-earth"
                            />
                        </form>

                        <button
                            on:click|preventDefault={login(data)}
                            type="submit"
                            class="button moss mt-6 block w-full"
                        >
                            <span> Weiter </span>
                        </button>

                        <div class="flex flex-col lg:flex-row gap-2 justify-center mt-3 text-moss">
                            <NavLink
                                to="/forgot-password"
                                linkClass="underline text-center block"
                            >
                                Passwort vergessen
                            </NavLink>
                            <p class="text-center hidden lg:block">•</p>
                            <NavLink
                                to="/register{getQueryParameterByName('intended') ? '?intended=' + getQueryParameterByName('intended') : ''}"
                                linkClass="underline text-center block"
                            >
                                Benutzerkonto erstellen
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
