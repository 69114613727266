<script>
    import { onDestroy, onMount } from "svelte";
    import InformationBox from "./InformationBox.svelte";

    let scrollY;
    let multiplier = 0.25;
    let offset = 0;

    const onResize = () => {
        let mobile = window.matchMedia('(min-width: 0px) and (max-width: 768px)');
        let tablet = window.matchMedia('(min-width: 769px) and (max-width: 1024px)');
        let desktop = window.matchMedia('(min-width: 1025px)');

        if(mobile.matches) {
            multiplier = 0.45;
            offset = 800;
        } else if(tablet.matches) {
            multiplier = 0.2;
            offset = 250;
        } else if(desktop.matches) {
            multiplier = 0.25;
            offset = 350;
        }
    } 

    onMount(() => {
        onResize()
        document.addEventListener('resize', onResize);
    })

    onDestroy(() => {
        document.removeEventListener('resize', onResize);
    })
</script>

<svelte:window bind:scrollY/>

<div class="relative overflow-hidden container-fluid">
    <div class="max-w-[2400px] mx-auto relative">
        <div class="overflow-y-hidden overflow-x-visible">
            <main class="relative text-gray max-w-sm px-14 pt-24 pb-24 mx-auto z-20 min-h-[700px] lg:max-w-6xl">
                <div class="absolute z-10 shadow-2xl shadow-sandash -top-[0%] left-0 w-[60%] lg:w-[250px] lg:top-[0%] lg:left-[-2%] aspect-square bg-limegreen rounded-full">
                    <div class="p-0.5 flex flex-col items-center justify-center text-center rounded-full border border-sandlight m-2" style="width: calc(100% - 1rem); height: calc(100% - 1rem)">
                        <InformationBox mainClass="text-white !text-xl font-semibold" number={window.meta.settings.employee_count} suffix="+" duration={1000}></InformationBox>
                        <p class="text-white/90 text-sm font-medium -mt-3">
                            Fachkräfte aus der <br/> grünen Branche
                        </p>
                    </div>
                </div>
                <div class="absolute z-10 shadow-2xl shadow-sandash top-[30%] left-[40%] w-[50%] lg:w-[225px] lg:top-[5%] lg:left-[40%] aspect-square bg-limegreen rounded-full">
                    <div class="p-0.5 flex flex-col items-center justify-center text-center rounded-full border border-sandlight m-2" style="width: calc(100% - 1rem); height: calc(100% - 1rem)">
                        <InformationBox mainClass="text-white !text-xl font-semibold" number={window.meta.settings.employer_count} suffix="+" duration={1000}></InformationBox>
                        <p class="text-white/90 text-sm font-medium -mt-3">
                            Unternehmen <br/>setzen auf <br/>GreenCareers
                        </p>
                    </div>
                </div>
                <div class="absolute z-10 shadow-2xl shadow-sandash top-[65%] left-[5%] w-[40%] lg:w-[200px] lg:left-[unset] lg:right-[-2%] lg:top-[32%] aspect-square bg-limegreen rounded-full">
                    <div class="p-0.5 flex flex-col items-center justify-center text-center rounded-full border border-sandlight m-2" style="width: calc(100% - 1rem); height: calc(100% - 1rem)">
                        <InformationBox mainClass="text-white !text-xl font-semibold" number={window.meta.settings.new_employee_count} suffix="+" duration={1000}></InformationBox>
                        <p class="text-white/90 text-sm font-medium -mt-3">
                            Neue Fachkräfte <br/> pro Monat
                        </p>
                    </div>
                </div>
                <div class="mx-8">
                    <svg class="lg:hidden" viewBox="0 0 139 517" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M81.607 1C114.734 2.64747 176.584 46.8263 104.602 162.483C80.56 201.112 56.7139 215.145 18.6222 269.411C-20.024 324.467 6.62521 461.445 81.607 516" stroke="currentColor" stroke-width="2" stroke-dasharray="4 4"/>
                    </svg>
                    <svg class="hidden lg:block w-full" viewBox="0 0 1470 387" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1469 225.054C1464.3 319.597 1338.37 496.119 1008.7 290.681C898.584 222.065 872.5 143 703.898 45.294C537.933 -50.8834 156.508 11.0544 1 225.054" stroke="currentColor" stroke-width="2" stroke-dasharray="4 4"/>
                    </svg>
                </div>
            </main>
            <img src="/images/plant-right.png" alt="plant" class="absolute z-10 -right-16 lg:right-0" style="top: {scrollY * multiplier}px"/>
            <img src="/images/plant-left.png" alt="plant" class="absolute z-10 -left-16 lg:left-0" style="top: {(-scrollY + offset) * multiplier}px"/>
        </div>

        <div class="bg-gradient-to-t from-sandlight h-24 w-full bottom-0 absolute z-10"/>
        <div class="bg-gradient-to-b from-sandlight h-24 w-full top-0 absolute z-10"/>
    </div>
</div>