<script>
    export let params = {
        params: {
            order_by: ['created_at.desc'],
            per_page: 5,
        }
    }

    export let emptyText = "Sie haben aktuell noch keine aktiven Stellenanzeigen";
    
    import { merge } from "lodash";
    import { onDestroy, onMount } from "svelte";
    import { writable } from "svelte/store";
    import { service as jobService, jobs } from "@/services/job";
    import Pagination from "@/components/Pagination.svelte";
    import LoadingDots from "@/components/LoadingDots.svelte";
    import Listing from "@/components/listing/Listing.svelte";

    let loading = true;
    
    const vacancies = writable([]);

    const getJobs = async (config) => {
        loading = true;

        await jobService.index(
            merge(params, config),
            vacancies
        );

        loading = false;
    }

    const paginate = (event) => {
        getJobs({
            params: {
                page: event.detail,
            }
        });
    };

    const eventBusHandler = () => {
        getJobs();
    } 

    onMount(async () => {
        await getJobs();

        window.eventbus.on("reload:vacancies", eventBusHandler);
    });

    onDestroy(() => {
        window.eventbus.off("reload:vacancies", eventBusHandler);
    })
</script>

<main>
    <div class="mt-2 relative">
        {#if loading}
            <div class="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">
                <LoadingDots/>
            </div>
        {/if}

        {#if $vacancies?.data?.length === 0}
            <div>
                {emptyText}
            </div>
        {:else if $vacancies?.data?.length >= 1}
            <div class="flex flex-col gap-2">
                {#each $vacancies.data as job}
                    <Listing editable={true} {job} containerClass="border border-poisongreen !bg-sandlight/20 {loading ? "opacity-20" : "opacity-100"}"/>
                {/each}
            </div>
        {/if}

        <div class="flex justify-center">
            <Pagination
                on:paginate={(event) => paginate(event)}
                meta={$vacancies.meta}
            />
        </div>
    </div>
</main>